import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { ModuleService } from './../../../module.service';
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient,HttpHeaders, HttpBackend, HttpEventType, HttpResponse} from '@angular/common/http';
import { Select2OptionData } from 'ng-select2';
declare var $;
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-edit-routing',
  templateUrl: './edit-routing.component.html',
  styleUrls: ['./edit-routing.component.scss']
})
export class EditRoutingComponent {
    error: string;
    addForm: FormGroup;
    private http: HttpClient;
    showLoadingIndicator: any;
    msbapTitle = 'Audio Title';
    msbapAudioUrl = 'https://d2actkasrcrrs8.cloudfront.net/ivr-audio/fsdffsdf.mp3';   
       
    msbapDisplayTitle = false; 
    msbapDisplayVolumeControls = true;  
    
    constructor(
      private fb: FormBuilder,
      private moduleService: ModuleService,
      private router: Router,
      private route: ActivatedRoute,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private cdRef:ChangeDetectorRef,
      private titleService: Title
  
    ) {
      this.http = new HttpClient(handler);
      
    }
    
    menuname:any;
    campaigns:any;
    // msbapTitle:any;
    audio:any;
    alltarget:any;
    allivrroutingplan:any;
    public currentCompany:any;
    public allBuyers:any=[];
    settings = {};
  
    ngAfterContentChecked() {
        this.cdRef.detectChanges();
    }
  
    public setTitle(newTitle: string) {
      this.titleService.setTitle(newTitle);
    }
  
    ngOnInit() {
      this.setTitle('Edit Predictive Routing')
      this.getalldata()
      // this.showLoadingIndicator= true;
      // setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.addForm = this.fb.group({
        user_uuid: [''],            
        name: ['',Validators.required],            
        count_attempts : ['',Validators.required],            
        track_conversions_by: ['',Validators.required],    
        minimum_numberofCalls: [''],        
        desired_numberofCalls: [''],        
        minimum_numberofHours: [''],        
        desired_numberofHours: [''],        
        skip_latest_hours: [''],        
        new_targets: [''],        
        underperforming_targets: [''],                       
      });
  
      // this.addForm.patchValue({
      //   count_attempts: '0',     
      //   track_conversions_by: '0',     
      //   minimum_numberofCalls: '15',     
      //   desired_numberofCalls: '75',     
      //   desired_numberofHours: '720',     
      // });  
  
    }
   
    getalldata(){
      const uuid = this.route.snapshot.paramMap.get('id');
      if (uuid) {
        this.moduleService.getpredictiveroutingdata(uuid).subscribe(
          res => {
            this.setTitle(res.name+' | Predictive Routing');
            this.addForm.patchValue({
              name: res.name,
              count_attempts: res.count_attempts,
              track_conversions_by: res.track_conversions_by,
              minimum_numberofCalls: res.minimum_numberofCalls,
              desired_numberofCalls: res.desired_numberofCalls,
              minimum_numberofHours: res.minimum_numberofHours,
              desired_numberofHours: res.desired_numberofHours,
              skip_latest_hours: res.skip_latest_hours,
              new_targets: res.new_targets,
              underperforming_targets: res.underperforming_targets              
            });                                
            }
        );
        // this.ngxLoader.stopLoader('customLoaderId');
      } 
    }
    
    submitted = false;
    get f() { return this.addForm.controls; }  
    onSubmit () {
      // const formData = new FormData();      
      this.submitted = true;
      if (this.addForm.invalid) {
          return;
      }
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.addForm.get("user_uuid").setValue(currentUser.uuid);
      // console.log(this.buyername); return
      
      // this.addForm.get("buyer").setValue(this.buyername);
      const uuid = this.route.snapshot.paramMap.get('id');
      this.moduleService.updatePredictiveRouting(uuid,this.currentCompany.uuid,this.addForm.value).subscribe(
        res => {
          // console.log(res); return false;
          if (res.status !== 'error') {
            this.toastrService.success('Predictive Routing Configuration Updated Successfully');
            this.router.navigate(['/predictive-routing']);
          }else{
            this.toastrService.error('Failed To Add');
          }
        },
        error => this.error = error,
      );     
    }  
  
  }