import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Fr8bukynRestApiService } from '../../fr8bukyn-rest-api.service';
import { GlobalVariable } from '../../../global';



@Component({
  selector: 'app-ngxdemo',
  templateUrl: './ngxdemo.component.html',
  styleUrls: ['./ngxdemo.component.scss']
})
export class NgxdemoComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  pageSize:any = 10;
  ColumnMode = ColumnMode;
  loadingIndicator = false;
  private http: HttpClient;
  blogPostsList!: Array<any>;
  constructor(handler: HttpBackend,private el: ElementRef,private ngxLoader: NgxUiLoaderService) {
                this.http = new HttpClient(handler);
               }

  ngOnInit(): void {
    this.searchBlogPosts();
  }
  lists = [];
  searchBlogPosts(): void {
    this.blogPostsList = [];
    // this.loadingIndicator = true;
    this.ngxLoader.start();
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.http.post<any>(
      this.ServerUrl+'getcompanydetailsbyuuid/'+currentCompany.uuid,            
       {}
    ).subscribe(resp => {
        this.blogPostsList = resp[0].Response.data;
        this.lists = resp[0].Response.data;
        // this.loadingIndicator = false;
        this.ngxLoader.stop();
        // console.log(this.blogPostsList);
    }, error => {
        this.loadingIndicator = false;
    });
  }

  private loadPage(limit: number) {
    // console.log(limit);
    // set the loading flag, which serves two purposes:
    // 1) it prevents the same page from being loaded twice
    // 2) it enables display of the loading indicator
    // this.loadingIndicator = true;
    this.ngxLoader.start();
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.http.post<any>(
      this.ServerUrl+'getcompanydetailsbyuuid/'+currentCompany.uuid+'/'+this.blogPostsList.length+'/'+limit,            
       {}
    ).subscribe(resp => {
        const rows = [...this.blogPostsList, ...resp[0].Response.data];
        this.blogPostsList = rows;
        this.lists = resp[0].Response.data;
        // this.loadingIndicator = false;
        this.ngxLoader.stop();
        // console.log(this.blogPostsList);
    }, error => {
        // this.loadingIndicator = false;
        this.ngxLoader.stop();
    });
 
  }

 

  // openAddBlogPostPopup(blog: any): void {
  //   const modalRef = this.dialogUtilService.createLargeDialog(BlogPostsAddComponent);
  //   const blogPostsAddComponent: BlogPostsAddComponent = modalRef.componentInstance;
  //   blogPostsAddComponent.currentPost = blog;
  //   modalRef.result.then(data => {
  //     this.searchBlogPosts();
  //   }, () => { });
  // }

  // openDeleteBlogPopup(blog: any): void {
  //   const modalRef = this.dialogUtilService.openConfirmDialog('Are you sure want to Remove?');
  //   modalRef.result.then((result) => {
  //     if (result.action === 'YES' as Action) {
  //       const payload = new HttpParams()
  //       .set('id', blog.id);

  //       this.ngxUiLoaderService.startLoader('admin-blog-posts-loader');
  //       this.fr8bukynRestApiService.post<any>(ApiUrls.DELETE_BLOG_POST, payload).subscribe(response => {
  //         this.ngxUiLoaderService.stopLoader('admin-blog-posts-loader');
  //         if (response.status === 'Success') {
  //           this.toastrService.success(response.message);
  //           this.searchBlogPosts();
  //         } else {
  //           this.toastrService.error(response.message);
  //         }
  //       });
  //     }
  //   });
  // }

  // getImageSrc(blog: any): any {
  //   if (blog) {
  //     return FolderPath.BLOG_FOLDER_URL + blog.image;
  //   }
  //   return null;
  // }


  isLoading: boolean;
  readonly headerHeight = 50;
  readonly rowHeight = 50;
  readonly pageLimit = 10;
  onScroll(offsetY: number) {
      const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
      // check if we scrolled to the end of the viewport
      // 
      if (!this.loadingIndicator && offsetY + viewHeight >= this.blogPostsList.length * this.rowHeight) {
      // total number of results to load
      let limit = this.pageLimit;
      
      // check if we haven't fetched any results yet
      if (this.blogPostsList.length === 0) {
        // calculate the number of rows that fit within viewport
        const pageSize = Math.ceil(viewHeight / this.rowHeight);

        // change the limit to pageSize such that we fill the first page entirely
        // (otherwise, we won't be able to scroll past it)
        limit = Math.max(pageSize, this.pageLimit);
      }
      this.loadPage(limit);
    }
  }


  
  temp = [];  
  @ViewChild(DatatableComponent) table1: DatatableComponent;
  
  updateFilter(event:any){
    // console.log(event.target.value);
    const val = event.target.value.toLowerCase().trim();

    if(val){ 
      // filter our data
      const temp = this.blogPostsList.filter(function (d) {
        return d.incoming_number.toLowerCase().indexOf(val) !== -1 ||
              d.publisher.toLowerCase().indexOf(val) !== -1 ||
              d.target.toLowerCase().indexOf(val) !== -1 ||
              d.campaign.toLowerCase().indexOf(val) !== -1 || !val;
      });
      // console.log(val);
      this.blogPostsList = temp;


    }else{
      this.blogPostsList = this.lists
    }
    this.table1.offset = 0;  
  }

  getpage(event : any){
    this.pageSize = event.target.value;
    // console.log(this.pageSize);
  }
}
