import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Component,ViewEncapsulation, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ModuleService } from './../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router,ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {RequestOptions } from '@angular/http';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import "datatables.net";
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { Chart } from 'angular-highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import * as Highcharts from 'highcharts';
import 'moment-timezone';
HC_exporting(Highcharts);
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import 'rxjs/add/observable/interval';
import { Select2OptionData } from 'ng-select2';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  totalarr:any[]
}

class filterarr
{
  filteradvanced: number;
  filterlabel: string;
  containfiltertype: number;
  containfiltertext: string;
  filtertype: number;
  isorand: number;
  uuid: string;
  name: string;
  isdup: Number;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportsComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;

  labelval: any;
  selected: any;
  selected2:any;
  numbers: any = '';
  alwaysShowCalendars: boolean;
  showRangeLabelOnInput: boolean;
  keepCalendarOpeningWithRange: boolean;
  // maxDate: moment.Moment;
  // minDate: moment.Moment;

  // @ViewChild('dataTable', {static: true}) table: ElementRef;
  @ViewChild('dataTable1', {static: true}) table1: ElementRef;
  @ViewChild('myTable') table: any;
  @ViewChild('copyname') copyname: ElementRef<HTMLElement>;

  dataTable: any;
  dataTable1: any;
  currentUser: any={};
  currentCompany: any;
  label: Number;
  error: {};
  showLoadingIndicator: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptionsdetails: DataTables.Settings = {};
  labels: any = 0;
  // callsdata: any = 0;
  callsdata: any = [];

  private http: HttpClient;
  searchcallsform: FormGroup;
  submitted = false;
  event:any="";
  expanded: any = {};
  timeout: any;
  rows: any[] = [];
  selectednewdates:any;
  // subscriptions : Subscription[];
  loader: any;
  gmttime:any='-07:00';
  alltags:any = [];

  tagselectedvalue:any = "";
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    handler: HttpBackend,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private el: ElementRef,private ngxLoader: NgxUiLoaderService,
    private cdRef:ChangeDetectorRef,
    private titleService: Title
  ){
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser.gmttime!=undefined){
      this.gmttime = currentUser.gmttime;
    }

    this.loader = {
      loaderId: 'customLoaderId',
      loaderId1: 'customLoaderId1',
      loaderId2: 'customLoaderId2',
    };

    this.http = new HttpClient(handler);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.alwaysShowCalendars = true;
    this.keepCalendarOpeningWithRange = true;
    this.showRangeLabelOnInput = true;

    // this.selected = {startDate: moment.tz(moment(), "America/Phoenix").format("MM/DD/YYYY 00:00"), endDate: moment.tz(moment(), "America/Phoenix").format("MM/DD/YYYY 23:59")};
    this.selected = {startDate: moment().format("MM/DD/YYYY 00:00"), endDate: moment().format("MM/DD/YYYY 23:59")};
    // console.log(this.selected);

    this.selected1 = this.selected;
    this.selected2 = this.selected;


  }

  triggerFalseClick() {
      let el: HTMLElement = this.copyname.nativeElement;
      // el.click();
      console.log(el);

  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      console.log('paged!', event);
    }, 100);
  }

  toggleExpandRow(rowIndex,row) {
    this.loadingIndicator = true;
    this.table.rowDetail.toggleExpandRow(row);
    this.details(rowIndex,row);
  }
  toggleCollaspeRow(row) {
    this.table.rowDetail.collapseAllRows();
  }


  sendMenuUpdateMessage(): void {
    // send message to subscribers via observable subject
      this.moduleservice.sendMenuUpdateMessage('1');
  }

  ranges: any = {
    Today: [  moment().format("MM/DD/YYYY 00:00"),   moment().format("MM/DD/YYYY 23:59")],
    Yesterday: [moment().subtract(1, 'days').format("MM/DD/YYYY 00:00"),  moment().subtract(1, 'days').format("MM/DD/YYYY 23:59")],
    "Last 2 days": [moment().subtract(1, 'days').format("MM/DD/YYYY 00:00"), moment().format("MM/DD/YYYY 23:59")],
    "Last 7 days": [moment().subtract(6, 'days').format("MM/DD/YYYY 00:00"), moment().format("MM/DD/YYYY 23:59")],
    "This Week": [moment().startOf('week').add(1, 'days').format("MM/DD/YYYY 00:00"), moment().endOf('week').add(1, 'days').format("MM/DD/YYYY 23:59")],
    "Last Week": [moment().startOf('week').subtract(6, 'days').format("MM/DD/YYYY 00:00"), moment().endOf('week').subtract(6, 'days').format("MM/DD/YYYY 23:59")],
    "Last 30 days": [moment().subtract(30, 'days').format("MM/DD/YYYY 00:00"), moment().format("MM/DD/YYYY 23:59")],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
     moment()
      .subtract(1, 'month')
      .startOf('month')
      ,
     moment()
      .subtract(1, 'month')
      .endOf('month')
    ],
  };
  // ranges: any = {
  //   Today: [ moment.tz(moment(), "America/Phoenix").format("MM/DD/YYYY 00:00"),  moment.tz(moment(), "America/Phoenix").format("MM/DD/YYYY 23:59")],
  //   Yesterday: [moment.tz(moment().subtract(1, 'days'), "America/Phoenix").format("MM/DD/YYYY 00:00"),  moment.tz(moment().subtract(1, 'days'), "America/Phoenix").format("MM/DD/YYYY 23:59")],
  //   'This Month': [moment().startOf('month'), moment().endOf('month')],
  //   'Last Month': [
  //    moment()
  //     .subtract(1, 'month')
  //     .startOf('month')
  //     ,
  //    moment()
  //     .subtract(1, 'month')
  //     .endOf('month')
  //   ],
  // };


  userselectedvalue:any = "";
  getuser(){
    let role = this.userselectedvalue[0].role;

    this.loadcalls(this.filterselected,this.selecteddate,role);
    this.loadcallsdata(this.filterselected,this.selecteddate,1,role);
    this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval,role);
  }

  ngAfterContentChecked() {
      this.cdRef.detectChanges();
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  allusers:any = [];
  ngxclass="dark";
  subscriptions : Subscription[];
  subscription: Subscription;
  intervalId: number;
  refresh_date: Date;
  result:any = []
  ngOnInit() {

    // this.showLoadingIndicator= true;
    // setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    const uuid = this.route.snapshot.paramMap.get('id');
    // console.log(uuid);
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(currentUser);

    this.getallivr(currentCompany.uuid).then((res)=>{
      let tags = [
        { "id": 1, "uuid": 1, "itemName": "State", "name": "State", "category": "InboundNumber" },
        { "id": 2, "uuid": 2, "itemName": "Name", "name": "Name", "category": "Ivr" }
        ];
        this.result = res;
        this.alltags = tags.concat(this.result);
    })

    if(currentUser.role===2){
      this.userselectedvalue = [{"id":currentUser.uuid,"itemName":'Admin'}];
      this.getallusers(currentCompany.uuid,currentUser.role).then((res)=>{
        this.allusers = res
      })
    }
    if(currentUser.role!==2){
      this.userselectedvalue = [{"id":currentUser.uuid,"itemName":currentUser.first_name}];
      let cat = "";
      if(currentUser.role==3){
        cat="Publishers";
      }
      if(currentUser.role==4){
        cat="Buyers";
      }
      this.allusers = [{"id":currentUser.uuid,"itemName":currentUser.first_name,'category':cat}]
    }


    this.refresh_date = new Date();
    // console.log(this.allusers);

    if(uuid!=null){
      this.getsavedreports(uuid);
    }else{
      this.setTitle('Reports');
      this.route.queryParams
      .subscribe(params => {
        // console.log(params);
        if(Object.keys(params).length>0){
          // console.log(params);

          let customObj = new filterarr();
          customObj.filteradvanced = params.filteradvanced;
          customObj.filtertype = params.filtertype;
          customObj.filterlabel = params.filterlabel;
          customObj.containfiltertype = params.containfiltertype;
          customObj.containfiltertext = params.containfiltertext;
          customObj.uuid = params.uuid;
          customObj.name = params.name;
          customObj.isorand = 0;

          this.filterselected.push(customObj);
          this.istougle = 0;
          this.filter = 1;



          this.loadcalls(this.filterselected,this.selecteddate,this.currentUser.role);
          this.loadcallsdata(this.filterselected,this.selecteddate,2,this.currentUser.role);
          this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval,this.currentUser.role);
        }else{
          this.selecteddate = {startDate: moment().format("MM/DD/YYYY 00:00"), endDate: moment().format("MM/DD/YYYY 23:59")};
          this.loadcalls("",this.selecteddate);
          this.loadcallsdata("",this.selecteddate,1);
          this.getChartNumberOfCalls(0,this.selecteddate,1);
        }
      });



      // this.getsavedreports();
    }
    if($("body").hasClass("theme-white")) {
      this.ngxclass='material';
    }

    // const source = interval(15000);
    // this.subscription = source.subscribe(val => this.loadlivecalls());
    // this.subscriptions.push(this.subscription);



  }

  getallivr(company_uuid:any){
    return new Promise((res,rej)=>{
      this.moduleservice.getIVRMenus(company_uuid).subscribe(
        result => {
          res(result)
        },
        error => this.error = error
      );
    })
  }

  getallusers(company_uuid:any,role:any){
    return new Promise((res,rej)=>{
      this.moduleservice.getallusers(company_uuid,role).subscribe(
        result => {
          res(result)
        },
        error => this.error = error
      );
      })
  }


  chart:any;
  date1:string;
  chartarg:any;
  bgcolorcode:any;
  colorcode:any='#fff';

  getChartNumberOfCalls(filterselected:any,date:any,daytype:any,role:any=""){
    this.ngxLoader.startLoader('customLoaderId', 'loader-03');
    if ($("html").hasClass("theme-light")) {
      this.bgcolorcode='rgb(17,19,25)';
      // this.colorcode='rgb(17,19,25)';
    }
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let user_uuid,selectedrole;
    if(this.userselectedvalue!="" && this.userselectedvalue[0]['category']=='Publisher'){
      user_uuid = this.userselectedvalue[0]['id'];
      selectedrole = 3;
    }else{
      if(role==""){
        selectedrole = this.currentUser.role;
        user_uuid = this.currentUser.uuid;
      }else{
        selectedrole = role;
        user_uuid = this.userselectedvalue[0].id;
      }
    }
    let buyer_uuids=[]
    if(this.currentUser.role==4){
      buyer_uuids = this.currentUser?.companydetails.map((item)=>item.user_uuid);      
    }
    this.chartarg = {
      filterselected: filterselected,
      date:date,
      user_uuid:user_uuid,
      role:selectedrole,
      buyer_uuids
    };
    this.moduleservice.getChartbyfilter(this.currentCompany.uuid,this.chartarg,daytype,this.currentUser.timezone).subscribe(
      res => {



        this.chart = new Chart({
          chart: {
            height:'20%',
            // polar: true,
            type: 'line',

            backgroundColor:this.bgcolorcode,
          },
          exporting: {
            enabled: true
          },
          title: {
            text: ''+this.charttype,
            style: {
              color: this.colorcode
            },
          },
          subtitle: {
            text: document.ontouchstart === undefined ?
              'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
              style: {
                color: this.colorcode
            },
          },
          credits: {
            enabled: false
          },
          tooltip: {
            shared: true,
          },
          xAxis: {
              minRange: 0,
              gridLineWidth: 0,
              title: {
                text: ''+this.charttype,
                style: {
                  color: this.colorcode
                },
              },categories: res.cattype,

          },
          yAxis: {
            min:0,
            gridLineWidth: 1,
            title: {
              text: 'Number Of Calls',
              style: {
                color: this.colorcode
            },
            },
            gridLineColor: '#2f3031'
          },
          colors: [
            'rgb(58,186,244)',
            ],
          plotOptions: {

            column: {
              colorByPoint: true
            },
            line: {
                  dataLabels: {
                    useHTML: true,
                    enabled: true,
                    format: '{point.percentage:.1f} %',
                    color: 'white',
                },
                enableMouseTracking: false,
            }
          },
          series: [{
            data: res.chartdata,
            type: 'column',
            name:"Call Count",
            color: '#0066FF',
          }
          ,{
            name: 'Converted',
            color: '#F9B236',
            type: 'spline',
            data: res.converted,
          },{
            name: 'Connected',
            type: 'spline',
            color: '#20bf55',
            data: res.connected,
          }
          ,{
            name: 'No Answer',
            type: 'spline',
            color: 'red',
            data: res.noanswer,
          },{
            name: 'No Connect',
            type: 'spline',
            color: 'red',
            data: res.noconnect,
            }
          ]
        });
        this.ngxLoader.stopLoader('customLoaderId', 'loader-03');
      },
      error => this.error = error
    );

  }

  calls = []
  lists = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  onActivate(event) {
    console.log('Activate Event', event);
  }

  selectedrow:any = [];
  onSelect(selectedrow) {
    // console.log('Select Event',selectedrow.selected);
    // console.log('Select Event', selectedrow, this.selectedrow);

    this.selectedrow.splice(0, this.selectedrow.length);
    this.selectedrow.push(...selectedrow.selected);
    // console.log(selectedrow.selected);

  }

  loadcalls(filterselected:any,date:any,role:any=""): void {
    // console.log(this.userselectedvalue[0]['id']);

    this.loadingIndicator = true;
    // this.ngxLoader.start();
    this.ngxLoader.startLoader('customLoaderId2');
    // this.ngxLoader.start();
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const formData = new FormData();
    formData.append('filterselected', JSON.stringify(filterselected));
    if(this.userselectedvalue!="" && this.userselectedvalue[0]['category']=='Publisher'){
      formData.append('user_uuid', this.userselectedvalue[0]['id']);
      formData.append('role', '3');
    }else{
      if(role==""){
        formData.append('user_uuid', currentUser.uuid);
        formData.append('role', currentUser.role);
      }else{
        formData.append('user_uuid', this.userselectedvalue[0].id);
        formData.append('role', role);
      }

    }

    formData.append('timezone', currentUser.timezone);
    formData.append('date', JSON.stringify(date));
    if(currentUser.role==4){
      let buyer_uuids = currentUser?.companydetails.map((item)=>item.user_uuid);
      formData.append('buyer_uuids', JSON.stringify(buyer_uuids));
    }
    // console.log("yyyy");
    
    this.http.post<any>(
      this.ServerUrl+'getcallsdatanew/'+currentCompany.uuid,
       formData,{
        headers: new HttpHeaders()
        // .set('Pragma', 'no-cache')
        // .set('Cache-Control', 'no-cache')
      }
    ).subscribe(resp => {
        this.calls = resp[0].Response.data;
        this.lists = resp[0].Response.data;
        this.loadingIndicator = false;

        // console.log(this.calls);
        
        // this.ngxLoader.stopBackground("customLoaderId2");
        this.ngxLoader.stopLoader('customLoaderId2');
        // console.log(this.blogPostsList);
    }, error => {
        // this.ngxLoader.stopBackground("customLoaderId2");
        this.ngxLoader.stopLoader('customLoaderId2');
        this.loadingIndicator = false;
    });

  }


  // livecalls = []
  // loadlivecalls(): void {
  //   this.loadingIndicator = true;
  //   this.http.post<any>(
  //     this.ServerUrl+'livecallReport/',
  //      {}
  //   ).subscribe(resp => {
  //     // console.log(resp.rows);
  //       this.livecalls = resp.rows;
  //       this.loadingIndicator = false;
  //   }, error => {
  //     this.loadingIndicator = false;
  //   });
  // }

  private loaddataonscroll(limit: number) {
    // this.ngxLoader.start();
    this.ngxLoader.startBackground('customLoaderId2');
    this.loadingIndicator = true;
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const formData = new FormData();
    formData.append('filterselected', JSON.stringify(this.filterselected));
    formData.append('timezone', currentUser.timezone);
    if(this.userselectedvalue!="" && this.userselectedvalue[0]['category']=='Publisher'){
      formData.append('user_uuid', this.userselectedvalue[0]['id']);
      formData.append('role', '3');
    }else{
      formData.append('user_uuid', currentUser.uuid);
      formData.append('role', currentUser.role);
    }
    formData.append('date', JSON.stringify(this.selected1));
    let newlimit = limit;
    let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    if(currentUser.role==4){
      let buyer_uuids = currentUser?.companydetails.map((item)=>item.user_uuid);
      formData.append('buyer_uuids', JSON.stringify(buyer_uuids));
    }
    
    this.http.post<any>(
      this.ServerUrl+'getcallsdatanew/'+currentCompany.uuid+'/'+this.calls.length+'/'+newlimit,
      formData
    ).subscribe(resp => {
        const rows = [...this.calls, ...resp[0].Response.data];
        this.calls = rows;
        this.lists = resp[0].Response.data;
        this.loadingIndicator = false;
        // this.ngxLoader.stop();
        this.ngxLoader.stopBackground('customLoaderId2');
        // console.log(this.blogPostsList);
    }, error => {
        this.loadingIndicator = false;
        // this.ngxLoader.stop();
        this.ngxLoader.stopBackground('customLoaderId2');
    });

  }

  isLoading: boolean;
  readonly headerHeight = 40;
  readonly rowHeight = 40;
  readonly pageLimit = 100;
  loadingIndicator = false;
  onScroll(event:any) {
      let offsetY = event.offsetY;
      let offsetX = event.offsetX;
      // console.log(event.offsetY);return
      // if(offsetY != 125 || offsetY!=undefined){
        const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;

          if (offsetX==0 && !this.loadingIndicator && offsetY + viewHeight >= this.calls.length * this.rowHeight) {
          // if (!this.loadingIndicator && offsetY + viewHeight >= this.rows.length * 100) {
          let limit = this.pageLimit;
          if (this.calls.length === 0) {
            const pageSize = Math.ceil(viewHeight / this.rowHeight);
            limit = Math.max(pageSize, this.pageLimit);
          }

          this.loaddataonscroll(limit);
        }
      // }
  }


  play(url: string, incomingnumber:any){

    this.toastrService.show(`<audio style="width: 130px;height: 30px;" controls><source src="https://d2actkasrcrrs8.cloudfront.net/${url}" type="audio/mp3"></audio>`,
    'Inbound # - '+incomingnumber , {
      closeButton: true,
        extendedTimeOut: 0,
        timeOut: 0,

        tapToDismiss: false,
             enableHtml: true,
             positionClass: 'toast-bottom-right' ,
         });
  }

  filterselectedforexport:any;
  dateforexport:any;
  // get f() { return this.searchcallsform.controls; }
  // loadcalls(filterselected:any,date:any){
  //   if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
  //     $(this.table.nativeElement).dataTable().fnDestroy();
  //   }

  //   this.filterselectedforexport = filterselected;
  //   this.dateforexport = date;

  //   this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  //   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));

  //   var that = this;
  //   this.dtOptionsdetails = {
  //     "pagingType": 'full_numbers',
  //     "processing": true,
  //     "serverSide": true,
  //     "lengthMenu": [[-1, 50, 100, 500, 2000], ["All", 50, 100, 500, 2000]],
  //     "pageLength": 100,
  //     "order": [[ 2, "desc" ]],
  //     "searching":false,
  //     ajax: (dataTablesParameters: any, callback) => {
  //       that.http.post<DataTablesResponse>(
  //         this.ServerUrl+'getcalls/'+this.currentCompany.uuid, {'dataTablesParameters':dataTablesParameters,'filterselected':filterselected,date:date,'timezone':this.currentUser.timezone}
  //        , {}
  //       ).subscribe(resp => {
  //                 // console.log(resp); return false;
  //                 that.labels = resp.data;
  //                 callback({
  //                     recordsTotal: resp.recordsTotal,
  //                     recordsFiltered: resp.recordsFiltered,
  //                     data: [],
  //                 });
  //             });
  //     },
  //     columns: [
  //       //
  //       {data:"detail"},{data:"did"},{data:"incoming_number"},{data:"publisher"},{data:"target"},{data:"campaign"},{data:"routed_to"},{data:"call_duration"},{data:"ivr_duration"},{data:"billable_duration"},{data:"date_of_call"},{data:"assosciated_labels"},{data:"hangup_by"},{data:"revenue"},{data:"duplicate"},{data:"payout"},{data:"recording"}
  //     ],
  // };

  // this.dataTable = $(this.table.nativeElement);
  // this.dataTable.dataTable(this.dtOptionsdetails);
  // }

  // get f() { return this.searchcallsform.controls; }
  totaldata:any=[];
  totalcount:any=[];

  // loadcallsdata(filterselected:any,date:any,groupby:any){
  //   $("#datatable1").dataTable().fnDestroy();
  //   // $("#summarytbl").dataTable().fnDestroy();

  //   this.ngxLoader.startLoader('loader-02');
  //   this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  //   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  //   // this.ngxLoader.startLoader('loader-03');

  //   this.http.post<any>(
  //             this.ServerUrl+'getcallsdata/'+this.currentCompany.uuid, {'filterselected':filterselected,'date':date,'timezone':this.currentUser.timezone,'groupby':groupby}
  //           , {}
  //           ).subscribe(resp => {
  //                 //
  //                 this.callsdata = resp.data;
  //                 this.totalcount = resp.totalarr;
  //                 this.totaldata  = resp.data.length ;
  //                 this.dtTrigger.next();
  //                 this.ngxLoader.stopLoader('loader-02');
  //               },
  //               error => this.error = error
  //           );


  // }


  // loadcallsdata(filterselected:any,date:any,groupby:any){

  //   this.ngxLoader.startLoader('loader-02');
  //   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  //   let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  //       this.http.post<any>(
  //         this.ServerUrl+'getcallsdata/'+this.currentCompany.uuid, {'filterselected':filterselected,'date':date,'timezone':currentUser.timezone,'groupby':groupby}
  //       , {}
  //       ).subscribe(resp => {
  //           // console.log(resp);
  //           this.callsdata = resp.data;
  //           this.totalcount = resp.totalarr;
  //           this.totaldata  = resp.data.length ;
  //           this.dtTrigger.next();
  //           this.ngxLoader.stopLoader('loader-02');
  //         },
  //         error => this.error = error
  //     );

  // }
  isloading:any = false
  loadcallsdata(filterselected:any,date:any,groupby:any,selerole:any=""){
    this.isloading = true
    if ($.fn.DataTable.isDataTable(this.table1.nativeElement) ) {
      $(this.table1.nativeElement).dataTable().fnDestroy();
    }
    // console.log(date);
    this.ngxLoader.startLoader('customLoaderId1');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let buyer_uuids=[]
    if(this.currentUser.role==4){
      buyer_uuids = this.currentUser?.companydetails.map((item)=>item.user_uuid);      
    }

    let user_uuid,role;
    if(this.userselectedvalue!="" && this.userselectedvalue[0]['category']=='Publisher'){
      user_uuid = this.userselectedvalue[0]['id'];
      role = 3;
    }else{
      if(selerole==""){
        user_uuid = this.currentUser.uuid;
        role = this.currentUser.role;
      }else{
        role = selerole;
        user_uuid = this.userselectedvalue[0].id;
      }
    }

    if(this.currentUser.role==4){
      this.filtertype=2
      $("#tabs3").addClass("activetab");
      groupby=2;
    }

    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));

    this.dtOptions = {
      pagingType: 'full_numbers',
      lengthChange:false,
      paging:false,
      searching:false,
      // pageLength: 200
    };
      this.http.post<any>(
          this.ServerUrl+'getcallsdata/'+this.currentCompany.uuid, {'filterselected':filterselected,'date':date,'role':role,'user_uuid':user_uuid,'timezone':this.currentUser.timezone,'groupby':groupby,'tagselectedvalue':this.tagselectedvalue, buyer_uuids:(buyer_uuids)}
        , {}
        ).subscribe(resp => {
              this.isloading = false;
              this.callsdata = resp.data;
              this.totalcount = resp.totalarr;
              this.totaldata  = resp.data.length ;
              if(resp.data.length>0){
                this.dtTrigger.next();
              }
              this.ngxLoader.stopLoader('customLoaderId1');
            },
            error => this.error = error
        );
    // var that = this;
    // this.dtOptions = {
    //   "dom": '<"top">rt<"bottom"flp><"clear">',
    //   "pagingType": 'full_numbers',
    //   // "processing": true,
    //   // "serverSide": false,
    //   // "lengthMenu": [[-1, 50, 100, 500, 2000, 5000], ["All", 50, 100, 500, 2000, 5000 ]],
    //   // "pageLength": 100,
    //   // "order": [[ 2, "asc" ]],
    //   "searching":false,
    //   ajax: (dataTablesParameters: any, callback) => {
    //   //   that.http.post<DataTablesResponse>(
    //   //     this.ServerUrl+'getcallsdata/'+this.currentCompany.uuid, {'dataTablesParameters':dataTablesParameters,'filterselected':filterselected,'date':date,'timezone':this.currentUser.timezone,'groupby':groupby}
    //   //    , {}
    //   //   ).subscribe(resp => {

    //     this.http.post<any>(
    //               this.ServerUrl+'getcallsdata/'+this.currentCompany.uuid, {'filterselected':filterselected,'date':date,'timezone':currentUser.timezone,'groupby':groupby}
    //             , {}
    //             ).subscribe(resp => {
    //               // console.log(resp); return false;
    //               this.callsdata = resp.data;
    //               this.totalcount = resp.totalarr;
    //               this.totaldata  = resp.data.length ;
    //               // this.totaldata  = resp.data.length ;

    //               callback({
    //                   recordsTotal: resp.recordsTotal,
    //                   recordsFiltered: resp.recordsFiltered,
    //                   data: [],
    //               });
    //           });
    //   },
    //   columns: [
    //     {data:"filter"},{data:"incoming"},{data:"uniquecalls"},{data:"ivr_inputs"},{data:"reachedtobuyer"},{data:"connected"},{data:"qualified"},{data:"converted"},{data:"completed"},{data:"no_answer"},{data:"no_connect"},{data:"duplicate"},{data:"payout"},{data:"revenue"},{data:"profit"},{data:"cr_percent"},{data:"rpc"},{data:"acl"},{data:"tcl"},
    //   ],
  // };

  // this.dataTable1 = $(this.table1.nativeElement);
  // this.dataTable1.dataTable(this.dtOptions);
  // this.ngxLoader.stopLoader('loader-02');
  }



  // addActive(id){
  //   alert(id);
  // }
  ivr_info:any = []
  details(i,data){
          // console.log(data);
          // return
          // var tr = $('td.details-control').closest('tr').eq(i);
          // var divClass = $(".detailrow_"+i).eq(0).attr('class');
          // if (divClass.indexOf('shown') > -1) {
          //     $(".appendedtbl_"+i).remove();
          //       $(".detailrow_"+i).removeClass('shown');
          //   }else {
              this.ngxLoader.start();
              this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
              this.moduleservice.getReportdetailsdata(data.uuid,this.currentUser.uuid).subscribe(
                res => {
                  // console.log(this.gmttime);
                  // Target
                  this.ivr_info = res.data[0].ivr_details_info;
                  let time = moment.utc(res.data[0].start_stamp).utcOffset(this.gmttime).format("YYYY-MM-DD HH:mm:ss");
                  let endtime = moment.utc(res.data[0].end_stamp).utcOffset(this.gmttime).format("YYYY-MM-DD HH:mm:ss");

                  this.ngxLoader.stop();

                  var targetid;
                  if(res.tagetdialed[0].targetid!=null){
                    targetid = res.tagetdialed[0].targetid;
                  }else{
                    targetid="-";

                  }

                  var targetname;
                  if(res.tagetdialed[0].targetname!=null){
                    targetname = res.tagetdialed[0].targetname;
                  }else{
                    targetname="-";
                  }

                  var targetnumber;
                  if(res.tagetdialed[0].targetnumber!=null){
                    targetnumber = res.tagetdialed[0].targetnumber;
                  }else{
                    targetnumber="-";
                  }

                  var payout;
                  if(res.tagetdialed[0].payout>0.0){
                    payout = "$"+res.tagetdialed[0].payout;
                  }else{
                    payout="-";
                  }

                  var recording;
                  if(res.data[0].recording!=null){
                    // recording = "$"+res.tagetdialed[0].recording;
                    recording = '<a target="_blank" style="color:white" href="https://d2actkasrcrrs8.cloudfront.net/'+res.data[0].recording+'">https://d2actkasrcrrs8.cloudfront.net/'+res.data[0].recording+'</a>';
                  }else{
                    recording="-";
                  }

                  var hangup_by;
                  if(res.data[0].hangup_by=='User'){
                    hangup_by = "Caller";
                  }else if(res.data[0].hangup_by=='System'){
                    hangup_by="System";
                  }else{
                    hangup_by="-";
                  }

                  var plan ='<table class="routingtbl" style="width:100%">'+
                            '<tr style="border:none">'+
                              '<th style="border:none">Priority</th>'+
                              '<th style="border:none">Weight</th>'+
                              '<th style="border:none">Name</th>'+
                              '<th style="border:none">Reason</th>'+
                              '</tr>';
                            var result="";
                            var plan1="";
                            var result1="";
                            for (let index = 0; index < res.routingplan_inele.length; index++) {
                                const data =  res.routingplan_inele[index];
                                if(data.iseligible==1){
                                  plan1 =
                                    '<tr style="border:none">'+
                                      '<td style="border:none">'+data.priority+'</td>'+
                                      '<td style="border:none">'+data.weight+'</td>'+
                                      '<td style="border:none">'+data.name+'</td>'+
                                      '<td style="border:none">'+data.reson+'</td>'+
                                    '</tr>';
                                  result1 += result.concat(plan1);
                                }
                            }
                            // console.log(plan); return
                  var finalresult = '<span>No Data Available</span>';
                if(res.routingplan_inele.length>0){
                  finalresult = plan.concat(result1);
                  finalresult += result.concat('</table>');
                }

                  var plan_ele ='<table class="routingtbl" style="width:100%">'+
                            '<tr style="border:none">'+
                              '<th style="border:none">Priority</th>'+
                              '<th style="border:none">Weight</th>'+
                              '<th style="border:none">Name</th>'+
                              '<th style="border:none">Reason</th>'+
                              '</tr>';
                            var result_ele="";
                            var plan1="";
                            var result2="";
                            for (let index = 0; index < res.routingplan_ele.length; index++) {
                                const data =  res.routingplan_ele[index];
                                if(data.iseligible==0){
                                  plan1 =
                                    '<tr style="border:none">'+
                                      '<td style="border:none">'+data.priority+'</td>'+
                                      '<td style="border:none">'+data.weight+'</td>'+
                                      '<td style="border:none">'+data.name+'</td>'+
                                      '<td style="border:none">'+data.reson+'</td>'+
                                    '</tr>';
                                  result2 += result.concat(plan1);
                                }
                            }
                            // console.log(plan); return
                  var finalresult_ele = '<span>No Data Available</span>';
                  if(res.routingplan_ele.length>0){
                  finalresult_ele = plan_ele.concat(result2);
                  var finalresult = finalresult;
                  finalresult += result_ele.concat('</table>');
                  finalresult_ele += result_ele.concat('</table>');
                  }

                  var ivrnode ='';
                  var ivrinfo ='';
                  var nodeexisttime="";
                  if(res.data[0].ivr_inputs!='' && res.data[0].ivr_inputs!=null){
                    if(res.data[0].ivr_details_info!=""){
                      let info = res.data[0].ivr_details_info;
                      let ivrinforesult="";
                      if(info && info.length>0){
                        // console.log(info);

                        for (let index = 0; index < info.length; index++) {
                            const data =  info[index];
                            // let nodeentertime = moment.utc(data.nodeentertime, "YYYY-MM-DD HH:mm:ss").tz('America/Phoenix').format("YYYY-MM-DD HH:mm:ss")
                            // let nodeexisttime = moment.utc(data.nodeexisttime, "YYYY-MM-DD HH:mm:ss").tz('America/Phoenix').format("YYYY-MM-DD HH:mm:ss")
                            let nodeentertime = moment.utc(data.nodeentertime).utcOffset(this.gmttime).format("YYYY-MM-DD HH:mm:ss");
                            nodeexisttime = moment.utc(data.nodeexisttime).utcOffset(this.gmttime).format("YYYY-MM-DD HH:mm:ss");

                            var a = moment(nodeexisttime);//now
                            var b = moment(nodeentertime);

                            var hrs = moment.utc(a.diff(b)).format("HH");
                            var min = moment.utc(a.diff(b)).format("mm");
                            var sec = moment.utc(a.diff(b)).format("ss");
                            let nodediffrence = [hrs, min, sec].join(':');


                            // let nodediffrence = a.diff(b, 'hours')+':'+a.diff(b, 'hours')+':'+a.diff(b, 'seconds')

                            // console.log(a.diff(b, 'minutes')) // 44700
                            // console.log(a.diff(b, 'hours')) // 745
                            // console.log(a.diff(b, 'seconds')) // 745

                            ivrinfo ='<div class="activity mt-2">'+
                              '<div class="activity-icon bg-blue text-white">'+
                                '<i class="fas fa-info"></i>'+
                              '</div>'+
                              '<div class="activity-detail" style="width: 35%!important;">'+
                              '<div class="">'+
                              '<span class="text-job text-primary">IVR Node Enter</span>'+'</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Timestamp</div>'+
                                  '<div class="col-md-6">'+
                                  nodeentertime+
                                  // '<span class="ml-2" #copyname><i style="font-size: 10px;cursor: pointer;" class="fa fa-copy"></i></span></div>'+
                                '</div>'+
                                '</div>'+
                                '<div class="row">'+
                                  '<div class="col-md-6">Node Type</div>'+
                                  '<div class="col-md-6">MenuNode</div>'+
                                '</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Step Name</div>'+
                                  '<div class="col-md-6">'+data.ivr+'</div>'+
                                '</div>'+
                              '</div>'+
                            '</div>'+

                            '<div class="activity">'+
                              '<div class="activity-icon bg-blue text-white">'+
                                '<i class="fas fa-info"></i>'+
                              '</div>'+
                              '<div class="activity-detail" style="width: 35%!important;">'+
                                '<div class="">'+
                                  '<span class="text-job text-primary">User Input</span>'+'</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Timestamp</div>'+
                                  '<div class="col-md-6">'+nodeexisttime+'</div>'+
                                '</div>'+
                                '<div class="row">'+
                                  '<div class="col-md-6">Digit</div>'+
                                  '<div class="col-md-6">'+data.dtmf+'</div>'+
                                '</div>'+
                              '</div>'+
                            '</div>'+

                            '<div class="activity">'+
                              '<div class="activity-icon bg-blue text-white">'+
                                '<i class="fas fa-info"></i>'+
                              '</div>'+
                              '<div class="activity-detail" style="width: 35%!important;">'+
                                '<div class="">'+
                                  '<span class="text-job text-primary">IVR Node Exit</span>'+'</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Timestamp</div>'+
                                  '<div class="col-md-6">'+nodeexisttime+'</div>'+
                                '</div>'+
                                '<div class="row">'+
                                  '<div class="col-md-6">Node Type</div>'+
                                  '<div class="col-md-6">MenuNode</div>'+
                                '</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Step Name</div>'+
                                  '<div class="col-md-6">'+data.ivr+'</div>'+
                                '</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Node '+index+1+' Spent Time</div>'+
                                  '<div class="col-md-6">'+nodediffrence+'</div>'+
                                '</div>'+
                              '</div>'+
                            '</div>';
                            ivrinforesult += ivrinfo;
                        }
                      }
                      ivrnode = ivrinforesult;
                   }else{
                    ivrnode ='<div class="activity mt-2">'+
                              '<div class="activity-icon bg-blue text-white">'+
                                '<i class="fas fa-info"></i>'+
                              '</div>'+
                              '<div class="activity-detail" style="width: 35%!important;">'+
                              '<span class="text-job text-primary">IVR Node Enter</span>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Timestamp</div>'+
                                  '<div class="col-md-6">'+time+'</div>'+
                                '</div>'+
                                '<div class="row">'+
                                  '<div class="col-md-6">Node Type</div>'+
                                  '<div class="col-md-6">MenuNode</div>'+
                                '</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Step Name</div>'+
                                  '<div class="col-md-6">Menu</div>'+
                                '</div>'+
                              '</div>'+
                            '</div>'+

                            '<div class="activity">'+
                              '<div class="activity-icon bg-blue text-white">'+
                                '<i class="fas fa-info"></i>'+
                              '</div>'+
                              '<div class="activity-detail" style="width: 35%!important;">'+
                                '<div class="">'+
                                  '<span class="text-job text-primary">User Input</span>'+'</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Timestamp</div>'+
                                  '<div class="col-md-6">'+time+'</div>'+
                                '</div>'+
                                '<div class="row">'+
                                  '<div class="col-md-6">Digit</div>'+
                                  '<div class="col-md-6">'+res.data[0].ivr_inputs+'</div>'+
                                '</div>'+
                              '</div>'+
                            '</div>'+

                            '<div class="activity">'+
                              '<div class="activity-icon bg-blue text-white">'+
                                '<i class="fas fa-info"></i>'+
                              '</div>'+
                              '<div class="activity-detail" style="width: 35%!important;">'+
                                '<div class="">'+
                                  '<span class="text-job text-primary">IVR Node Exit</span>'+'</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Timestamp</div>'+
                                  '<div class="col-md-6">'+time+'</div>'+
                                '</div>'+
                                '<div class="row">'+
                                  '<div class="col-md-6">Node Type</div>'+
                                  '<div class="col-md-6">MenuNode</div>'+
                                '</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Step Name</div>'+
                                  '<div class="col-md-6">Menu</div>'+
                                '</div>'+
                              '</div>'+
                            '</div>';

                   }
                  }
                  // console.log(ivrnode);

                  let routingtime;
                  if(nodeexisttime!=""){
                    routingtime = nodeexisttime;
                  }else{
                    routingtime = time;
                  }

                  if(res.routingplan_inele.length>0){
                    if(res.routingplan_inele[0]['enter_time']!=''){
                      routingtime = res.routingplan_inele[0]['enter_time'];
                      routingtime = moment.utc(routingtime).utcOffset(this.gmttime).format("YYYY-MM-DD HH:mm:ss");
                    }
                  }
                  // console.log(res.routingplan_inele.length , res.routingplan_ele.length , res.data[0].is_callerid_blocked);

                  var routingplan ='';
                  if((res.routingplan_inele.length>0 || res.routingplan_ele.length>0) && res.data[0].is_callerid_blocked==0){
                  let st="";
                  if(res.data[0].is_predictive_routing=="1"){
                    st =  '<div class="row">'+
                    '<div class="col-md-6">Is Predictive Routing</div>'+
                    '<div class="col-md-6">Yes</div>'+
                    '</div>';
                  }
                  routingplan='<div class="activity">'+
                            '<div class="activity-icon bg-blue text-white">'+
                              '<i class="fas fa-info"></i>'+
                            '</div>'+
                            '<div class="activity-detail" style="width: 35%!important;">'+
                              '<div class="">'+
                                '<span class="text-job text-primary">Routing Plan</span>'+'</div>'+

                                '<div class="row">'+
                                  '<div class="col-md-6">Timestamp</div>'+
                                  '<div class="col-md-6">'+routingtime+'</div>'+
                                '</div>'+
                                st
                               +
                                '<div class="row mt-2" >'+
                                  '<div class="col-md-6">Ineligible Targets	</div>'+
                                '</div>'+

                                '<div class="row">'+
                                '<div class="col-md-4">'+
                                finalresult+
                                '</div>'+
                                '</div>'+

                                '<div class="row mt-2" >'+
                                  '<div class="col-md-6">Eligible Targets	</div>'+
                                '</div>'+

                                '<div class="row">'+
                                '<div class="col-md-4">'+
                                finalresult_ele+
                                '</div>'+
                                '</div>'+
                            '</div>'+
                          '</div>';
                  }else if(res.data[0].is_callerid_blocked==1){
                     routingplan +='<div class="activity">'+
                     '<div class="activity-icon bg-danger text-white">'+
                       '<i class="fas fa-exclamation"></i>'+
                     '</div>'+
                     '<div class="activity-detail" style="width: 35%!important;">'+
                       '<div class="">'+
                         '<span class="text-job text-primary">Blocked Call</span>'+'</div>'+
                       '<div class="row" >'+
                         '<div class="col-md-6">Timestamp</div>'+
                         '<div class="col-md-6">'+time+'</div>'+
                       '</div>'+
                       '<div class="row">'+
                         '<div class="col-md-6">Reason  </div>'+
                         '<div class="col-md-6">Blocked By Account</div>'+
                       '</div>'+
                     '</div>'+
                   '</div>' +

                   '<div class="activity">'+
                     '<div class="activity-icon bg-danger text-white">'+
                       '<i class="fas fa-exclamation"></i>'+
                     '</div>'+
                     '<div class="activity-detail" style="width: 35%!important;">'+
                       '<div class="">'+
                         '<span class="text-job text-primary">NO Target Answered</span>'+'</div>'+
                       '<div class="row" >'+
                         '<div class="col-md-6">Timestamp</div>'+
                         '<div class="col-md-6">'+time+'</div>'+
                       '</div>'+
                     '</div>'+
                   '</div>'
                  }

                  var targetdialed ='';
                  var connecteddata ='';
                  var completeddata ='';
                  var ringtree ='';
                  
                  
                  if(res.data[0].target_dial_info!='' && res.data[0].target_dial_info!=null){
                    if(res.data[0].target_dial_info!=""){
                      let info =res.tagetdialed;
                      let targetinforesult="";
                      if(info && info.length>0){
                        // console.log(info);
                        var targetinfo ='';
                        for (let index = 0; index < info.length; index++) {
                            const data =  info[index];
                            // console.log(data);
                            let dialdate = moment.utc(data.dialtime).utcOffset(this.gmttime).format("YYYY-MM-DD");
                            let dialtime = moment.utc(data.dialtime).utcOffset(this.gmttime).format("YYYY-MM-DD HH:mm:ss");
                            let dialend = moment.utc(data.dialend).utcOffset(this.gmttime).format("YYYY-MM-DD HH:mm:ss");
                            let cond;
                            if(dialdate <= '2023-11-18'){
                              // console.log('yes');
                              
                              cond = res.data[0].ringtree_api_response!="" && res.data[0].ringtree_api_response!=null
                            }else{
                              // console.log('no');
                              cond = res.data[0].is_ringtree==1
                            }
                            // console.log(cond);
                            if(cond){
                            // if(res.data[0].ringtree_api_response!="" && res.data[0].ringtree_api_response!=null){
                       
                              let ringtree_frwd = res.data[0].ringtree_frwd!=""?res.data[0].ringtree_frwd:"-";
                              let ringtree_api_url = res.data[0].ringtree_api_url!=""?res.data[0].ringtree_api_url:"-";
                              let ringtree_payout = res.data[0].ringtree_payout!=""?res.data[0].ringtree_payout:"-";
                              let zip_code = res.data[0].zip_code!=""?res.data[0].zip_code:"-";
                              let ringtree_billableDuration = res.data[0].ringtree_billableDuration!=""?res.data[0].ringtree_billableDuration:"-";
                              let ringtree_api_response = res.data[0].ringtree_api_response!=""?res.data[0].ringtree_api_response:"-";
                             ringtree = '<div class="activity">'+
                               '<div class="activity-icon bg-success text-white">'+
                                 '<i class="fas fa-check"></i>'+
                               '</div>'+
                               '<div class="activity-detail" style="width: 70%!important;">'+
                               '<div class="">'+
                                 '<span class="text-job text-white">Ringtree</span>'+'</div>'+
                                 '<div class="row" >'+
                                 '<div class="col-md-3">Ringtree URL</div>'+
                                 '<div class="col-md-3">'+ringtree_api_url+'</div>'+
                                '</div>'+
                                 '<div class="row" >'+
                                   '<div class="col-md-3">Ringtree Forward</div>'+
                                   '<div class="col-md-3">'+ringtree_frwd+'</div>'+
                                 '</div>'+
                                 '<div class="row">'+
                                   '<div class="col-md-3">Ringtree Payout</div>'+
                                   '<div class="col-md-3">'+ringtree_payout+'</div>'+
                                 '</div>'+
                                 '<div class="row" >'+
                                   '<div class="col-md-3">ZIP Code</div>'+
                                   '<div class="col-md-3">'+zip_code+'</div>'+
                                 '</div>'+
                                 '<div class="row">'+
                                   '<div class="col-md-3">Ringtree Billable Duration</div>'+
                                   '<div class="col-md-3">'+ringtree_billableDuration+'</div>'+
                                 '</div>'+
                                 '<div class="row" >'+
                                   '<div class="col-md-3">Ringtree API Response</div>'+
                                   '<div class="col-md-9" style="white-space: initial;">'+ringtree_api_response+'</div>'+
                                 '</div>'+
                             '</div>'+
                             '</div>';

                            }
                          var isshowbuyer = "";
                          if(this.currentUser.role!=3) {
                          isshowbuyer = '<div class="row">'+
                              '<div class="col-md-6">Buyer</div>'+
                              '<div class="col-md-6">'+data.buyer+'</div>'+
                            '</div>'+
                            '<div class="row" >'+
                              '<div class="col-md-6">Buyer ID</div>'+
                              '<div class="col-md-6">'+data.buyerid+'</div>'+
                            '</div>';
                          }

                            targetinfo='<div class="activity">'+
                              '<div class="activity-icon bg-success text-white">'+
                                '<i class="fas fa-check"></i>'+
                              '</div>'+
                              '<div class="activity-detail" style="width: 35%!important;">'+
                                '<div class="">'+
                                  '<span class="text-job text-primary">Target Dialed</span>'+'</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Timestamp</div>'+
                                  '<div class="col-md-6">'+dialtime+'</div>'+
                                '</div>'+
                                isshowbuyer+
                                '<div class="row">'+
                                  '<div class="col-md-6">Target ID</div>'+
                                  '<div class="col-md-6">'+data.target_uuid+'</div>'+
                                '</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Target Name</div>'+
                                  '<div class="col-md-6">'+data.target+'</div>'+
                                '</div>'+
                                '<div class="row" >'+
                                '<div class="col-md-6">Routing Number</div>'+
                                '<div class="col-md-6">'+data.dialnumber+'</div>'+
                                '</div>'+
                              '</div>'+
                            '</div>';

                            if(data.hangup_cause!='SUCCESS'){
                              targetinfo +='<div class="activity">'+
                              '<div class="activity-icon bg-danger text-white">'+
                                '<i class="fas fa-exclamation"></i>'+
                              '</div>'+
                              '<div class="activity-detail" style="width: 35%!important;">'+
                                '<div class="">'+
                                  '<span class="text-job text-primary">Failed Call</span>'+'</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Timestamp</div>'+
                                  '<div class="col-md-6">'+dialend+'</div>'+
                                '</div>'+
                                isshowbuyer+
                                '<div class="row">'+
                                  '<div class="col-md-6">Target ID</div>'+
                                  '<div class="col-md-6">'+data.target_uuid+'</div>'+
                                '</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Target Name</div>'+
                                  '<div class="col-md-6">'+data.target+'</div>'+
                                '</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Hangup Cause</div>'+
                                  '<div class="col-md-6">'+data.hangup_cause+'</div>'+
                                '</div>'+
                              '</div>'+
                            '</div>';

                            }else{

                            connecteddata =  '<div class="activity">'+
                              '<div class="activity-icon bg-success text-white">'+
                                '<i class="fas fa-check"></i>'+
                              '</div>'+
                              '<div class="activity-detail" style="width: 35%!important;">'+
                                '<div class="">'+
                                  '<span class="text-job text-primary">Connected</span>'+'</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Timestamp</div>'+
                                  '<div class="col-md-6">'+dialtime+'</div>'+
                                '</div>'+
                                isshowbuyer+
                                '<div class="row">'+
                                  '<div class="col-md-6">Target ID</div>'+
                                  '<div class="col-md-6">'+data.target_uuid+'</div>'+
                                '</div>'+
                                '<div class="row" >'+
                                  '<div class="col-md-6">Target Name</div>'+
                                  '<div class="col-md-6">'+data.target+'</div>'+
                                '</div>'+
                                '<div class="row" >'+
                                '<div class="col-md-6">Routing Number</div>'+
                                '<div class="col-md-6">'+data.dialnumber+'</div>'+
                                '</div>'+
                              '</div>'+
                            '</div>';


                            completeddata =  '<div class="activity">'+
                            '<div class="activity-icon bg-blue text-white">'+
                              '<i class="fas fa-info"></i>'+
                            '</div>'+
                            '<div class="activity-detail" style="width: 35%!important;">'+
                              '<div class="">'+
                                '<span class="text-job text-primary">Completed</span>'+'</div>'+
                              '<div class="row" >'+
                                '<div class="col-md-6">Timestamp</div>'+
                                '<div class="col-md-6">'+dialend+'</div>'+
                              '</div>'+
                              isshowbuyer+
                              '<div class="row">'+
                                '<div class="col-md-6">Target ID</div>'+
                                '<div class="col-md-6">'+data.target_uuid+'</div>'+
                              '</div>'+
                              '<div class="row" >'+
                                '<div class="col-md-6">Target Name</div>'+
                                '<div class="col-md-6">'+data.target+'</div>'+
                              '</div>'+
                              '<div class="row" >'+
                                '<div class="col-md-6">Routing Number</div>'+
                                '<div class="col-md-6">'+data.dialnumber+'</div>'+
                              '</div>'+
                              '<div class="row" >'+
                                '<div class="col-md-6">Hangup Cause</div>'+
                                '<div class="col-md-6">'+data.hangup_cause+'</div>'+
                              '</div>'+
                            '</div>'+
                          '</div>';

                            }
                            targetinforesult += targetinfo;
                        }
                      }
                      targetdialed = targetinforesult;
                   }
                  }else{
                      // console.log(data.ringtree_api_response);
                              // console.log(res.data[0].is_ringtree);
                    if(res.data[0].is_ringtree==1){
                      // console.log(data);
                       let ringtree_frwd = data.ringtree_frwd!=""?data.ringtree_frwd:"-";
                       let ringtree_api_url = data.ringtree_api_url!=""?data.ringtree_api_url:"-";
                       let ringtree_payout = data.ringtree_payout!=""?data.ringtree_payout:"-";
                       let zip_code = data.zip_code!=""?data.zip_code:"-";
                       let ringtree_billableDuration = data.ringtree_billableDuration!=""?data.ringtree_billableDuration:"-";
                       let ringtree_api_response = data.ringtree_api_response!=""?data.ringtree_api_response:"-";
                      ringtree = '<div class="activity">'+
                        '<div class="activity-icon bg-success text-white">'+
                          '<i class="fas fa-check"></i>'+
                        '</div>'+
                        '<div class="activity-detail" style="width: 70%!important;">'+
                        '<div class="">'+
                          '<span class="text-job text-white">Ringtree</span>'+'</div>'+
                          '<div class="row" >'+
                            '<div class="col-md-3">Ringtree URL</div>'+
                            '<div class="col-md-3">'+ringtree_api_url+'</div>'+
                          '</div>'+
                          '<div class="row" >'+
                            '<div class="col-md-3">Ringtree Forward</div>'+
                            '<div class="col-md-3">'+ringtree_frwd+'</div>'+
                          '</div>'+
                          '<div class="row">'+
                            '<div class="col-md-3">Ringtree Payout</div>'+
                            '<div class="col-md-3">'+ringtree_payout+'</div>'+
                          '</div>'+
                          '<div class="row" >'+
                            '<div class="col-md-3">ZIP Code</div>'+
                            '<div class="col-md-3">'+zip_code+'</div>'+
                          '</div>'+
                          '<div class="row">'+
                            '<div class="col-md-3">Ringtree Billable Duration</div>'+
                            '<div class="col-md-3">'+ringtree_billableDuration+'</div>'+
                          '</div>'+
                          '<div class="row" >'+
                            '<div class="col-md-3">Ringtree API Response</div>'+
                            '<div class="col-md-9" style="white-space: initial;">'+ringtree_api_response+'</div>'+
                          '</div>'+
                      '</div>'+
                      '</div>';
                      // console.log(ringtree);
                    }
                  }
                  // console.log(data.ringtree_api_response);


                  // console.log(targetdialed);

                  // if(res.data[0].billsec>0){
                  //   targetdialed='<div class="activity">'+
                  //   '<div class="activity-icon bg-success text-white">'+
                  //     '<i class="fas fa-check"></i>'+
                  //   '</div>'+
                  //   '<div class="activity-detail" style="width: 35%!important;">'+
                  //     '<div class="">'+
                  //       '<span class="text-job text-primary">Target Dialed</span>'+'</div>'+
                  //     '<div class="row" >'+
                  //       '<div class="col-md-6">Timestamp</div>'+
                  //       '<div class="col-md-6">'+time+'</div>'+
                  //     '</div>'+
                  //     '<div class="row">'+
                  //       '<div class="col-md-6">Buyer</div>'+
                  //       '<div class="col-md-6">'+res.tagetdialed[0].buyer+'</div>'+
                  //     '</div>'+
                  //     '<div class="row" >'+
                  //       '<div class="col-md-6">Buyer ID</div>'+
                  //       '<div class="col-md-6">'+res.tagetdialed[0].buyerid+'</div>'+
                  //     '</div>'+
                  //     '<div class="row">'+
                  //       '<div class="col-md-6">Target ID</div>'+
                  //       '<div class="col-md-6">'+targetid+'</div>'+
                  //     '</div>'+
                  //     '<div class="row" >'+
                  //       '<div class="col-md-6">Target Name</div>'+
                  //       '<div class="col-md-6">'+targetname+'</div>'+
                  //     '</div>'+
                  //     '<div class="row">'+
                  //       '<div class="col-md-6">Routing Number</div>'+
                  //       '<div class="col-md-6">'+res.tagetdialed[0].routedto+'</div>'+
                  //     '</div>'+
                  //   '</div>'+
                  // '</div>';
                  // }

                  var isshowbuyer1 = "";
                  if(this.currentUser.role!=3) {
                    isshowbuyer1 = '<div class="row">'+
                    '<div class="col-md-6">Buyer</div>'+
                    '<div class="col-md-6">'+res.tagetdialed[0].buyer+'</div>'+
                  '</div>'+
                  '<div class="row" >'+
                    '<div class="col-md-6">Buyer ID</div>'+
                    '<div class="col-md-6">'+res.tagetdialed[0].buyerid+'</div>'+
                  '</div>'
                  }

                  var converteddata ='';
                  if(res.data[0].payout>0){
                    converteddata = '<div class="activity">'+
                        '<div class="activity-icon bg-success text-white">'+
                          '<i class="fas fa-check"></i>'+
                        '</div>'+
                        '<div class="activity-detail" style="width: 35%!important;">'+
                          '<div class="">'+
                            '<span class="text-job text-primary">Converted</span>'+'</div>'+
                          '<div class="row" >'+
                            '<div class="col-md-6">Timestamp</div>'+
                            '<div class="col-md-6">'+time+'</div>'+
                          '</div>'+
                          isshowbuyer1+
                          '<div class="row">'+
                            '<div class="col-md-6">Payout</div>'+
                            '<div class="col-md-6">'+res.data[0].payout+'</div>'+
                          '</div>'+
                          '<div class="row" >'+
                            '<div class="col-md-6">Target ID</div>'+
                            '<div class="col-md-6">'+res.data[0].target_id+'</div>'+
                          '</div>'+
                          '<div class="row">'+
                            '<div class="col-md-6">Target Name</div>'+
                            '<div class="col-md-6">'+res.data[0].target_name+'</div>'+
                          '</div>'+
                          '<div class="row" >'+
                            '<div class="col-md-6">Routing Number</div>'+
                            '<div class="col-md-6">'+res.data[0].routed_to+'</div>'+
                          '</div>'+
                        '</div>'+
                      '</div>';
                  }

                  // var connecteddata ='';
                  // if(res.data[0].billsec>0){
                  //   connecteddata =  '<div class="activity">'+
                  //   '<div class="activity-icon bg-success text-white">'+
                  //     '<i class="fas fa-check"></i>'+
                  //   '</div>'+
                  //   '<div class="activity-detail" style="width: 35%!important;">'+
                  //     '<div class="">'+
                  //       '<span class="text-job text-primary">Connected</span>'+'</div>'+
                  //     '<div class="row" >'+
                  //       '<div class="col-md-6">Timestamp</div>'+
                  //       '<div class="col-md-6">'+time+'</div>'+
                  //     '</div>'+
                  //     '<div class="row">'+
                  //       '<div class="col-md-6">Buyer</div>'+
                  //       '<div class="col-md-6">'+res.tagetdialed[0].buyer+'</div>'+
                  //     '</div>'+
                  //     '<div class="row" >'+
                  //       '<div class="col-md-6">Buyer ID</div>'+
                  //       '<div class="col-md-6">'+res.tagetdialed[0].buyerid+'</div>'+
                  //     '</div>'+
                  //     '<div class="row">'+
                  //       '<div class="col-md-6">Connection Time</div>'+
                  //       '<div class="col-md-6">'+time+'</div>'+
                  //     '</div>'+
                  //     '<div class="row" >'+
                  //       '<div class="col-md-6">Target ID</div>'+
                  //       '<div class="col-md-6">'+targetid+'</div>'+
                  //     '</div>'+
                  //     '<div class="row">'+
                  //       '<div class="col-md-6">Target Name</div>'+
                  //       '<div class="col-md-6">'+targetname+'</div>'+
                  //     '</div>'+
                  //     '<div class="row" >'+
                  //     '<div class="col-md-6">Routing Number</div>'+
                  //     '<div class="col-md-6">'+res.tagetdialed[0].routedto+'</div>'+
                  //     '</div>'+
                  //   '</div>'+
                  // '</div>';
                  // }

                  // var enddata;
                  // console.log(res.tagetdialed[0].end_target_id);
                  // if(res.tagetdialed[0].end_target_id!='-'){
                  //   enddata = '<div class="row" >'+
                  //   '<div class="col-md-6">End Target ID</div>'+
                  //   '<div class="col-md-6">'+res.tagetdialed[0].end_target_id+'</div>'+
                  //   '</div>'+
                  //   '<div class="row">'+
                  //     '<div class="col-md-6">End Target Name</div>'+
                  //     '<div class="col-md-6">'+res.tagetdialed[0].endtargetname+'</div>'+
                  //   '</div>'+
                  //   '<div class="row" >'+
                  //     '<div class="col-md-6">End Buyer ID</div>'+
                  //     '<div class="col-md-6">'+res.tagetdialed[0].end_buyer_id+'</div>'+
                  //   '</div>'+
                  //   '<div class="row">'+
                  //     '<div class="col-md-6">End Buyer Name</div>'+
                  //     '<div class="col-md-6">'+res.tagetdialed[0].end_buyername+'</div>'+
                  //   '</div>';

                  // }else{
                  //   enddata="";
                  // }

                  // var completeddata =  '<div class="activity">'+
                  //     '<div class="activity-icon bg-blue text-white">'+
                  //       '<i class="fas fa-info"></i>'+
                  //     '</div>'+
                  //     '<div class="activity-detail" style="width: 35%!important;">'+
                  //       '<div class="">'+
                  //         '<span class="text-job text-primary">Completed</span>'+'</div>'+
                  //       '<div class="row" >'+
                  //         '<div class="col-md-6">Timestamp</div>'+
                  //         '<div class="col-md-6">'+time+'</div>'+
                  //       '</div>'+
                  //       '<div class="row">'+
                  //         '<div class="col-md-6">Buyer</div>'+
                  //         '<div class="col-md-6">'+res.tagetdialed[0].buyer+'</div>'+
                  //       '</div>'+
                  //       '<div class="row" >'+
                  //         '<div class="col-md-6">Buyer ID</div>'+
                  //         '<div class="col-md-6">'+res.tagetdialed[0].buyerid+'</div>'+
                  //       '</div>'+
                  //       '<div class="row">'+
                  //         '<div class="col-md-6">Call Length</div>'+
                  //         '<div class="col-md-6">'+res.data[0].duration+'</div>'+
                  //       '</div>'+
                  //       '<div class="row" >'+
                  //         '<div class="col-md-6">Completion Time</div>'+
                  //         '<div class="col-md-6">'+endtime+'</div>'+
                  //       '</div>'+
                  //        enddata+
                  //       '<div class="row">'+
                  //         '<div class="col-md-6">Customer IP</div>'+
                  //         '<div class="col-md-6">'+res.tagetdialed[0].customerip+'</div>'+
                  //       '</div>'+

                  //     '</div>'+
                  //   '</div>';

              var payoutcall ='';
              if(res.data[0].payout>0){
                payoutcall =  '<div class="activity">'+
                  '<div class="activity-icon bg-success text-white">'+
                    '<i class="fas fa-check"></i>'+
                  '</div>'+
                  '<div class="activity-detail" style="width: 35%!important;">'+
                    '<div class="">'+
                      '<span class="text-job text-white">Payout Call</span>'+'</div>'+
                      '<div class="row" >'+
                        '<div class="col-md-6">Timestamp</div>'+
                        '<div class="col-md-6">'+time+'</div>'+
                      '</div>'+
                      '<div class="row">'+
                        '<div class="col-md-6">Payout</div>'+
                        '<div class="col-md-6">'+payout+'</div>'+
                      '</div>'+
                  '</div>'+
                '</div>';
              }


              var adjust_payout ='';
              let payoutdata = res.data[0].payoutdata;

              var isshowbuyer2 = "";
              if(this.currentUser.role!=3 && payoutdata && payoutdata.buyer) {
                // console.log(payoutdata.buyer);

                isshowbuyer2 = '<div class="row">'+
                '<div class="col-md-4">Buyer</div>'+
                '<div class="col-md-3">'+payoutdata.buyer+'</div>'+
              '</div>'
              }

              if(payoutdata!=null){
                adjust_payout +=  '<div class="activity">'+
                  '<div class="activity-icon bg-success text-white">'+
                    '<i class="fas fa-check"></i>'+
                  '</div>'+
                  '<div class="activity-detail" style="width: 35%!important;">'+
                    '<div class="">'+
                      '<span class="text-job text-white">Conversation Adjustment Requested</span>'+'</div>'+
                      '<div class="row" >'+
                        '<div class="col-md-4">Timestamp</div>'+
                        '<div class="col-md-3">'+payoutdata.created_at+'</div>'+
                      '</div>'+
                      isshowbuyer2+
                      '<div class="row" >'+
                        '<div class="col-md-4">Buyer ID</div>'+
                        '<div class="col-md-3">'+payoutdata.user_uuid+'</div>'+
                      '</div>'+
                      '<div class="row">'+
                        '<div class="col-md-4">Reason</div>'+
                        '<div class="col-md-3">'+payoutdata.reason+'</div>'+
                      '</div>'+
                      '<div class="row" >'+
                        '<div class="col-md-4">Request ID</div>'+
                        '<div class="col-md-8" style="white-space: initial;">'+payoutdata.uuid+'</div>'+
                      '</div>'+
                      '<div class="row">'+
                        '<div class="col-md-4">Requested Adjustment Revenue</div>'+
                        '<div class="col-md-8" style="white-space: initial;">'+payoutdata.revenue+'</div>'+
                      '</div>'+
                      '<div class="row">'+
                        '<div class="col-md-4">Requested Adjustment Payout</div>'+
                        '<div class="col-md-8" style="white-space: initial;">'+payoutdata.payout+'</div>'+
                      '</div>'+
                  '</div>'+
                '</div>';
              }

              var blacklistaliance ='';
              if(res.data[0].is_blacklist_alliance_api==1){
                blacklistaliance =  '<div class="activity mt-2">'+
                  '<div class="activity-icon bg-blue text-white">'+
                  '<i class="fas fa-info"></i>'+
                '</div>'+
                  '<div class="activity-detail" style="width: 35%!important;">'+
                    '<div class="">'+
                      '<span class="text-job text-white">Blacklist Alliance API</span>'+'</div>'+
                      '<div class="row" >'+
                        '<div class="col-md-2">Blacklist API URL</div>'+
                        '<div class="col-md-10" style="white-space: initial;">'+res.data[0].blacklist_api_url+'</div>'+
                      '</div>'+
                      '<div class="row">'+
                        '<div class="col-md-2">Blacklist API Response</div>'+
                        '<div class="col-md-10" style="white-space: initial;">'+res.data[0].blacklist_api_response+'</div>'+
                      '</div>'+
                      '<div class="row" >'+
                        '<div class="col-md-2">Blacklist API Blocked</div>'+
                        '<div class="col-md-3">'+res.data[0].is_blacklist_api_blocked+'</div>'+
                      '</div>'+
                  '</div>'+
                '</div>';
              }
            // console.log(recording);

              var recordingdata ='';
              if(recording!="-" && recording!=null){
                let result = res.tagetdialed.filter(s => s.hangup_cause == "SUCCESS");
                // console.log(result);
                if(result.length==0){
                  result = res.tagetdialed[res.tagetdialed.length-1];
                }
                let buyer = result && result.length>0 ? result[0].buyer:'';
                let buyerid = result && result.length>0 ? result[0].buyerid:'';
                //  console.log(result);
                var isshowbuyer3 = "";
                if(this.currentUser.role!=3) {
                  isshowbuyer3 =  '<div class="row">'+
                  '<div class="col-md-2">Buyer</div>'+
                  '<div class="col-md-8 pl-4 ml-2">'+buyer+'</div>'+
                '</div>'+
                '<div class="row" >'+
                      '<div class="col-md-2">Buyer ID</div>'+
                      '<div class="col-md-8 pl-4 ml-2">'+buyerid+'</div>'+
                    '</div>'
                }
                recordingdata = '<div class="activity">'+
                '<div class="activity-icon bg-blue text-white">'+
                  '<i class="fas fa-info"></i>'+
                '</div>'+
                '<div class="activity-detail" style="width: 100%!important;">'+
                  '<div class="">'+
                    '<span class="text-job text-primary">Recorded</span>'+'</div>'+
                    '<div class="row" >'+
                    '<div class="col-md-2">Timestamp</div>'+
                    '<div class="col-md-8 pl-4 ml-2">'+routingtime+'</div>'+
                    '</div>'+
                    isshowbuyer3+
                    '<div class="row">'+
                      '<div class="col-md-2">Recording URL</div>'+
                      '<div class="col-md-8 pl-4 ml-2" style="white-space: initial;">'+recording+'</div>'+
                    '</div>'+
                '</div>'+
              '</div>'
              }

              var targetnoansnofound ='';
              if((res.data[0].is_eligible_found=='0' && res.data[0].target_eligible=="") && res.data[0].is_callerid_blocked==0){
                targetnoansnofound = '<div class="activity">'+
                '<div class="activity-icon bg-danger text-white">'+
                  '<i class="fas fa-exclamation"></i>'+
                '</div>'+
                '<div class="activity-detail" style="width: 100%!important;">'+
                  '<div class="">'+
                    '<span class="text-job text-primary">No Target Found</span>'+'</div>'+
                    '<div class="row" >'+
                    '<div class="col-md-2">Timestamp</div>'+
                    '<div class="col-md-8 pl-4 ml-2">'+routingtime+'</div>'+
                    '</div>'+
                '</div>'+
              '</div>'+
              '<div class="activity">'+
                '<div class="activity-icon bg-danger text-white">'+
                  '<i class="fas fa-exclamation"></i>'+
                '</div>'+
                '<div class="activity-detail" style="width: 100%!important;">'+
                  '<div class="">'+
                    '<span class="text-job text-primary">No Targets Answered</span>'+'</div>'+
                    '<div class="row" >'+
                    '<div class="col-md-2">Timestamp</div>'+
                    '<div class="col-md-8 pl-4 ml-2">'+routingtime+'</div>'+
                    '</div>'+
                '</div>'+
              '</div>'
              }


              // $(".appendrow").append(payoutcall);return
              // appendrow
              // console.log(payoutcall);
              // this.table.rowDetail.toggleExpandRow(payoutcall); return false
              // $(".appendrow"+i).after(
                // console.log(res.data[0]);

              var isshowpublisher ='';
              var isshowpublisherid ='';
              if(this.currentUser.role!=4){
                isshowpublisher = `
                <tr style="background:#1F263D!important">
                <td>Publisher</td>
                <td>${res.data[0].publisher}</td>
                </tr>`;
                isshowpublisherid = `
                <tr style="background:#1F263D!important">
                <td>Publisher</td>
                <td>${res.data[0].publisher_id}</td>
                </tr>`;
              }

              var isshowbuyer ='';
              var isshowbuyerid ='';
              if(this.currentUser.role!=3){
                isshowbuyer = `
                <tr style="background:#1F263D!important">
                <td>Buyer</td>
                <td>${res.tagetdialed[0].buyer}</td>
                </tr>`;
                isshowbuyerid = `
                <tr style="background:#1F263D!important">
                <td>Buyer ID</td>
                <td>${res.tagetdialed[0].buyerid}</td>
                </tr>`;
              }

              var isshowpublishertag ='';
              if(this.currentUser.role!=4){
                isshowpublishertag = '<span class="text-job text-primary">Publisher</span>'+
                '<table class="table table-striped targetreferencetable"><tbody>'+
                // '<tr style="background: #252d48!important;"><th>Name</th><th>Values</th></tr>'+
                '<tr style="background:#242e4e!important">'+
                '<td>Name</td>'+
                ' <td>'+res.data[0].publisher+'</td>'+
                '</tr>'+
                '<tr style="background:#1F263D!important">'+
                '<td>Id</td>'+
                ' <td>'+res.data[0].publisher_id+'</td>'+
                '</tr>'+
                '<tr style="background:#242e4e!important">'+
                '<td>SubId</td>'+
                ' <td>'+res.data[0].publisher_subid+'</td>'+
                '</tr>'+
                '</tbody></table>'
              }

            let endcallsource = '';
            if(res.data[0].is_callerid_blocked==0){
              endcallsource +=   '<div class="activity">'+
              '<div class="activity-icon bg-blue text-white">'+
                '<i class="fas fa-info"></i>'+
              '</div>'+
              '<div class="activity-detail" style="width: 35%!important;">'+
                '<div class="">'+
                  '<span class="text-job text-primary">End Call Source</span>'+'</div>'+
                  '<div class="row" >'+
                    '<div class="col-md-6">Timestamp</div>'+
                    '<div class="col-md-3">'+routingtime+'</div>'+
                  '</div>'+
                  '<div class="row">'+
                    '<div class="col-md-6">Source</div>'+
                    '<div class="col-md-3">'+hangup_by+'</div>'+
                  '</div>'+
              '</div>'+
            '</div>';
          }

                var finalresult = '<div class="appendedtbl_'+i+'" style="width: 100%!important;">'+
                '<ul class="nav nav-tabs expandtabs">'+
                  '<li id="eventtgl" style="" class="active"><a class="active" data-toggle="tab" href="#home_'+i+'">Event</a></li>'+
                  '<li style=""><a  data-toggle="tab" href="#menu_'+i+'">Record</a></li>'+
                  '<li style=""><a data-toggle="tab" href="#tag_'+i+'">Tag</a></li>'+
                '</ul>'+

                '<div class="tab-content" style="width: 50%!important;">'+
                  '<div id="home_'+i+'" class="tab-pane fade in active show">'+
                    '<section class="section" style="width: 0px;">'+
                    '<div class="section-body">'+
                      '<div class="row">'+
                        '<div class="col-12">'+
                          '<div class="activities">'+
                            blacklistaliance+
                            ivrnode+
                            routingplan+
                            ringtree+
                            targetnoansnofound+
                            targetdialed+
                            converteddata+
                            connecteddata+
                            completeddata+
                            payoutcall+
                            recordingdata+
                            adjust_payout+
                            endcallsource +
                          '</span>'+
                        '</div>'+
                      '</div>'+
                    '</div>'+
                  '</section> '+
                  '</div>'+

                  '<div id="menu_'+i+'" class="tab-pane fade recorded ml-0 p-0 pt-2">'+
                  '<table class="table  table-striped targetreferencetable"><tbody>'+
                  '<tr style="background: #252d48!important;"><th>Name</th><th>Values</th></tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Campaign</td>'+
                  ' <td>'+res.data[0].campaign+'</td>'+
                  '</tr>'+
                  isshowpublisher +
                  '<tr style="background:#1F263D!important">'+
                  '<td>Target</td>'+
                  ' <td>'+res.data[0].target_name+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Target Number</td>'+
                  ' <td>'+res.data[0].routed_to+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  isshowbuyer+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Campaign ID</td>'+
                  ' <td>'+res.data[0].campaign_id+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  isshowpublisherid+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Target ID</td>'+
                  ' <td>'+res.data[0].target_id+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  isshowbuyerid+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Call Date</td>'+
                  ' <td>'+time+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Caller ID</td>'+
                  ' <td>'+res.data[0].uuid+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Dialed #</td>'+
                  ' <td>'+res.data[0].did+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Time To Call</td>'+
                  ' <td>'+res.data[0].duration+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Call Complete Timestamp</td>'+
                  ' <td>'+endtime+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Call Connected Timestamp</td>'+
                  ' <td>'+time+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Duration</td>'+
                  ' <td>'+res.data[0].duration+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Connected Call Length</td>'+
                  ' <td>'+res.data[0].billsec+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Hangup</td>'+
                  ' <td>'+res.data[0].billsec+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Connected</td>'+
                  ' <td>'+res.data[0].connected+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Paid Out</td>'+
                  ' <td>'+res.data[0].paidout+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Revenue</td>'+
                  ' <td>'+res.data[0].revenue+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Payout</td>'+
                  ' <td>'+res.tagetdialed[0].payout+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Recording</td>'+
                  ' <td>'+recording+'</td>'+
                  '</tr>'+

                  '</tbody></table>'+
                  '</div>'+

                  '<div id="tag_'+i+'" class="tab-pane fade">'+
                  '<span class="text-job text-primary">Time</span>'+
                  '<table class="table table-striped targetreferencetable"><tbody>'+
                  // '<tr style="background: #252d48!important;"><th>Name</th><th>Values</th></tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>UTC Hour</td>'+
                  ' <td>'+res.tags[0].UTCHour+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>UTC Minute</td>'+
                  ' <td>'+res.tags[0].UTCMinute+'</td>'+
                  '</tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>UTC Second</td>'+
                  ' <td>'+res.tags[0].UTCSecond+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>UTC Offset</td>'+
                  ' <td>'+res.tags[0].UTCOffset+'</td>'+
                  '</tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>Hour</td>'+
                  ' <td>'+res.tags[0].Hour+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Minute</td>'+
                  ' <td>'+res.tags[0].Minute+'</td>'+
                  '</tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>Second</td>'+
                  ' <td>'+res.tags[0].Second+'</td>'+
                  '</tr>'+
                  '</tbody></table>'+


                  '<span class="text-job text-primary">Date</span>'+
                  '<table class="table table-striped targetreferencetable"><tbody>'+
                  // '<tr style="background: #252d48!important;"><th>Name</th><th>Values</th></tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>UTC Hour</td>'+
                  ' <td>'+res.tags[0].UTCWeekDay+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>UTC Day</td>'+
                  ' <td>'+res.tags[0].UTCDay+'</td>'+
                  '</tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>UTC Month</td>'+
                  ' <td>'+res.tags[0].UTCMonth+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>UTC Year</td>'+
                  ' <td>'+res.tags[0].UTCYear+'</td>'+
                  '</tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>Week Day</td>'+
                  ' <td>'+res.tags[0].WeekDay+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Day</td>'+
                  ' <td>'+res.tags[0].Day+'</td>'+
                  '</tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>Month</td>'+
                  ' <td>'+res.tags[0].Month+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Year</td>'+
                  ' <td>'+res.tags[0].Year+'</td>'+
                  '</tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>Time Zone</td>'+
                  ' <td>'+res.tags[0].TimeZone+'</td>'+
                  '</tr>'+
                  '</tbody></table>'+

                  '<span class="text-job text-primary">Campaign</span>'+
                  '<table class="table table-striped targetreferencetable"><tbody>'+
                  // '<tr style="background: #252d48!important;"><th>Name</th><th>Values</th></tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>Name</td>'+
                  ' <td>'+res.data[0].campaign+'</td>'+
                  '</tr>'+
                  '<tr style="background:#1F263D!important">'+
                  '<td>Id</td>'+
                  ' <td>'+res.data[0].campaign_id+'</td>'+
                  '</tr>'+
                  '</tbody></table>'+

                  isshowpublishertag +

                  '<span class="text-job text-primary">Dialed Number</span>'+
                  '<table class="table table-striped targetreferencetable"><tbody>'+
                  // '<tr style="background: #252d48!important;"><th>Name</th><th>Values</th></tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>Number</td>'+
                  ' <td>'+res.data[0].did+'</td>'+
                  '</tr>'+
                  '</tbody></table>'+

                  '<span class="text-job text-primary">Call Length</span>'+
                  '<table class="table table-striped targetreferencetable"><tbody>'+
                  // '<tr style="background: #252d48!important;"><th>Name</th><th>Values</th></tr>'+
                  '<tr style="background:#242e4e!important">'+
                  '<td>Total</td>'+
                  ' <td>'+res.data[0].billsec+'</td>'+
                  '</tr>'+
                  '</tbody></table>'+

                '</div>'+
              '</div>';
                // );
                $('.appendData').append(finalresult);
                // tr.addClass('shown');
              },
              error => this.error = error
          );
            // }

  }

   noofrecords:any = 500;
   exportheader:any = [];
   exportdata(){

    if(this.currentUser.role==3){
        this.exportheader = ["Number Called", "Caller ID","Campaign","Call Duration","Date of Call","Time of Call","Payout"]
      }else if(this.currentUser.role==4){
        this.exportheader = ["Number Called", "Caller ID","Target", "Call Duration","Date of Call","Time of Call","Revenue"]
    }else{
        this.exportheader = ["UUID","Number Called", "Caller ID"," Publisher","Target","Campaign","Buyer","Routed To","Call Duration","IVR Inputs","Date of Call","Time of Call","Revenue","Payout","Recording URL"]
    }

    var that = this;
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      // showTitle: true,
      // title: 'Your title',
      useBom: true,
      // noDownload: true,
      headers: this.exportheader
    };


    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const formData = new FormData();
    formData.append('role', currentUser.role);
    formData.append('user_uuid', currentUser.uuid);
    formData.append('timezone', this.currentUser.timezone);
    formData.append('filterselected', JSON.stringify(this.filterselected));
    formData.append('date',JSON.stringify(this.selecteddate));
    formData.append('totalrecords', '5000');
    // console.log(formData);return;
    this.showLoadingIndicator =true;
    this.moduleservice.getCalldetailsForExport(this.currentCompany.uuid,formData).subscribe(
      res => {
        let finalarr = [];
        if(this.currentUser.role==3){
          let newarr = res.data.map(element => {
            // delete element.campaign;
            // delete element.duplicate;
            delete element.publisher;
            delete element.target;
            delete element.routed_to;
            delete element.ivr_inputs;
            delete element.ivr_duration;
            delete element.revenue;
            delete element.recording;
            delete element.assosciated_labels;
              return element;
          });
          finalarr = newarr;
        }else if(this.currentUser.role==4){
          let newarr = res.data.map(element => {
            delete element.campaign;
            delete element.duplicate;
            delete element.publisher;
            delete element.routed_to;
            delete element.ivr_inputs;
            delete element.ivr_duration;
            delete element.payout;
            delete element.recording;
            delete element.assosciated_labels;
              return element;
          });
          finalarr = newarr;
      }else{
        let newarr = res.data.map(element => {
          delete element.assosciated_labels;
            return element;
        });
        finalarr = newarr;

      }

        // console.log(res.data);return;
        new ngxCsv(finalarr, 'All Calls', options);
        this.showLoadingIndicator =false;
      },
      error => this.error = error
  );
  }

  header:any = [];
  exportcalldata(){
    // let spliced = this.callsdata.splice(1, 1);
  if(this.currentUser.role==3){
      let newarr = this.callsdata.map(element => {
        delete element.profit;
        delete element.reachedtobuyer;
        delete element.revenue;
        delete element.ivr_agent;
        delete element.ivr_inputs;
        delete element.hour;
          return element;
      });
      this.callsdata = newarr;
      this.header = ["UUID","Name(Filter)", "Incoming","Unique Calls","Connected","Qualified","Converted","Completed","No Answer","No Connect","Duplicate","Blocked","Payout","CR%","RPC","ACL","TCL"]
    }else if(this.currentUser.role==4){
      let newarr = this.callsdata.map(element => {
        delete element.profit;
        delete element.reachedtobuyer;
        delete element.payout;
        delete element.ivr_agent;
        delete element.ivr_inputs;
        delete element.hour;
          return element;
      });
      this.callsdata = newarr;
      this.header = ["UUID","Name(Filter)", "Incoming","Unique Calls","Connected","Qualified","Converted","Completed","No Answer","No Connect","Duplicate","Blocked","Revenue","CR%","RPC","ACL","TCL"]
  }else{
    let newarr = this.callsdata.map(element => {
      delete element.hour;
        return element;
    });
    this.callsdata = newarr;
      this.header = ["UUID","Name(Filter)", "Incoming","IVR Inputs","IVR Agent","Reached To Buyer","Unique Calls","Connected","Qualified","Converted","Completed","No Answer","No Connect","Duplicate","Blocked","Payout","Revenue","Profit","CR%","RPC","ACL","TCL"]
  }
    // console.log(this.callsdata);
    // console.log(spliced);
    // return

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers:this.header
    };
    new   ngxCsv(this.callsdata, 'All Calls', options);
  }

  isclick:number = 1;
  istougle:number = 1;

  togglepanel(){
    if(this.isclick==1){
      this.istougle = 1;
      this.isclick = 0;
    }else{
      this.isclick = 1;
      this.istougle = 0;
    }
  }

  filter:number;
  filtertext:string;
  filterlabel:string;
  // activeresultdata:any;
  resultdata:any;
  nonactiveresultdata:any;
  filtertype:any = 1;
  filterdata(type:number){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.filter = 0;
    this.isclick = 0;
    this.isselectElement = 0;
    if(type == 1){
      this.resultdata=[];
      this.nonactiveresultdata=[];
      this.filter = 1;
      this.filtertype = 1;
      this.filtertext = 'Select Campaign';
      this.filterlabel = 'Campaign';
      this.filtervalue="";
      this.filteradvanced=0;
      this.ngxLoader.startLoader('loader-04');
      this.moduleservice.getallcampain(this.currentCompany.uuid).subscribe(
        res => {
          this.resultdata = res.active;
          this.dropdownbkp = res.active;
          this.nonactiveresultdata = res.non_active;
          this.ngxLoader.stopLoader('loader-04');
        });
    }
    if(type == 2){
     this.resultdata=[];
      this.filtertype = 2;
      this.filter = 1;
      this.filtertext = 'Select Target';
      this.filterlabel = 'Target';
      this.filtervalue="";
      this.filteradvanced=0;
      this.ngxLoader.startLoader('loader-04');
      let buyer_uuids=[]
      if(this.currentUser.role==4){
        buyer_uuids = this.currentUser?.companydetails.map((item)=>item.user_uuid);      
      }
      let finalbuyer_uuids =JSON.stringify(buyer_uuids);
      this.moduleservice.getAlltargetbybuyer(this.currentCompany.uuid,this.currentUser.role,this.currentUser.uuid,finalbuyer_uuids).subscribe(
        res => {
          this.resultdata = res;
          this.dropdownbkp = res;
          this.ngxLoader.stopLoader('loader-04');
        });
    }
    if(type == 3){
      this.resultdata=[];
      this.filter = 1;
      this.filtertype = 3;
      this.filtertext = 'Select Buyer';
      this.filterlabel = 'Buyer';
      this.filtervalue="";
      this.ngxLoader.startLoader('loader-04');
      this.moduleservice.getallbuyers(this.currentCompany.uuid).subscribe(
        res => {
          this.resultdata = res;
          this.dropdownbkp = res;
          this.ngxLoader.stopLoader('loader-04');
        });
    }
    if(type == 4){
      this.resultdata=[];
      this.filter = 1;
      this.filtertype = 4;
      this.filtertext = 'Select Publisher';
      this.filterlabel = 'Publisher';
      this.filtervalue="";
      this.ngxLoader.startLoader('loader-04');
      this.moduleservice.getAllpublishers(this.currentCompany.uuid).subscribe(
        res => {
          this.resultdata = res;
          this.dropdownbkp = res;
          this.ngxLoader.stopLoader('loader-04');
        });
    }

    if(type == 5){
      this.resultdata=[];
      this.dropdownbkp=[];
      this.filter = 1;
      this.filtertype = 5;
      this.filtertext = 'Enter Caller ID';
      this.filterlabel = 'Caller ID';
      this.filtervalue="";
    }

    if(type == 6){
      this.resultdata=[];
      this.dropdownbkp=[];
      this.filter = 1;
      this.filtertype = 6;
      this.filtertext = 'Enter Number Called';
      this.filterlabel = 'Number Called';
      this.filtervalue="";
    }

    if(type == 8){
      this.resultdata=[];
      this.filter = 1;
      this.filtertype = 8;
      this.filtertext = 'Select IVR Inputs';
      this.filterlabel = 'IVR Inputs';
      this.filtervalue="";
      this.resultdata = [{
        'id':0,
        'text':'No',
        'itemName':'No',
      },{
        'id':1,
        'text':'Yes',
        'itemName':'Yes',
      }];
    }
    this.dropdownbkp = this.resultdata;
    if(type == 9){
      this.resultdata=[];
      this.filter = 1;
      this.filtertype = 9;
      this.filtertext = 'Select Ringtree';
      this.filterlabel = 'Ringtree';
      this.filtervalue="";
      this.resultdata = [{
        'id':0,
        'text':'No',
        'itemName':'No',
      },{
        'id':1,
        'text':'Yes',
        'itemName':'Yes',
      }];
    }

    if(type == 10){
      this.filteradvanced=6;
      this.resultdata=[];
      this.filter = 1;
      this.filtertype = 10;
      this.filtertext = 'Select Duration';
      this.containtext = 'Greater Than';
      this.filterlabel = 'Duration';
      this.filtervalue="";
    }

    this.dropdownbkp = this.resultdata;

  }

  clearfilter(index:any){

    // this.isselectElement = 0;
    this.filterselected.splice(index,1);
    if(this.filterselected.length===0){
      this.isclick=1;
    }
    this.loadcalls(this.filterselected,this.selecteddate);
    this.loadcallsdata(this.filterselected,this.selecteddate,1);
    this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval);
  }
  containtext:any="Equals Single Value";
  getadvancedfilter(e:any, type:any){
    // console.log(e.target.options[e.target.selectedIndex].text);
    this.containtext = e.target.options[e.target.selectedIndex].text;
    // this.filteradvanced = e.target.value;
  }
  dropdownbkp:any=[];
  getcontainfilter(e:any, type:any){
    let filterdata = [];
    if(e.target.value==1){
      this.dropdownbkp.forEach(element => {
        let obj = {
          'id' :element.id,
          'itemName' :element.id,
          'text' :element.id,
        }
        filterdata.push(obj);
      });
    }else{
      this.dropdownbkp.forEach(element => {
        let obj = {
          'id' :element.id,
          'itemName' :element.itemName,
          'text' :element.text,
        }
        filterdata.push(obj);
      });
    }
    // if(this.filtertype==1){
    //   this.activeresultdata = filterdata;
    // }else{
      this.resultdata = filterdata;
    // }
    // this.filteradvanced = e.target.value;
  }

  containfiltertype: any = 0;
  filteradvanced: any = 0;
  filtervalue: any = '';
  filterdate: any = '';
  startdate: any;
  enddate: any;
  diff: any;
  days: any;
  selected1: any;
  selecteddate: any;
  isadded: any=1;
  isfiltered: any=0;
  isshowbyhrs: any=1;
  filterval(event:any,group_by:Number){
    // console.log(this.filtervalue[0]['itemName']); return false;
    // console.log(this.filteradvanced); return
    this.refresh_date = new Date();
    let itemuuid;
    let itemname;
    if((this.filteradvanced==0 || this.filteradvanced==1) && this.filtertype!=5 && this.filtertype!=6){
      if(this.filtervalue!=undefined && this.filtervalue!=''){
        itemuuid = this.filtervalue[0]['id'];
        itemname = this.filtervalue[0]['itemName']
      }else{
        itemuuid = ""
        itemname = ""
      }
    }else{
      itemname = this.filtervalue
      itemuuid = ""
    }
    // Invalid filter LESS_THAN for column isDuplicate
    // if(this.filteradvanced==6 || this.filteradvanced==7){
    //   Swal.fire(
    //     'Error!',
    //     'Invalid filter Command.',
    //   );
    //   return
    // }
    if(itemname!=undefined && itemname!=''){
      let customObj = new filterarr();
      customObj.filteradvanced = this.filteradvanced;
      customObj.filtertype = this.filtertype;
      customObj.filterlabel = this.filterlabel;
      customObj.containfiltertype = this.containfiltertype;
      customObj.containfiltertext = this.containtext;
      customObj.uuid = itemuuid;
      customObj.name = itemname;
      // customObj.name = [0]['itemName']this.selectElementText;
      customObj.isorand = 0;
      // console.log(customObj); return

      if(itemname!=undefined && this.filterselected?.length==0 && !event){
        this.filterselected.push(customObj);
      }
      if(itemname!=undefined && this.filterselected?.length==1 && !event){
        this.filterselected=[];
        this.filterselected.push(customObj);
      }
    }
    // console.log(this.filterselected);
    if(event && event.startDate==null && event.endDate==null)
    return false;
    if(event){
    var start = moment(event.startDate._d).format('MM/DD/YYYY HH:mm:ss');
    var end = moment(event.endDate._d).format('MM/DD/YYYY HH:mm:ss');
    // console.log(this.startdate);
    // console.log(this.enddate);return false;
    this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD HH:mm:ss")
    this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD HH:mm:ss")

    // this.diff = this.enddate.diff(this.startdate, 'days');
    // console.log(this.startdate);

      this.selected1 = {
        startDate: this.startdate,
        endDate: this.enddate,
      };
      this.selecteddate = this.selected1;
      var a = moment(start);
      var b = moment(end);
      if(b.diff(a, 'days')>0){
        this.daytypeval = 2;
        this.isshowbyhrs = 0;
      }else{
        this.daytypeval = 1;
        this.isshowbyhrs = 1;
      }
      this.isfiltered = 1;
    }else{
      this.isselectElement = 1;
      this.isadded = 0;
      const uuid = this.route.snapshot.paramMap.get('id');
      if(uuid!=null){

        var start = moment(this.selected.startDate).format('MM/DD/YYYY HH:mm:ss');
        var end = moment(this.selected.endDate).format('MM/DD/YYYY HH:mm:ss');
      }else{
        // console.log(this.selected1.startDate._d);
        if(this.selected1.startDate._d!=undefined){
          var start = moment(this.selected1.startDate._d).format('MM/DD/YYYY HH:mm:ss');
          var end = moment(this.selected1.endDate._d).format('MM/DD/YYYY HH:mm:ss');
        }else{
          // console.log(this.selected2);

          var start = moment(this.selected2.startDate).format('MM/DD/YYYY HH:mm:ss');
          var end = moment(this.selected2.endDate).format('MM/DD/YYYY HH:mm:ss');

        }
      }
      // console.log(this.selected1);return
      // console.log(this.startdate);return false;
      this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD HH:mm:ss")
      this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD HH:mm:ss")
      if(this.isfiltered==0){
        this.selected1 = {
          startDate: this.startdate,
          endDate: this.enddate,
        };
        this.selecteddate = "";
      }else{
        this.selecteddate = this.selected1;
      }

      // console.log(this.selecteddate);
      this.daytypeval = 2;
    }
    // console.log(this.selecteddate);
    // return false
    // this.diff = this.startdate.getTime() - this.enddate.getTime();
    //getTime() function used to convert a date into milliseconds. This is needed in order to perform calculations.

  //  this.days = Math.round(Math.abs(this.diff/(1000*60*60*24)))

    // console.log('ok');
    // console.log(this.filterselected);
    // console.log(this.selecteddate);
    // console.log(this.daytypeval);
    // return;
    // console.log(group_by);
    // console.log(group_by);      
    if(group_by == undefined){
      group_by = this.filtertype;
      // console.log(this.filtertype);      
    }
    // this.filtertype = 1;
    // $(".tabsbtn").removeClass("btn btn-primary btn-sm activetab");
    // $("#tabs1").addClass("activetab");
    let role =this.currentUser.role;
    if(this.userselectedvalue && this.userselectedvalue[0].role){
      role = this.userselectedvalue[0].role;
    }

    this.loadcalls(this.filterselected,this.selecteddate,role);
    this.loadcallsdata(this.filterselected,this.selecteddate,group_by,role);
    this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval,role);

  }


  reload(){
    this.refresh_date = new Date();
    this.loadcalls(this.filterselected,this.selecteddate);
    this.loadcallsdata(this.filterselected,this.selecteddate,1);
    this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval);
  }
  calcelfilter(){


    // this.filtertype = 0;
    this.isselectElement = 0;
    this.filtertype = 1;
    this.filterdata(1);
    this.filtervalue = '';
    this.filteradvanced = 0;
    this.filterselected=[];
    this.containtext="Equals Single Value";
    // this.loadcalls("","");
    // this.loadcallsdata("","",1);
    // this.getChartNumberOfCalls(0,0,1);
    // if(this.isadded==0){
      this.loadcalls("",this.selecteddate);
      this.loadcallsdata("",this.selecteddate,1);
      this.getChartNumberOfCalls("",this.selecteddate,this.daytypeval);
    // }
    this.isadded = 1;
    this.isclick = 1;
    this.filter = 0;
  }

  isselectElement:any=0;
  selectElementText:any;
  filterselected: Array<filterarr> = [];


  gettext(event:any,type){
    let text;
    // if(this.filtertype==1){
    //   text =  this.activeresultdata.filter(x => x.id == event)[0];
    // }else{
      text =  this.resultdata.filter(x => x.id == event)[0];
    // }


    if(type==1){
    //  this.selectElementText = event.target['options'][event.target['options'].selectedIndex].text;
     this.selectElementText = text.text;
    }else{
     this.selectElementText = event.target.value;
    }
    //  this.selectElementText = event.target.value
    // console.log(event);
  }
  addorrule(){
    // if(this.filteradvanced==6 || this.filteradvanced==7){
    //   Swal.fire(
    //     'Error!',
    //     'Invalid filter Command.',
    //   );
    //   return
    // }
    let customObj = new filterarr();
    customObj.filteradvanced = this.filteradvanced;
    customObj.filterlabel = this.filterlabel;
    customObj.filtertype = this.filtertype;
    customObj.containfiltertext = this.containtext;
    customObj.containfiltertype = this.containfiltertype;
    // customObj.uuid = this.filtervalue;
    // customObj.name = this.selectElementText;
    let itemname;
    let itemuuid;
    if((this.filteradvanced==0 || this.filteradvanced==1) && this.filtertype!=5 && this.filtertype!=6){
      if(this.filtervalue!=undefined && this.filtervalue!=''){
        itemuuid = this.filtervalue[0]['id'];
        itemname = this.filtervalue[0]['itemName']
      }else{
        itemname = "";
        itemuuid = "";
      }
    }else{
      itemname = this.filtervalue;
      itemuuid = "";
    }
    // console.log(itemname);


    customObj.uuid = itemuuid;
    customObj.name = itemname;
    customObj.isorand = 0;
    if(itemname!=undefined){
      this.filterselected.push(customObj);
    }
    // console.log(this.filterselected);
  }

  addandrule(){
    // if(this.filteradvanced==6 || this.filteradvanced==7){
    //   Swal.fire(
    //     'Error!',
    //     'Invalid filter Command.',
    //   );
    //   return
    // }
    let itemname;
    let itemuuid;
    if((this.filteradvanced==0 || this.filteradvanced==1) && this.filtertype!=5 && this.filtertype!=6){
      if(this.filtervalue!=undefined && this.filtervalue!=''){
        itemname = this.filtervalue[0]['itemName']
        itemuuid = this.filtervalue[0]['id'];
      }else{
        itemname = ""
        itemuuid = ""
      }
    }else{
      itemname = this.filtervalue
      itemuuid = ""
    }

    let customObj = new filterarr();
    customObj.filtertype = this.filtertype;
    customObj.filterlabel = this.filterlabel;
    customObj.filteradvanced = this.filteradvanced;
    customObj.containfiltertext = this.containtext;
    customObj.containfiltertype = this.containfiltertype;
    // customObj.uuid = this.filtervalue;
    // customObj.name = this.selectElementText;
    customObj.uuid = itemuuid;
    customObj.name = itemname;
    customObj.isorand = 1;
    // console.log(this.selectElementText);
    if(itemname!=undefined){
      this.filterselected.push(customObj);
    }
  }

  editfilter(){
    this.isadded=1;
    this.isselectElement=0;

    if(this.filtertype==10){
      this.containtext = 'Greater Than';
      this.filteradvanced=6;
      this.filterlabel = 'Duration';
    }else{
      this.containtext = 'Equals Single Value';
      this.filteradvanced=0;
      this.filterlabel = 'Campaign';
    }
  }

  charttype:string = "";
  daytypeval:number = 1;
  chartby(type:number){
    this.filterdate="";
    if(type==1){
      this.daytypeval = 1;
      this.charttype = "";
      this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval);
    }
    if(type==2){
      this.daytypeval = 2;
      this.charttype = "";
      this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval);
    }
    if(type==3){
      this.daytypeval = 3;
      this.charttype = "";
      this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval);
    }
  }

  savedreportsettings:any;
  selecteddates:any;
  isreport:any = 0;
  getsavedreports(uuid:string){
    this.moduleservice.getsavedreportsbyuuid(uuid).subscribe(
      res => {
        console.log(res);
        
        this.labelval = res.reportlabelname;
        this.setTitle(this.labelval+' | Report');

        this.daytypeval = 2;
        if(res.filtertype!=''){
          this.isreport = 1;
          if(res.filtertype!=0){ this.isclick = 1; }
          this.filter = res.filtertype;
          let startday = res.start_date;
          let endday = res.end_date;
          const d = new Date(startday);
          const d1 = new Date(endday);
          moment(d).format('MMMM d, YYYY');
          moment(d1).format('MMMM d, YYYY');
          var start = moment(d).format('MM/DD/YYYY HH:mm:ss');
          var end = moment(d1).format('MM/DD/YYYY HH:mm:ss');

          this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
          this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
          // console.log(this.filter);
          // console.log(this.filtervalue);
          // console.log(this.selected);

          this.selected = {startDate: d, endDate: d1};
          this.selecteddates = {startDate: this.startdate, endDate: this.enddate};
          this.filterselected = JSON.parse(res.filterarr);
          var a = moment(d);
          var b = moment(d1);
          if(b.diff(a, 'days')>0){
              this.daytypeval = 2;
              this.charttype = "Calls Per Day";
          }else{
              this.daytypeval = 1;
              this.charttype = "Calls Per Hour";
          }

        }
        if(res.filterarr==""){
          this.filterdata(this.filter);
        }
        if(res.filtervalue!=''){
          this.isclick = 1;
          this.filtervalue = res.filtervalue;
        }
        if(res.filteradvanced!=''){
          this.isclick = 1;
          this.filteradvanced = res.filteradvanced;
        }

        this.selectednewdates = this.selecteddates;

        if(res.filterarr!=""){
          this.isclick = 0;
          this.filter = 1;
          this.filterselected = JSON.parse(res.filterarr);
          if(this.filterselected.length>0 && this.filterselected.length==1){
            this.filterdata(this.filterselected[0].filtertype);
            // this.filteradvanced = this.filterselected[0].filteradvanced;
            // this.filtervalue = this.filterselected[0].uuid;
          }else if(this.filterselected.length>0){
            // this.filterdata(this.filter);
            this.filterdata(this.filterselected[this.filterselected.length-1].filtertype);
          }
          // this.filtertype= 1;
          console.log(this.filterselected);
           

        }
        // localStorage.setItem('selectedDates',JSON.stringify(this.selecteddates));
        // console.log(localStorage.getItem('selectedDates'));return
        // console.log(this.selectednewdates);
        this.loadcalls(this.filterselected,this.selecteddates);
        this.loadcallsdata(this.filterselected,this.selecteddates,1);
        this.getChartNumberOfCalls(this.filterselected,this.selecteddates,this.daytypeval);

      });
  }

  islabelvaltext:any;
  savelabel(){
    // console.log(this.labelval);
    // console.log(this.selected.startDate._d);
    // console.log(this.filtertype);
    // console.log(this.filtervalue);
    const uuid = this.route.snapshot.paramMap.get('id');
    // console.log(this.selected1);
    // return false;

    if(this.labelval==undefined){
      this.islabelvaltext='yes';
      Swal.fire(
        'Error!',
        'Please enter report name',
        'error',
      )
      return;
    }
    this.islabelvaltext='';
    const formData = new FormData();
    if(uuid!=null){
      formData.append('uuid', uuid);
    }
    if(!this.selecteddate){
      this.selecteddate =this.selected1;
    }
    var start = moment(this.selecteddate.startDate).format('MM/DD/YYYY HH:mm:ss');
    var end = moment(this.selecteddate.endDate).format('MM/DD/YYYY HH:mm:ss');

    this.startdate = moment.tz(start, 'utc').format("YYYY-MM-DD 00:00:00")
    this.enddate = moment.tz(end, 'utc').format("YYYY-MM-DD 23:59:59")
    // console.log(this.filtertype);
    // console.log(this.startdate);
    // console.log(this.enddate);
    // return false;
    if(this.filtervalue==""){
      this.filtertype = 0;
    }
    if(this.filteradvanced==""){
      this.filteradvanced = 0;
    }
    // console.log(this.filterselected);return false;
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('reportlabelname', this.labelval);
    formData.append('start_date', this.startdate);
    formData.append('end_date', this.enddate);
    formData.append('filtertype', this.filtertype);
    formData.append('filtervalue', this.filtervalue);
    formData.append('filteradvanced', this.filteradvanced);
    formData.append('filterarr', JSON.stringify(this.filterselected));
    this.moduleservice.savereport(formData).subscribe(
      res => {
        if (res.status !== 'error') {
          if(res.isavail!=1){
            this.labelval="";
          }
          this.sendMenuUpdateMessage();
          this.toastrService.success('Report Saved Successfully');
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      });
  }

  deletelabel(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Selected Report Name!',

      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const uuid = this.route.snapshot.paramMap.get('id');
        this.moduleservice.deleteSelectedSavedReport(uuid).subscribe(
          res => {
              Swal.fire(
                'Deleted!',
                'Records Deleted Successfully.',
              )
              this.sendMenuUpdateMessage();
              setTimeout(() => {
                Swal.close()
                this.router.navigate(['/reports']);
              }, 2000);

            },
            error => this.error = error
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }

  // groupby:any=1;
  getSummary(type){
    // console.log(type);
    // this.filterselected=[];
    // console.log(this.selecteddate); return
    this.tagselectedvalue = "";
    if(this.selecteddate == undefined){
      this.selecteddate = this.selected1;
    }
    // this.selecteddate = this.selected1;
    $(".tabsbtn").removeClass("btn btn-primary btn-sm activetab");
    if(type==1){
       $("#tabs1").addClass("activetab");this.filtertype=1;
       this.loadcallsdata(this.filterselected,this.selecteddate,1);
    }
    if(type==2){
       $("#tabs2").addClass("activetab");this.filtertype=4;
       this.loadcallsdata(this.filterselected,this.selecteddate,4);
    }
    if(type==3){
       $("#tabs3").addClass("activetab");this.filtertype=2;
       this.loadcallsdata(this.filterselected,this.selecteddate,2);
    }
    if(type==4){
       $("#tabs4").addClass("activetab");this.filtertype=3;
       this.loadcallsdata(this.filterselected,this.selecteddate,3);
    }
    if(type==5){
       $("#tabs5").addClass("activetab");this.filtertype=5;
       this.loadcallsdata(this.filterselected,this.selecteddate,5);
    }
    if(type==7){
       $("#tabs7").addClass("activetab");this.filtertype=7;
       this.loadcallsdata(this.filterselected,this.selecteddate,7);
    }
    if(type==8){
       $("#tabs8").addClass("activetab");this.filtertype=8;
       this.loadcallsdata(this.filterselected,this.selecteddate,8);
    }
    // if(type==5){ $("#tabs5").addClass("activetab");this.filtertype=5;}
    // if(type==6){ $("#tabs6").addClass("activetab");this.filtertype=6;}
    // if(type==7){ $("#tabs7").addClass("activetab");this.filtertype=7;}
    // if(type==8){ $("#tabs8").addClass("activetab");this.filtertype=8;}
    // if(type==8){ $("#tabs8").addClass("activetab");this.filtertype=8;}
  }

  islive:boolean=false;
  getcallDetails(type){
    // alert(type);
    this.filterselected=[];
    $(".tabsbtn").removeClass("btn btn-primary btn-sm activetab");
    if(type==1){
       this.islive = false;
       $("#calltabs1").addClass("activetab");this.filtertype=1;
       this.loadcalls(this.filterselected,this.selecteddate);
    }
    if(type==2){
       this.islive = true;
       $("#calltabs2").addClass("activetab");this.filtertype=4;
      //  this.loadlivecalls();
    }
  }


  scrolltoy(){
    $('datatable-body').scrollLeft(1000);
  // $('datatable-body').scrollLeft += 800;
    // this.bodyComponent.updateOffsetY(50);
    // document.getElementByClass('datatable-body').scrollLeft += 800;
  }
  scrolltox(){
    $('datatable-body').scrollLeft(0);
    // document.getElementById('datatable-body.datatable-body').scrollLeft -= 800;
  }


  filterbyvalue(uuid,value,type = 0){
    // console.log(this.filtertype);
    // console.log(value); return false;
    // this.togglepanel();
    this.istougle = 1;
    this.isclick = 1;
    // if(type == 0 || type == 1){
      this.filterdata(this.filtertype);
      let customObj = new filterarr();
      customObj.filtertype = this.filtertype;
      customObj.filterlabel = this.filterlabel;
      customObj.containfiltertext = this.containtext;
      customObj.containfiltertype = this.containfiltertype;
      customObj.uuid = uuid;
      customObj.name = value;
      customObj.isorand = 1;
      customObj.isdup = type;
      // console.log(this.selectElementText);
      if(value!=""){
        this.filterselected.push(customObj);
      }
    // }
    // console.log(this.filterselected);
    this.filterval('',this.filtertype);
  }


  ngOnDestroy(){
    this.dtTrigger.unsubscribe();
    // this.subscription.unsubscribe();
    console.log('Report method destroyed');
  }


  copyName(val: string){
    console.log(val);

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  filtercalldata(filtertype,uuid:any, no:any){


    this.istougle = 1;
    this.isclick = 1;
    this.isadded=0;

    this.filterdata(filtertype);
    let customObj = new filterarr();
    customObj.filtertype = filtertype;
    customObj.filterlabel = this.filterlabel;
    customObj.filteradvanced = this.filteradvanced;
    customObj.containfiltertext = this.containtext;
    customObj.containfiltertype = this.containfiltertype;
    customObj.uuid = uuid;
    customObj.name = no;
    customObj.isorand = 1;
    // console.log(this.selectElementText);
    if(no!=""){
      this.filterselected.push(customObj);
    }
    // this.filter = 1;
    this.isselectElement = 1;
    // this.daytypeval=2;
    // console.log(this.filterselected);
    // console.log(this.selecteddate);

    this.loadcalls(this.filterselected,this.selecteddate);
    this.loadcallsdata(this.filterselected,this.selecteddate,1);
    this.getChartNumberOfCalls(this.filterselected,this.selecteddate,this.daytypeval);

  }

  blockdurationval:any="";
  selectedcampaign:any;
  selectedcampaign_uuid:any;
  blocked_number:any;
  blockno(campaign:any,campaign_uuid:any,blocked_number:any){
    this.selectedcampaign = campaign;
    this.selectedcampaign_uuid = campaign_uuid;
    this.blocked_number = blocked_number;
  }

  blockdurationvalselected:any="";
  blockselectedno(){
    if(this.selectedrow.length==0){
      Swal.fire(
        'Error!',
        'Please select number',
        'error',
      )
      return;
    }else{
      $('#blockselectedno').modal('show');

    }
  }


  submitblockno(type){
    if((this.blockdurationval=="" && type==0) || (this.blockdurationvalselected=="" && type==1)){
      Swal.fire(
        'Error!',
        'Please enter required value',
        'error',
      )
      return;
    }
    const formData = new FormData();
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('type', type);
    formData.append('block_type', "0");
    if(type==0){
      formData.append('blocked_number', this.blocked_number);
      formData.append('duration', this.blockdurationval);
    }else{
      formData.append('selectedrow', JSON.stringify(this.selectedrow));
      formData.append('duration', this.blockdurationvalselected);
    }

    formData.append('blocked_prefix', "");
    formData.append('scope', '1');
    formData.append('campaign_uuid', this.selectedcampaign_uuid);

    formData.append('is_duration', '1');

    this.ngxLoader.startLoader('loader-04');
     this.moduleservice.addblockedcalls(formData,this.currentCompany.uuid).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          // this.toastrService.w('Number Blocked Successfully');
          Swal.fire(
            'Success!',
            'Number Blocked Successfully',
          )

        }else{
          Swal.fire(
            'Error!',
            'Error occured please try again',
          )
        }
        this.ngxLoader.stopLoader('loader-04');
      },
      error => this.error = error,
    );
  }

  calleruuid:any;
  freeswitch_call_uuid:any;
  adjust_revenue:any="";
  adjust_payout:any="";
  adjust_reason:any="";
  adjustcall(uuid:any,freeswitch_call_uuid:any,payoutdata:any){
    if(payoutdata!=null){
      this.calleruuid = payoutdata.call_uuid;
      this.freeswitch_call_uuid = payoutdata.freeswitch_call_uuid;
      if(payoutdata.revenue!=0){
        this.adjust_revenue = payoutdata.revenue;
      }else{
        this.adjust_revenue = "";
      }
      if(payoutdata.payout!=0){
        this.adjust_payout = payoutdata.payout;
      }else{
        this.adjust_payout = "";
      }
      if(payoutdata.reason!=""){
        this.adjust_reason = payoutdata.reason;
      }else{
        this.adjust_reason="";
      }

    }else{
      this.calleruuid = uuid;
      this.freeswitch_call_uuid = freeswitch_call_uuid;
      this.adjust_reason="";
    }
  }


  adjustcallselectedno(){
    if(this.selectedrow.length==0){
      Swal.fire(
        'Error!',
        'Please select number',
        'error',
      )
      return;
    }else{
      $('#adjustcallselected').modal('show');

    }
  }

  gettags(){
    $(".tabsbtn").removeClass("btn btn-primary btn-sm activetab");
    if(this.tagselectedvalue.length>0){
      // console.log(this.tagselectedvalue[0].name);
      this.tagselectedvalue[0].itemName = 'Tag: '+this.tagselectedvalue[0].name
      // console.log(this.tagselectedvalue);

      $("#tabs9").addClass("activetab");
      this.filtertype=9;
      this.loadcallsdata(this.filterselected,this.selecteddate,9);
    }
    // else{
    //   $("#tabs1").addClass("activetab");this.filtertype=1;
    //   this.loadcallsdata(this.filterselected,this.selecteddate,1);
    // }
  }

  adjustcallsubmit(type){
    if(this.currentUser.role==4){
      if(this.adjust_payout==""){
        Swal.fire(
          'Error!',
          'Please enter required value',
          'error',
        )
        return;
      }
    }else if(this.currentUser.role==3){
        if(this.adjust_revenue==""){
          Swal.fire(
            'Error!',
            'Please enter required value',
            'error',
          )
          return;
        }
    }else{
      if(this.adjust_payout=="" || this.adjust_revenue==""){
        Swal.fire(
          'Error!',
          'Please enter required value',
          'error',
        )
        return;
      }
    }
    // console.log(this.adjust_revenue);

    // return
    const formData = new FormData();
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    formData.append('user_uuid', this.currentUser.uuid);
    formData.append('role', this.currentUser.role);
    formData.append('call_uuid', this.calleruuid);
    formData.append('freeswitch_call_uuid', this.freeswitch_call_uuid);
    formData.append('type', type);
    if(type==1){
      formData.append('selectedrow', JSON.stringify(this.selectedrow));
    }

    if(this.adjust_revenue==""){
      formData.append('is_revenue', "0");
    }else{
      formData.append('is_revenue', "1");
    }
    if(this.adjust_payout==""){
      formData.append('is_payout', "0");
    }else{
      formData.append('is_payout', "1");
    }

    formData.append('revenue', this.adjust_revenue);
    formData.append('payout', this.adjust_payout);
    formData.append('reason', this.adjust_reason);

     this.moduleservice.adjust_call(formData,this.currentCompany.uuid).subscribe(
      res => {
        if (res.status !== 'error') {
          Swal.fire(
            'Success!',
            'Call adjust request successfully done',
          )
          this.loadcalls(this.filterselected,this.selecteddate);
        }else{
          Swal.fire(
            'Error!',
            'Error occured please try again',
          )
        }
      },
      error => this.error = error,
    );
  }

}
