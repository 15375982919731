// import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app-routing.module';
import { RouterModule } from '@angular/router';
import { ModulesRoutingModule } from './modules-routing.module';
import { NumberComponent } from './number/number.component';
import { NumberUploadComponent } from './number/number-upload/number-upload.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NumbereditComponent } from './number/numberedit/numberedit.component';
import { NumberaddComponent } from './number/numberadd/numberadd.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE} from 'ng-pick-datetime';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { LabelComponent } from './label/label.component';
import { NotesComponent } from './notes/notes.component';
import { IvrMenusComponent } from './ivr-menus/ivr-menus.component';
import { AddIVRMenuComponent } from './ivr-menus/add-ivrmenu/add-ivrmenu.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { EditIvrmenuComponent } from './ivr-menus/edit-ivrmenu/edit-ivrmenu.component';
import { RulesComponent } from './rules/rules.component';
import { RulesaddComponent } from './rules/rulesadd/rulesadd.component';
import { RuleseditComponent } from './rules/rulesedit/rulesedit.component';
import { DynamicParameterComponent } from './dynamic-parameter/dynamic-parameter.component';
import { BlacklistComponent } from './blacklist/blacklist.component';
import { WhitelistComponent } from './whitelist/whitelist.component';
import { ReportsComponent } from './reports/reports.component';
import { VerificationManagementComponent } from './verification-management/verification-management.component';
import { AudibleRingComponent } from './audible-ring/audible-ring.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ShortlistComponent } from './shortlist/shortlist.component';
import { LonglistComponent } from './longlist/longlist.component';
import { NumberBatcheditComponent } from './number/number-batchedit/number-batchedit.component';
import { NumberAddfslrulesComponent } from './number/number-addfslrules/number-addfslrules.component';
import { LabelViewComponent } from './label/label-view/label-view.component';
import { ChartModule } from 'angular-highcharts';
import { SmsComponent } from './sms/sms.component';
import { RecordingsComponent } from './recordings/recordings.component';
import { OwlMomentDateTimeModule, MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { ReportCallsComponent } from './reports/report-calls/report-calls.component';
import { ReportLabelsummaryComponent } from './reports/report-labelsummary/report-labelsummary.component';
import { NumberBuyerComponent } from './number/number-buyer/number-buyer.component';
import { PublishersComponent } from './publishers/publishers.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignCreateComponent } from './campaigns/campaign-create/campaign-create.component';
import { NumbersComponent } from './numbers/numbers.component';
import {DataTablesModule} from 'angular-datatables';
import { EditorModule } from "@tinymce/tinymce-angular";
// import { NgxTinymceModule } from 'ngx-tinymce';
export const MY_MOMENT_FORMATS = {
//   fullPickerInput: 'YYYY-MM-DD HH:mm:ss',
// parseInput: 'YYYY-MM-DD HH:mm:ss',
// datePickerInput: 'YYYY-MM-DD HH:mm:ss',
timePickerInput: 'HH:mm',
// monthYearLabel: 'MMM YYYY',
// dateA11yLabel: 'LL',
// monthYearA11yLabel: 'MMMM YYYY'
};
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgSelect2Module } from 'ng-select2';
import { CampaignEditComponent } from './campaigns/campaign-edit/campaign-edit.component';
import { BlockedNumbersComponent } from './numbers/blocked-numbers/blocked-numbers.component';
import { ManageUsersComponent } from './settings/manage-users/manage-users.component';
import { PublisherSettingsComponent } from './publishers/publisher-settings/publisher-settings.component';
import { PublisherTermsComponent } from './publishers/publisher-terms/publisher-terms.component';
import { AdvertiserTermsComponent } from './publishers/advertiser-terms/advertiser-terms.component';
import { BasicSettingComponent } from './settings/basic-setting/basic-setting.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { AlertConfigurationComponent } from './settings/alert-configuration/alert-configuration.component';
import { NumberPoolComponent } from './numbers/number-pool/number-pool.component';
import { ManageCompanyComponent } from './settings/manage-company/manage-company.component';
import { ChangelogComponent } from './changelog/changelog.component';


import { IVRRoutingPlansComponent } from './ivr-menus/ivr-routing-plans/ivr-routing-plans.component';
import { AddPlanComponent } from './ivr-menus/ivr-routing-plans/add-plan/add-plan.component';
import { EditPlanComponent } from './ivr-menus/ivr-routing-plans/edit-plan/edit-plan.component';
import { MailVerificationComponent } from './mail-verification/mail-verification.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxdemoComponent } from './label/ngxdemo/ngxdemo.component';

import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import {TimeAgoPipe} from 'time-ago-pipe';

// import { NgxUiLoaderModule } from "ngx-ui-loader";
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";
import { ReportsComparisonComponent } from './reports-comparison/reports-comparison.component';
import { VoicemailComponent } from './voicemail/voicemail.component';
import { ThankyouComponent } from './publishers/thankyou/thankyou.component';
import { CampaignViewComponent } from './campaigns/campaign-view/campaign-view.component';
import { TasksComponent } from './tasks/tasks.component';
import { PredictiveRoutingComponent } from './settings/predictive-routing/predictive-routing.component';
import { AddRoutingComponent } from './settings/predictive-routing/add-routing/add-routing.component';
import { EditRoutingComponent } from './settings/predictive-routing/edit-routing/edit-routing.component';
import { NumbereditBuyerComponent } from './number/numberedit-buyer/numberedit-buyer.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // bgsColor: '#3858E7',
  bgsPosition: POSITION.centerCenter,
  // bgsSize: 30,
  // bgsType: SPINNER.rectangleBounce, // background spinner type
  // fgsType: SPINNER.chasingDots, // foreground spinner type
  // pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  // pbThickness: 2, // progress bar thickness
};


@NgModule({
  declarations: [TimeAgoPipe,NumberComponent, DashboardComponent, NumbereditComponent, NumberaddComponent, LabelComponent, NotesComponent, IvrMenusComponent, AddIVRMenuComponent, EditIvrmenuComponent, RulesComponent, RulesaddComponent, RuleseditComponent, DynamicParameterComponent, BlacklistComponent, WhitelistComponent, ReportsComponent, VerificationManagementComponent, AudibleRingComponent,NumberUploadComponent, ShortlistComponent, LonglistComponent, NumberBatcheditComponent, NumberAddfslrulesComponent, LabelViewComponent, SmsComponent, RecordingsComponent, ReportCallsComponent, ReportLabelsummaryComponent, NumberBuyerComponent, PublishersComponent, CampaignsComponent, CampaignCreateComponent, NumbersComponent, CampaignEditComponent, BlockedNumbersComponent, ManageUsersComponent,  PublisherSettingsComponent, PublisherTermsComponent, AdvertiserTermsComponent, BasicSettingComponent, AlertConfigurationComponent, NumberPoolComponent, ManageCompanyComponent, ChangelogComponent, IVRRoutingPlansComponent, AddPlanComponent, EditPlanComponent, MailVerificationComponent, NgxdemoComponent, ReportsComparisonComponent, VoicemailComponent, ThankyouComponent, CampaignViewComponent, TasksComponent, PredictiveRoutingComponent, AddRoutingComponent, EditRoutingComponent, NumbereditBuyerComponent ],
  imports: [
    EditorModule,
    CommonModule,
    ModulesRoutingModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,  
    AngularMultiSelectModule,
    ChartModule, 
    MatFormFieldModule,
    NgxAudioPlayerModule,
    NgxDaterangepickerMd.forRoot(),        
    MatStepperModule,
    MatSelectModule,
    FormlyBootstrapModule,
    MatInputModule, 
    MatButtonModule,
    NgSelect2Module,
    NgxDatatableModule,
    NgbTooltipModule,
    DataTablesModule,
    // NgxTinymceModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    FormlyModule.forRoot({
      validationMessages: [
        { name: 'required', message: 'This field is required' },
      ],
    }),
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [    
    BrowserModule,    
    NumberComponent,
    FormsModule
  ],
  providers: [
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}    
    ]
})
export class ModulesModule { }