import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup,FormArray,Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from './../../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
// import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
declare var $;
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-campaign-create',
  templateUrl: './campaign-create.component.html',
  styleUrls: ['./campaign-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignCreateComponent implements OnInit {
  addForm: FormGroup;
  currentCompany: any;
  locations:any = [];
  error: {};
  showLoadingIndicator: any;
  public value: string[];
  public numberdata: Array<Select2OptionData>;
  public exampleData: Array<Select2OptionData>;
  public options: Options;
  // public options: Options;
  dropdownSettingss:IDropdownSettings;
  labeldropdownSettingss:IDropdownSettings;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  labeldropdownSettings = {};

  constructor(
      private renderer: Renderer2,
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
      private router: Router,      
      private changedetect: ChangeDetectorRef,
      private titleService: Title   
  ){}  

  // addJsToElement(src: string): HTMLScriptElement {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src = src;
  //   this.renderer.appendChild(document.body, script);
  //   return script;
  // }


  maxconcurrency:any;
  get_maxcon(event:any){
    this.maxconcurrency = event.target.checked;    
    // this.isselectlabel = false;    
    // $(".labelswitch").prop('checked',false);
  }
  
  isselectlabel:any;
  get_labelisselect(event:any){
    this.isselectlabel = event.target.checked;    
    // this.maxconcurrency = false;    
    // $(".labelnumber").prop('checked',false);
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {
    this.setTitle('Create Campaign')
    this.dropdownSettings = { 
      singleSelection: false, 
      text:"Select Numbers",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };   
 
    this.labeldropdownSettings = { 
      singleSelection: false, 
      text:"Select Labels",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"myclass custom-class"
    };   
 
    var parentElement = $(".asdf");

    $(".js-example-basic-single").select2({
                dropdownParent: parentElement
            });
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    // this.addJsToElement('../assets/bundles/jquery-validation/dist/jquery.validate.min.js').onload ;
    // this.addJsToElement('../assets/bundles/jquery-steps/jquery.steps.min.js').onload;
    // this.addJsToElement('../assets/js/page/form-wizard.js').onload ;    
    // this.loadNumbers();
    this.loadNumbers();
    this.getAllpublishers();
    this.getAlltarget();
    this.getAllLabels();
   
    this.addForm = this.fb.group({
      campaignName : ['', Validators.required],
      country: [''],      
      // option: [''],      
      number: [''],      
      labeldata: [''],      
      numberIsFor: [''],      
      // numberType: [''],             
      publisher: [''],             
      payout_typeedit: [''],      
      payout_amount: [''],      
      payout_trigger: [''],      
      payout_seconds: [''],

      target: [''],   
      conversation_revenue: [''],      
      conversation_event: [''],      
      conversation_seconds: [''],        

      user_uuid: [''],        

      recording: [''],             
    });
    
    this.addForm.patchValue({
      country:'United States'
    });
    // this.value = ['multiple2', 'multiple4'];

    this.options = {
      width: '300',
      multiple: true,
      // tags: true
    };
  }

  ngAfterViewInit(): void {
    this.changedetect.detectChanges(); 
  }

  payout_typevalueedit:any = 0;
payout_typevaledit(val:number) {
  this.payout_typevalueedit = val;
  this.addForm.get('payout_typeedit').setValue(val); 
}

  ngOnDestroy(){
    const selectOptionsContainer = $('.select2-container');
    selectOptionsContainer.removeClass('span.select2-selection.select2-selection--single')
  }


  // public numberdata: any;
  // public numberdata: Array<Select2OptionData>;
  loadNumbers(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.loadNumbersfordropdown(this.currentCompany.uuid).subscribe(
      resp => {
          this.numberdata = resp;     
     },
        error => this.error = error
    );
  }

  labeldata:any
  getAllLabels(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getAllLabels(this.currentCompany.uuid).subscribe(
      res => {
        this.labeldata = res;
        // console.log(res);
      },
      error => this.error = error,
    );  
  }

  publisherdata:any;
  getAllpublishers(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getAllpublishers(this.currentCompany.uuid).subscribe(
      res => {
        this.publisherdata= res                
        },
        error => this.error = error
    );
  }
 
  targetdata:any;
  getAlltarget(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getAlltarget(this.currentCompany.uuid).subscribe(
      res => {
        // console.log(res);
        
        this.targetdata= res
        },
        error => this.error = error
    );
  }

  selectedpub:any = [];
  getPublisher(event:any){
      this.addForm.get('publisher').setValue(event[0].id);    
  }
  selectedtarget:any = [];
  getTarget(event:any){
      this.addForm.get('target').setValue(event[0].id);    
  }

  numberIsFor:number;
  numberIsForval(type:number){
    this.numberIsFor = type;
    this.addForm.get('numberIsFor').setValue(type); 

  }

  recording:string;
  get_recordingstatus(event:any){
    this.recording = event.target.checked;
    this.addForm.get('recording').setValue(this.recording); 
  }  

Submitted = false;
//Add Publisher
get addFormcontrol() { return this.addForm.controls; }  
onSubmit () {
  // console.log(this.addForm.value);
  // return false;
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.addForm.get('user_uuid').setValue(currentUser.uuid); 

    this.moduleservice.addCampaign(this.currentCompany.uuid,this.addForm.value).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.isName = "fail";
          this.toastrService.success('Campaign Added Successfully');  
          this.Submitted = false;          
          this.router.navigate(['/campaigns']);
        }else{
          this.toastrService.error('Failed To Add Campaign');
        }
      },
      error => this.error = error,
    );     
   }

   isName : any; 
  validatename() {
  if(this.addForm.get('campaignName').value!=''){     
    this.moduleservice.validateCampaignName(this.addForm.get('campaignName').value).subscribe(
      res =>{
          // console.log(res); return false;  
        this.isName = res;
        if (this.isName.status == 'success') {
          this.isName = "success";
        }
        else{
          this.isName = "fail";
        }
      }    
    );
  }else{
    this.isName="";
  }
}

  //  states_list = [
  //   {
  //     "label": "Alabama",
  //     "value": "AL"
  //   },
  //   {
  //     "label": "Alaska",
  //     "value": "AK"
  //   },
  //   {
  //     "label": "American Samoa",
  //     "value": "AS"
  //   },
  //   {
  //     "label": "Arizona",
  //     "value": "AZ"
  //   },
  //   {
  //     "label": "Arkansas",
  //     "value": "AR"
  //   },
  //   {
  //     "label": "California",
  //     "value": "CA"
  //   },
  //   {
  //     "label": "Colorado",
  //     "value": "CO"
  //   },
  //   {
  //     "label": "Connecticut",
  //     "value": "CT"
  //   }
  // ];

  //  Multistep

//   activedStep = 0;
 
//   model:any = {};
  
//   steps: StepType[] = [
//     {
//       label: 'Setup Your Campaign',
//       fields: [
//         {
//           key: 'campaignName',
//           type: 'input',
//           templateOptions: {
//             label: 'Campaign Name',
//             required: true,
//             change: this.addPreview.bind(this),
//           },
//         },
//         {
//           key: 'country',
//           type: 'select',
//           templateOptions: {
//             label: 'Country',
//             required: true,
//             change: this.addPreview.bind(this),
//             placeholder: 'Select Country',
//             options: [
//               { value: 'United States', label: 'United States'  },             
//             ],
//           },
          
//         },
//       ],
//     },
//     {
//       label: 'Get a Tracking Number',
//       fields: [
//         {
//           key: 'option',
//           type: 'radio',
//           templateOptions: {
//             label: 'Choose Option',
//             // required: true,
//             id: 'customClass',
//             change: this.addPreview.bind(this),
//             options: [
//               { value: 0, label: 'Select Existing ' },
//               { value: 1, label: ' Create New' },          
//             ],
//           },
//         },
//         {
//           key: 'numberType',
//           type: 'radio',
//           templateOptions: {
//             label: 'Number Type',
//             // required: true,
//             className: 'customClass',
//             change: this.addPreview.bind(this),
//             options: [
//               { value: 0, label: 'Toll Free' },
//               { value: 1, label: 'Local' },          
//             ],                 
//           },
//           hideExpression: "!model.option"     
//         },
//         {
//           key: 'prefix',
//           type: 'input',
//           templateOptions: {
//             label: 'Prefix',
//             // required: true,
//             change: this.addPreview.bind(this),
//           },
//           hideExpression: "!model.option"    
//         },
//         {
//           key: 'number',
//           type: 'select',
//           templateOptions: {
//             label: 'Select Existing Number',
//             // required: true,
//             change: this.addPreview.bind(this),
//             placeholder: 'Select Number',
//             options:this.numberdata
            
//           },
//           focus:true,  
//           hideExpression: "model.option"           
//         },
//         {
//           key: 'numberIsFor',
//           type: 'radio',
//           templateOptions: {
//             label: 'This number is for',
//             // required: true,
//             change: this.addPreview.bind(this),
//             className: 'customClass',
//             options: [
//               { value: 0, label: 'Me' },
//               { value: 1, label: 'Publisher' },          
//             ],
//           },
//         },
//         {
//           key: 'publisher',
//           type: 'select',
//           templateOptions: {
//             label: 'Publisher',
//             // required: true,
//             change: this.addPreview.bind(this),
//             className: 'customClass',
//             options: [
//               { value: 0, label: 'Pub-1' },
//               { value: 1, label: 'Pub-2' },          
//             ],
//           },
//           hideExpression: "!model.numberIsFor"           
//         },
//       ],
//     },
//     {
//       label: 'Add a Target',
//       fields: [
//         {
//           key: 'target',
//           type: 'select',
//           templateOptions: {
//             label: 'Select Target',
//             required: true,
//             change: this.addPreview.bind(this),
//             className: 'customClass',
//             options: [
//               { value: 0, label: 'Target-1' },
//               { value: 1, label: 'Target-2' },          
//             ],
//           },
//         },
//       ],
//     },
//     {
//       label: 'Set it Live!',
//       fields: [
//         {
//           key: 'campaignNameget',
//           type: 'input',
//           defaultValue: this.model.campaignName,
//           templateOptions: {
//             label: 'Campaign Name',
//           },
//           expressionProperties: {
//             'templateOptions.disabled': '!model.text',
//           },
//         },
//         {
//           key: 'countryget',
//           type: 'select',
//           templateOptions: {
//             label: 'Country',
//             placeholder: 'Select Country',
//             options: [
//               { value: 'United States', label: 'United States'  },             
//             ],
//           },
//           expressionProperties: {
//             'templateOptions.disabled': '!model.text',
//           },
          
//         },
//         {
//           key: 'optionget',
//           type: 'radio',
//           templateOptions: {
//             label: 'Choose Option',
//             className: 'customClass',
//             options: [
//               { value: 1, label: 'Select Existing ' },
//               { value: 2, label: ' Create New' },          
//             ],
//           },
//           expressionProperties: {
//             'templateOptions.disabled': '!model.text',
//           },
//         },
//         {
//           key: 'prefixget',
//           type: 'input',
//           templateOptions: {
//             label: 'Prefix',
//             // required: true,
//             change: this.addPreview.bind(this),
//           },
//           expressionProperties: {
//             'templateOptions.disabled': '!model.text',
//           },
//         },
//         {
//           key: 'numberIsForget',
//           type: 'radio',
//           templateOptions: {
//             label: 'This number is for',
//             // required: true,
//             change: this.addPreview.bind(this),
//             className: 'customClass',
//             options: [
//               { value: 0, label: 'Me' },
//               { value: 1, label: 'Publisher' },          
//             ],
//           },
//           expressionProperties: {
//             'templateOptions.disabled': '!model.text',
//           },
//         },
//         {
//           key: 'numberget',
//           type: 'select',
//           templateOptions: {
//             label: 'Select Existing Number',
//             placeholder: 'Select Number',
//             options: [
//               { value: '1', label: '1'  },             
//             ],
//           },
//           expressionProperties: {
//             'templateOptions.disabled': '!model.text',
//           },
//         },
//         {
//           key: 'numberTypeget',
//           type: 'radio',
//           templateOptions: {
//             label: 'This number is for',
//             className: 'customClass',
//             options: [
//               { value: 1, label: 'Me' },
//               { value: 2, label: 'Publisher' },          
//             ],
//           },
//           expressionProperties: {
//             'templateOptions.disabled': '!model.text',
//           },
//         },
//         {
//           key: 'publisherget',
//           type: 'select',
//           templateOptions: {
//             label: 'Publisher',
//             className: 'customClass',
//             options: [
//               { value: 1, label: 'Me' },
//               { value: 2, label: 'Publisher' },          
//             ],
//           },
//           expressionProperties: {
//             'templateOptions.disabled': '!model.text',
//           },
//         },
//         {
//           key: 'targetget',
//           type: 'select',
//           templateOptions: {
//             label: 'Select Target',
//             className: 'customClass',
//             options: [
//               { value: 1, label: 'Me' },
//               { value: 2, label: 'Publisher' },          
//             ],
//           },
//           expressionProperties: {
//             'templateOptions.disabled': '!model.text',
//           },
//         },
//       ],
//     },
//   ];

//   form = new FormArray(this.steps.map(() => new FormGroup({})));
//   options = this.steps.map(() => <FormlyFormOptions> {});

//   prevStep(step) {
//     this.activedStep = step - 1;
//   }

//   nextStep(step) {
//     // console.log(step);
//     // if(step==0){
//     //   this.loadNumbers();
//     // }
//     this.activedStep = step + 1;
//   }

//   submit() {
//     alert(JSON.stringify(this.model));
//   }

//  addPreview(){
//         this.model = {
//           campaignName:this.model.campaignName,          
//           country:this.model.country,          
//           option:this.model.option,          
//           prefix:this.model.prefix,          
//           numberIsFor:this.model.numberIsFor,          
//           number:this.model.number,          
//           numberType:this.model.numberType,          
//           publisher:this.model.publisher,          
//           target:this.model.target,          
          
//           campaignNameget:this.model.campaignName,          
//           countryget:this.model.country,          
//           optionget:this.model.option,          
//           prefixget:this.model.prefix,          
//           numberIsForget:this.model.numberIsFor,          
//           numberget:this.model.number,          
//           numberTypeget:this.model.numberType,          
//           publisherget:this.model.publisher,          
//           targetget:this.model.target,          
//         }
//      }

}
