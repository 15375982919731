import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModuleService } from './../module.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit,ChangeDetectorRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
declare var $;
const router = Router;
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { exit } from 'process';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-numbers',
  templateUrl: './numbers.component.html',
  styleUrls: ['./numbers.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush

})
export class NumbersComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  dataTable: any;
  number: Number;
  action: any;
  error: {};
  showLoadingIndicator: any;
  dtOptions: DataTables.Settings = {};
  numbers: any;
  that: Number;
  private http: HttpClient;
  currentCompany: any;
  ischecked: string = '1';
  public publishers: any;
  addForm: FormGroup;
  editForm: FormGroup;
  addLabelForm: FormGroup;

  dropdownSettingss:IDropdownSettings;
  campaigndropdownSettings = {};

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    text:"Select Associated Labels",
    selectAllText:'Select All',
    unSelectAllText:'UnSelect All',
    enableSearchFilter: true,
    classes:"myclass custom-class",
  };


  searchbynumbers: any = '';
  searchbycampaign: any = '';
  searchbylabels: any = '';
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    handler: HttpBackend,
    private fb: FormBuilder,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private titleService: Title,
    private ngxLoader: NgxUiLoaderService,
  ){
    this.http = new HttpClient(handler);
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  getAllLabels(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getAllLabels(this.currentCompany.uuid).subscribe(
      res => {
        this.dropdownList = res;
        // console.log(res);
      },
      error => this.error = error,
    );
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  role:any="";
  useruuid:any="";
  editpayout_typeval:number;
  selectedItemspub = [];
  selectedItemscam = [];

  ngOnInit() {
    this.setTitle('Manage Numbers')
    var loggedinuser = JSON.parse(localStorage.getItem('currentUser'));
    if(loggedinuser!=null){
    this.role = loggedinuser.role;
    this.useruuid = loggedinuser.uuid;
    }
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.loadNumbers();
    this.getAllpublishers();
    this.getAllLabels();
    this.getAllcampaign();

    this.campaigndropdownSettings = {
      singleSelection: true,
      text:"Select Campaign",
      enableSearchFilter: true,
      classes:"myclass custom-class",
      showCheckbox:false
    };

    this.addForm = this.fb.group({
      country : ['', Validators.required],
      numberType: [''],
      prefix: [''],
      number: [''],
      publisher: ['', Validators.required],
      payout_typeedit: [''],
      payout_amount: [''],
      payout_trigger: [''],
      payout_seconds: [''],
      campain: ['', Validators.required],
      assosciated_labels: [''],
      user_uuid: [''],
    });
    // let prefix="";
    // if(this.addForm.get('country').value=="United Kingdom"){
    //   prefix = "+1";
    // }
    // if(this.addForm.get('country').value=="United States"){
    //   prefix = "+44";
    // }
    this.addForm.patchValue({
      country:'United States',
      prefix:"+1"
    });

    this.editForm = this.fb.group({
      country : ['', Validators.required],
      uuid: [''],
      numberType: [''],
      prefix: [''],
      number: [''],
      publisher: [''],
      payout_uuid: [''],
      payout_typeedit: [''],
      payout_amount: [''],
      payout_trigger: [''],
      payout_seconds: [''],
      campain: [''],
      assosciated_labels: [''],
      user_uuid: [''],
    });

    this.addLabelForm = this.fb.group({
      name: ['', Validators.required],
      notes: ['',Validators.required],
    });


  }

  getAllpublishers(){
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getAllpublishers(this.currentCompany.uuid).subscribe(
      res => {
        //  console.log(res); return false;
        this.publishers = res;
      },
      error => this.error = error,
    );
  }

getallcampain : any;
getAllcampaign(){
  this.moduleservice.getallcampaindata(this.currentCompany.uuid).subscribe(
    res =>{
      this.getallcampain = res;
      // console.log(this.getallcampain);
    }
  );
}

  loadNumbers(){
        // console.log('ok');
        // return false;
          if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
            $(this.table.nativeElement).dataTable().fnDestroy();
          }
         this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
         this.dtOptions = {
            "pagingType": 'full_numbers',
            "processing": true,
            "serverSide": true,
            "pageLength": 1000,
            deferRender:    true,
            scrollY:        "800px",
            scrollCollapse: true,
            "lengthMenu": [50,100,500,1000,2000,5000],
            "order": [[ 1, "asc" ]],
            ajax: (dataTablesParameters: any, callback) => {
              // this.http.post<DataTablesResponse>(
              //   this.ServerUrl+'getnumbersdata/'+this.currentCompany.uuid,
              //   dataTablesParameters, {}
              this.http.post<DataTablesResponse>(
                this.ServerUrl+'getnumbersdata/'+this.currentCompany.uuid, {'dataTablesParameters':dataTablesParameters,'number':this.searchbynumbers,'campaign':this.searchbycampaign,'labels':this.searchbylabels, role:this.role, useruuid:this.useruuid}
               , {}
              ).subscribe(resp => {
                        this.numbers = resp.data;
                        // console.log(this.numbers);

                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
              // ,{data:"target"}
              {data:"checkall"},{data:"number"},{data:"campaign"},{data:"publisher"},{data:"status"},{data:"created_at"},{data:"assosciated_label"},{data:"action"},
            ],
            columnDefs: [
              {
                  "targets": [0],
                  "orderable": false,
              },
              ],
        };

      this.dataTable = $(this.table.nativeElement);
      this.dataTable.dataTable(this.dtOptions);
  }


  numberTypeadd:any;
  labels: any = [];
  editdata(uuid:string,prefix:string,numberType:string,number:string,country:string,publisher:string,campaign_uuid:string){
  console.log(publisher);
  if(campaign_uuid!="" || campaign_uuid!=undefined){
    let text =  this.getallcampain.filter(x => x.id == campaign_uuid)[0];
    this.selectedItemscam = [{"id":campaign_uuid,"itemName":text.text}];
  }
  if(publisher!="" || publisher!=undefined){
    let text =  this.publishers.filter(x => x.id == publisher)[0];
    this.selectedItemspub = [{"id":publisher,"itemName":text.text}];
  }
  // console.log(this.selectedItemscam);

  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getnumberAssciatedlabels(this.currentCompany.uuid,number).subscribe(
       res => {
       this.labels = res
      //  console.log(this.labels);
      },
      error => this.error = error
    );

    this.moduleservice.getpayoutbynumberuuid(uuid).subscribe(
       res => {
      //  console.log(this.payoutdata);
      if(res!=''){
        this.payoutedit_typevalue = res.payout_type;

        this.editForm.get('payout_uuid').setValue(res.uuid);
        this.editForm.patchValue({
          payout_typeedit: res.payout_typeedit,
          payout_amount: res.payout_amount,
          payout_trigger: res.payout_trigger,
          payout_seconds: res.payout_seconds
        });
      }
      },
      error => this.error = error
    );

    let editprefix="";
    if(country=="United Kingdom"){
      editprefix = "+44";
    }
    if(country=="United States"){
      editprefix = "+1";
    }

    this.numberTypeadd = numberType;
      this.editForm.patchValue({
      uuid : uuid,
      country : country,
      numberType : numberType,
      prefix: editprefix,
      number: number,
    });
  }

  checkalldelete(event) {
    const checked = event.target.checked;
    this.numbers.forEach(chkbox => chkbox.selected = checked);
  }

  checkboxes:any = [];
  selectednumber:any = [];
  deleteselected(event:any){
    // $("#customCheck1").prop("checked", false);
    if(event.target.checked==true)
    {
      if(event.target.value=='on'){
        this.checkboxes = [];
        this.selectednumber = [];
        this.numbers.forEach(lbl => {
          this.checkboxes.push(lbl.id);
          this.selectednumber.push(lbl.number);
        });
      }else{
        if(this.checkboxes.length+1==this.numbers.length){
          $( "#customCheck1" ).prop( "checked", true );
        }
        this.checkboxes.push(event.target.value);
        this.numbers.forEach(lbl => {
          if(event.target.value == lbl.id){
          this.selectednumber.push(lbl.number);}
        });
        // this.selectednumber.push(event.target.value);
        }
        this.ischecked = '0';
    }else{
      // console.log(this.ischecked);
      if(this.checkboxes.length!=0){
        $( "#customCheck1" ).prop( "checked", false );
      }
      if(event.target.value!='on'){
        const index = this.checkboxes.indexOf(event.target.value);
        this.checkboxes.splice(index, 1);
        this.selectednumber.splice(index, 1);
        // console.log('123='+this.selectednumber.length);
        if(this.selectednumber.length==0){
        this.ischecked = '1';
        }
      }else{
        this.checkboxes = [];
        this.selectednumber = [];
        // console.log(this.selectednumber.length);
        if(this.selectednumber.length==0){
          this.ischecked = '1';
        }
      }

    }
    // console.log(this.ischecked);
  }

  onBatchEditSubmit(){
    // console.log(this.selectednumber); return
    this.moduleservice.setNumbersdata({
      numberslist: this.selectednumber,
    });
    this.router.navigate(['/batch-edit']);
  }

  onDeleteSubmit(){
    if(this.checkboxes.length==0){
      this.toastrService.error('Please Select Row To Delete');
    }else{
      // console.log(this.checkboxes);
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Selected Record!',

        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {

          this.moduleservice.deleteSelectedNumbers(this.checkboxes).subscribe(
            res => {
                Swal.fire(
                  'Deleted!',
                  'Records Deleted Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
                this.loadNumbers();
              },
              error => this.error = error
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }

  }



  onStatusChangeSubmit(){
    // console.log(this.checkboxes);

    if(this.checkboxes.length==0){
      this.toastrService.error('Please Select Row To Status Change');
    }else{
      // console.log(this.checkboxes);
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Change status of selected record!',

        showCancelButton: true,
        confirmButtonText: 'Yes, Change it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {

          this.moduleservice.StatusChangeSelectedNumbers(this.checkboxes).subscribe(
            res => {
                Swal.fire(
                  'Status Changed!',
                  'Status Changed Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
                this.checkboxes = [];
                this.loadNumbers();
              },
              error => this.error = error
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }

  }


  Delete(uuid: any,number: any,company_uuid:any) {
    this.ngxLoader.startLoader('loader-01');
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.moduleservice.getNumberdatabyno(number,company_uuid).subscribe(
    res => {
      this.ngxLoader.stopLoader('loader-01');
    Swal.fire({
      title: 'Are you sure?',
      html: `Hey! You're trying to delete a number that has received <span class="text-danger"><b>${res.allcalls}</b></span> calls in the past <span class="text-success"><b>7 days</b></span>, are you sure you want to delete this?`,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.moduleservice.deleteNumberdata(uuid,currentUser.uuid).subscribe(
            res => {
              // console.log(res);return false;
              this.loadNumbers();
              // this.toastrService.success('Number Deleted Successfully');
              Swal.fire(
                'Deleted!',
                'Record Deleted Successfully.',
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);


            },
            error => this.error = error
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  })
  }

  numberTypeval(val:number) {
  this.addForm.get('numberType').setValue(val);
}

payout_typevalueedit:any = 0;
payout_typevaledit(val:number) {
  this.payout_typevalueedit = val;
  this.addForm.get('payout_typeedit').setValue(val);
}

payoutedit_typevalue:any = 0;
payoutedit_typeval(val:number) {
  this.payoutedit_typevalue = val;
  this.editForm.get('payout_typeedit').setValue(val);
}

Submitted = false;
//Add Publisher
get addformcontrol() { return this.addForm.controls; }
onSubmit () {
    const formData = new FormData();
    this.Submitted = true;
    if (this.addForm.invalid) {
        return;
    }

    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    formData.append('user_uuid', currentUser.uuid);
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('country', this.addForm.get('country').value);
    formData.append('numberType', this.addForm.get('numberType').value);
    formData.append('prefix', this.addForm.get('prefix').value);
    formData.append('number', this.addForm.get('number').value);
    formData.append('publisher', JSON.stringify(this.addForm.get('publisher').value));
    formData.append('payout_typeedit', this.addForm.get('payout_typeedit').value);
    formData.append('payout_amount', this.addForm.get('payout_amount').value);
    formData.append('payout_trigger', this.addForm.get('payout_trigger').value);
    formData.append('payout_seconds', this.addForm.get('payout_seconds').value);
    formData.append('campain', JSON.stringify(this.addForm.get('campain').value));
    formData.append('assosciated_labels', JSON.stringify(this.addForm.get('assosciated_labels').value));

    // console.log(formData); return false;

    this.moduleservice.addNumberdata(formData).subscribe(
      res => {
        // console.log(res.status); return false;
        if (res.status == 'error') {
          this.toastrService.error('Failed To Add Label');
        }else if (res.status == 'duplicate') {
          this.toastrService.error('The Number you Entered is Already Available');
        }else{
          this.toastrService.success('Number Added Successfully');
          this.loadNumbers();
          this.Submitted = false;
        }
      },
      error => this.error = error,
    );
   }

editSubmitted = false;
//Add Buyer
get editFormcontrol() { return this.editForm.controls; }
onEditSubmit () {
    // this.editForm.get('assosciated_labels').setValue(JSON.stringify(this.labels));
    // alert('ok');return false;
    const formData = new FormData();
    this.editSubmitted = true;
    if (this.editForm.invalid) {
        return;
    }
    // console.log(this.labels);return false;

    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    formData.append('user_uuid', currentUser.uuid);
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('uuid', this.editForm.get('uuid').value);
    formData.append('country', this.editForm.get('country').value);
    formData.append('numberType', this.editForm.get('numberType').value);
    formData.append('prefix', this.editForm.get('prefix').value);
    formData.append('number', this.editForm.get('number').value);
    formData.append('payout_uuid', this.editForm.get('payout_uuid').value);
    formData.append('publisher', JSON.stringify(this.editForm.get('publisher').value));
    formData.append('payout_typeedit', this.editForm.get('payout_typeedit').value);
    formData.append('payout_amount', this.editForm.get('payout_amount').value);
    formData.append('payout_trigger', this.editForm.get('payout_trigger').value);
    formData.append('payout_seconds', this.editForm.get('payout_seconds').value);
    formData.append('campain', JSON.stringify(this.editForm.get('campain').value));
    formData.append('assosciated_labels', JSON.stringify(this.labels));

    this.moduleservice.editNumberdata(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Number Updated Successfully');
          this.loadNumbers();
          this.editSubmitted = false;
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );
   }


  closeModal() {
    // console.log(this.addForm.get('country').value);return false;
    if(!this.addForm.invalid){
      $("#addModel").modal("hide");
      // $(".custom-switch-input").prop('checked',false);
      this.Submitted = false;
      setTimeout(() => { this.addForm.reset();}, 2000);
    }

    if(this.addLabelForm.get('name').value && this.addLabelForm.get('notes').value)
    {
      $("#addLabels").modal("hide");
      $(".custom-switch-input").prop('checked',false);
      this.LabelSubmitted = false;
      setTimeout(() => {
        this.addLabelForm.reset();
        this.isName="";
      }, 2000);
    }
  }
  closeEditModal() {
    if(this.editForm.get('country').value!='')
    {
      $("#editModel").modal("hide");
      this.editSubmitted = false;
      setTimeout(() => { this.editForm.reset();}, 2000);
    }
  }

  LabelSubmitted = false;
  get labelcontrol() { return this.addLabelForm.controls; }
  onLabelSubmit () {
    const formData = new FormData();
    this.LabelSubmitted = true;
    if (this.addLabelForm.invalid) {
        return;
    }
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('name', this.addLabelForm.get('name').value);
    formData.append('notes', this.addLabelForm.get('notes').value);
    this.moduleservice.addLabel(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Label Added Successfully');
          this.getAllLabels();
          this.LabelSubmitted = false;
          this.isName = false;

        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );
   }

   isName : any;
   validatename() {
   if(this.addLabelForm.get('name').value!=''){
     this.moduleservice.validateLabelName(this.addLabelForm.get('name').value).subscribe(
       res =>{
           // console.log(res); return false;
         this.isName = res;
         if (this.isName.status == 'success') {
           this.isName = "success";
         }
         else{
           this.isName = "fail";
         }
       }
     );
   }else{
     this.isName="";
   }
 }

  exportNumber:any = {};
  finalexportNumber:any = [];
  exportdata(){
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      // showTitle: true,
      // title: 'Your title',
      useBom: true,
      // noDownload: true,
      headers: ["prefix", "number", "country","campaign","publisher","target","created_at"]
    };

    this.numbers.forEach((element,index)=>{
      this.exportNumber.prefix = element.prefix;
      this.exportNumber.number = element.number;
      this.exportNumber.country = element.country;
      this.exportNumber.campaign = element.campaign;
      this.exportNumber.publisher = element.publisher;
      this.exportNumber.target = element.target;
      // this.exportNumber.assosciated_labels = element.assosciated_labels;
      this.exportNumber.created_at = element.created_at;

      this.finalexportNumber[index] = this.exportNumber;
   });

  //  console.log(this.numbers);
  //  console.log(this.finalexportNumber); return;
    new   ngxCsv(this.finalexportNumber, 'All Numbers', options);

  }

  addprefix(event){
    // alert(event)
    if(event=="United Kingdom"){
      this.addForm.patchValue({
        prefix:"+44"
      });
    }
    if(event=="United States"){
      this.addForm.patchValue({
        prefix:"+1"
      });
    }
  }

  editprefix(event){
    // alert(event)
    if(event=="United Kingdom"){
      this.editForm.patchValue({
        prefix:"+44"
      });
    }
    if(event=="United States"){
      this.editForm.patchValue({
        prefix:"+1"
      });
    }
  }

  url: string = 'https://www.wellingtonsoccer.com/lib/api/auth.cfc?returnFormat=JSON&method=Authenticate';
  purchase_number(event){
    // alert(event);
    if(event==1){
      const headers = new HttpHeaders()
      .set('cache-control', 'no-cache')
      .set('content-type', 'application/json')
      .set('postman-token', 'b408a67d-5f78-54fc-2fb7-00f6e9cefbd1');

    const body = {
        email: 'myemail@xyz.com',
        user_password: 'mypasss',
        token: 'my token'
    }




    this.http.post(this.url,body, { headers: headers }).subscribe(
      data=>{
        console.log(data);
      }
    );
  }
}

}

