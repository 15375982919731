import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders, HttpBackend} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError,map } from 'rxjs/operators';
import { Menu } from './menu';
import { GlobalVariable } from '.././global';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private ServerUrl = GlobalVariable.BASE_API_URL;

  errorData: {};

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };  
  
  private http: HttpClient;

  constructor(handler: HttpBackend) {
      this.http = new HttpClient(handler);
  }
  getMenu(role:any) {
    return this.http.get<Menu>(this.ServerUrl + 'getmenu/'+role).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }
}
