import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, NgForm } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { ModuleService } from './../../module.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-rulesadd',
  templateUrl: './rulesadd.component.html',
  styleUrls: ['./rulesadd.component.scss']
})
export class RulesaddComponent implements OnInit {
  addForm: FormGroup;
  error: string;
  controlEnabled:boolean = true;
  constructor(
    private fb: FormBuilder,
    private toastrService:ToastrService, 
    private moduleService: ModuleService,
    private router: Router,

  ) { }

  ngOnInit() {
    this.addForm = this.fb.group({ 
      name: ['',Validators.required],
      itemRows: this.fb.array([this.initItemRow()]),
    });    
  }

  submitted = false;
  currentCompany:any;
  get f() { return this.addForm.controls; }  
  onSubmit () {
    // const formData = new FormData();      
    this.submitted = true;
    if (this.addForm.invalid) {
        return;
    }
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    // console.log(this.addForm.value);
    this.moduleService.addRuleSet(this.addForm.value,this.currentCompany.uuid).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Rule Set Added Successfully');
          this.router.navigate(['/rules']);
        }else{
          this.toastrService.error('Failed To Add');
        }
      },
      error => this.error = error,
    );     
  }

  initItemRow(){
    return this.fb.group({
      ifval: ['NUMBER',''],
      isval: ['',''],
      thenval: ['FORWARD_TO',''],
      thentxt: ['',''],
      andval: ['STOP',''],
    });
  }

  addRow(){
    // alert('ok');
    const control = <FormArray>this.addForm.controls['itemRows'];
    control.push(this.initItemRow());
  }

  totalrow:number;
  deleteRow(index:number){
    const control = <FormArray>this.addForm.controls['itemRows'];
    if(control!=null){
      this.totalrow = control.value.length;      
    }
    if(this.totalrow>1){
      control.removeAt(index);  
    }else{
      this.toastrService.error('One Record is Mendatory');
      // alert('One Record is Mendatory');
      return false;
    }
}


_keyPress(event: any) {
  const isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };
  
  const isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };
  
  const enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if(!isNumericInput(event) && !isModifierKey(event)){
      event.preventDefault();
    }
  };
  
  const formatToPhone = (event) => {
    if(isModifierKey(event)) {return;}
  
    const target = event.target;
    const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
    const zip = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);
  
    if(input.length > 6){target.value = `(${zip}) ${middle}-${last}`;}
    else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
    else if(input.length > 0){target.value = `(${zip}`;}
  };
  // const inputElement = document.getElementById('number');
  const inputElement =document.getElementsByClassName('number');
  if(inputElement){
    for (let i = 0; i < inputElement.length; i++) {
      inputElement[i].addEventListener('keydown',enforceFormat);
      inputElement[i].addEventListener('keyup',formatToPhone);    
  }
     }  
    
}


}
