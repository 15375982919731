import { FormGroup,Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from './../../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../../global';
import { exit } from 'process';
// import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Title } from '@angular/platform-browser';
declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


@Component({
  selector: 'app-number-buyer',
  templateUrl: './number-buyer.component.html',
  styleUrls: ['./number-buyer.component.scss']
})
export class NumberBuyerComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;

    @ViewChild('dataTable', {static: true}) table: ElementRef;
    dataTable: any;
    number: Number;
    action: any;
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {};
    numbers: any;
    that: Number;
    private http: HttpClient;
    currentCompany: any;
    ischecked: string = '1';

    addForm: FormGroup;
    editForm: FormGroup;


    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
      private titleService: Title
    ){
      this.http = new HttpClient(handler);
    }

    public setTitle(newTitle: string) {
      this.titleService.setTitle(newTitle);
    }

    ngOnInit() {
      this.setTitle('Manage Buyers')
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.loadNumbers();

      this.addForm = this.fb.group({
        company : ['', Validators.required],
        email : ['', Validators.required],
        password : [''],
        sub_id: [''],
        allow_buyer_to_pause_target: [''],
        allow_buyer_to_set_target_concurrency: [''],
        allow_buyer_to_dispute_call_conversions: [''],
        limit_revenue: [''],
      });

      this.editForm = this.fb.group({
        uuid: [''],
        company : ['', Validators.required],
        email : ['', Validators.required],
        password : [''],
        sub_id: [''],
        allow_buyer_to_pause_target: [''],
        allow_buyer_to_set_target_concurrency: [''],
        allow_buyer_to_dispute_call_conversions: [''],
        limit_revenue: [''],
      });
    }
    loadNumbers(){
          // console.log('ok');
          // return false;
            if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
              $(this.table.nativeElement).dataTable().fnDestroy();
            }
            this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
           this.dtOptions = {
              "pagingType": 'full_numbers',
              "processing": true,
              "serverSide": true,
              "pageLength": 20,
              "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],
              "order": [[ 0, "asc" ]],
              ajax: (dataTablesParameters: any, callback) => {
                this.http.post<DataTablesResponse>(
                  this.ServerUrl+'getbuyers/'+this.currentCompany.uuid,
                  dataTablesParameters, {}
                ).subscribe(resp => {
                          this.numbers = resp.data;
                          callback({
                              recordsTotal: resp.recordsTotal,
                              recordsFiltered: resp.recordsFiltered,
                              data: [],
                          });
                      });
              },
              columns: [
                // {data:"checkall"},
                // {data:"hour"},{data:"day"},{data:"month"},{data:"total"},
                // ,{data:"status"}
                // ,{data:"allow_buyer_to_dispute_call_conversions"},
                {data:"company"},{data:"email"},{data:"sub_id"},{data:"allow_buyer_to_pause_target"},{data:"allow_buyer_to_set_target_concurrency"},{data:"created_at"},{data:"action"},
              ],
              columnDefs: [
                {
                    "targets": [0],
                    "orderable": false,
                },
                ],
          };

        this.dataTable = $(this.table.nativeElement);
        this.dataTable.dataTable(this.dtOptions);
    }

  editdata(uuid:string,company:string,sub_id:string,allow_buyer_to_pause_target:number,allow_buyer_to_set_target_concurrency:number,allow_buyer_to_dispute_call_conversions:number,limit_revenue:number,email:number){
    this.editForm.patchValue({
      uuid : uuid,
      company : company,
      email : email,
      sub_id: sub_id,
      allow_buyer_to_pause_target: allow_buyer_to_pause_target,
      allow_buyer_to_set_target_concurrency: allow_buyer_to_set_target_concurrency,
      allow_buyer_to_dispute_call_conversions: allow_buyer_to_dispute_call_conversions,
      limit_revenue: limit_revenue,
    });
  }


    checkalldelete(event) {
      const checked = event.target.checked;
      this.numbers.forEach(chkbox => chkbox.selected = checked);
    }

    checkboxes:any = [];
    deleteselected(event:any){
      // $("#customCheck1").prop("checked", false);
      if(event.target.checked==true)
      {
        if(event.target.value=='on'){
          this.checkboxes = [];
          this.numbers.forEach(lbl => {
            this.checkboxes.push(lbl.id);
          });
        }else{
          if(this.checkboxes.length+1==this.numbers.length){
            $( "#customCheck1" ).prop( "checked", true );
          }
          this.checkboxes.push(event.target.value);            }
          this.ischecked = '0';
      }else{
        // console.log(this.checkboxes);
        if(this.checkboxes.length!=0){
          $( "#customCheck1" ).prop( "checked", false );
        }
        if(event.target.value!='on'){
          const index = this.checkboxes.indexOf(event.target.value);
          this.checkboxes.splice(index, 1);
        }else{
          this.checkboxes = [];
        }
        this.ischecked = '1';
      }
      // console.log(this.checkboxes);
    }

    onDeleteSubmit(){
      if(this.checkboxes.length==0){
        this.toastrService.error('Please Select Row To Delete');
      }else{
        // console.log(this.checkboxes);
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to Delete Selected Record!',

          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {

            this.moduleservice.deleteSelectedNumbers(this.checkboxes).subscribe(
              res => {
                  Swal.fire(
                    'Deleted!',
                    'Records Deleted Successfully.',
                  )
                  setTimeout(() => {
                    Swal.close()
                  }, 2000);
                  this.loadNumbers();
                },
                error => this.error = error
            );
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your Record is safe :)',
              'error'
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);
          }
        });
      }

    }

    Delete(id: number) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Record!',

        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.moduleservice.deleteBuyer(+id,currentUser.uuid).subscribe(
              res => {
                this.loadNumbers();
                // this.toastrService.success('Number Deleted Successfully');
                Swal.fire(
                  'Deleted!',
                  'Record Deleted Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);


              },
              error => this.error = error
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }


Submitted = false;
currentUser:any;
//Add Buyer
get buyercontrol() { return this.addForm.controls; }
onSubmit () {
    const formData = new FormData();
    this.Submitted = true;
    if (this.addForm.invalid) {
        return;
    }
    this.closeModal();
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(this.currentUser);return;
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('user_uuid', this.currentUser.uuid);
    formData.append('company', this.addForm.get('company').value);
    formData.append('email', this.addForm.get('email').value);
    formData.append('password', this.addForm.get('password').value);
    formData.append('sub_id', this.addForm.get('sub_id').value);
    formData.append('allow_buyer_to_pause_target', this.addForm.get('allow_buyer_to_pause_target').value);
    formData.append('allow_buyer_to_set_target_concurrency', this.addForm.get('allow_buyer_to_set_target_concurrency').value);
    formData.append('allow_buyer_to_dispute_call_conversions', this.addForm.get('allow_buyer_to_dispute_call_conversions').value);
    formData.append('limit_revenue', this.addForm.get('limit_revenue').value);

    // console.log(formData); return false;

    this.moduleservice.addBuyer(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Buyer Added Successfully');
          this.loadNumbers();
          this.Submitted = false;

        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );
   }

   closeModal() {
    if(this.addForm.get('company').value)
    {
      $("#addLabels").modal("hide");
      $(".custom-switch-input").prop('checked',false);
      this.Submitted = false;
      setTimeout(() => { this.addForm.reset();}, 2000);
    }
  }



editSubmitted = false;
//Add Buyer
get editbuyercontrol() { return this.editForm.controls; }
onEditSubmit () {
  // alert('ok');return false;
    const formData = new FormData();
    this.editSubmitted = true;
    if (this.editForm.invalid) {
        return;
    }
    this.closeEditModal();
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('user_uuid', this.currentUser.uuid);
    formData.append('uuid', this.editForm.get('uuid').value);
    formData.append('company', this.editForm.get('company').value);
    formData.append('email', this.editForm.get('email').value);
    formData.append('password', this.editForm.get('password').value);
    formData.append('sub_id', this.editForm.get('sub_id').value);
    formData.append('allow_buyer_to_pause_target', this.editForm.get('allow_buyer_to_pause_target').value);
    formData.append('allow_buyer_to_set_target_concurrency', this.editForm.get('allow_buyer_to_set_target_concurrency').value);
    formData.append('allow_buyer_to_dispute_call_conversions', this.editForm.get('allow_buyer_to_dispute_call_conversions').value);
    formData.append('limit_revenue', this.editForm.get('limit_revenue').value);
    // console.log(formData);

    this.moduleservice.editBuyer(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Buyer Updated Successfully');
          this.loadNumbers();
          this.editSubmitted = false;
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );
   }

   closeEditModal() {
    if(this.editForm.get('company').value)
    {
      $("#editLabels").modal("hide");
      this.editSubmitted = false;
      setTimeout(() => { this.editForm.reset();}, 2000);
    }
  }

}
