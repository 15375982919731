import { NgModule } from '@angular/core';
import { Routes, RouterModule,Router } from '@angular/router';
import { LoginComponuntComponent } from './login-componunt/login-componunt.component';
import { ReactiveFormsModule } from '@angular/forms';  

const routes: Routes = [
  {path:'login',component:LoginComponuntComponent},    
  ];

@NgModule({
  imports: [RouterModule.forChild(routes),ReactiveFormsModule],
  exports: [RouterModule,]
})
export class AuthRoutingModule { }
