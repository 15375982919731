import { GlobalVariable } from './../../../global';
import { AfterViewInit, ChangeDetectionStrategy,ViewChild, ElementRef, ChangeDetectorRef,Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup,FormArray,Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from './../../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { ngxCsv } from 'ngx-csv';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Chart } from 'angular-highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import * as Highcharts from 'highcharts';
HC_exporting(Highcharts);
declare var $;

class DataTablesResponse {
  data: any[];
  campaign_no: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  pendingTotal:number;
  completedTotal:number;
  pendingdata:any[];
  completed:any[];
}

@Component({
  selector: 'app-campaign-view',
  templateUrl: './campaign-view.component.html',
  styleUrls: ['./campaign-view.component.scss']
})
export class CampaignViewComponent implements OnInit {
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    @ViewChild('PerdataTable', {static: true}) Pertable: ElementRef;
    PerdataTable: any;

     numberForm: FormGroup;
     
     currentCompany: any;
     locations:any = [];
     error: {};
     showLoadingIndicator: any;
     getdata: any;
     uuid:any;
     dropdownList = [];
     selectedItems = [];
  
     private ServerUrl = GlobalVariable.BASE_API_URL;
     dtTrigger: Subject<any> = new Subject<any>();
     dtOptions: DataTables.Settings = {};
    //  dtOptionsTarget: DataTables.Settings = {};
    //  dtOptionsRouting: DataTables.Settings = {};
    //  dtOptionsPending: DataTables.Settings = {};
     dataTable: any;
    //  dataTableTarget: any;
    //  dataTablePendingReq: any;
    //  dataTableRouting: any;
     campaignpublisher: any;
     private http: HttpClient;
  
     constructor(
         private renderer: Renderer2,
         private moduleservice: ModuleService,
         private toastrService:ToastrService,
         handler: HttpBackend,
         private fb: FormBuilder,
         private router: Router,       
         private route: ActivatedRoute,
         private cdRef: ChangeDetectorRef,
         private ngxLoader: NgxUiLoaderService      
         ){
          this.http = new HttpClient(handler);
        this.uuid = this.route.snapshot.paramMap.get('id');     
     }  
   
    ngAfterViewChecked(): void {
      this.cdRef.detectChanges(); 
    }
  
     editpayout_typeval:number=0;
     isrecording:number;
     campaignNumbers: any = [];
     campaignLabels: any = [];
  
     ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
  
     today: string;
     event:any="";
     ngOnInit() {
       this.showLoadingIndicator= true;
       setTimeout(()=>{this.showLoadingIndicator= false; },1000);
       this.today = new Date().toISOString().split('T')[0];
  
       this.numberForm = this.fb.group({
        company_uuid: [''],      
        user_uuid: [''],      
        campaign_uuid: [''],      
        notype: [0],              
        prefix: [''],              
       });
  
  
       this.getcampaignbyuuid();
       
       this.loadNumbers();
      
       this.loadcalls();
       this.getChartNumberOfCalls(this.event);
       
       this.loadcampaignpublishers(this.uuid);
       this.loadparticipationrequest(this.uuid);
      
     }
    defaultconf:any; 
    getcampaignbyuuid(){
      this.moduleservice.getcampainbyuuid(this.uuid).subscribe(
        res => {
          this.getdata = res.campaigns;
          this.defaultconf = res.conf;          
        },
        error => this.error = error,
      );    
    }
  
     loadcampaignpublishers(uuid){
      // console.log('ok');
      // return false;
        if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
          $(this.table.nativeElement).dataTable().fnDestroy();
        }    
        this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
       this.dtOptions = {
          "pagingType": 'full_numbers',
          "processing": true,
          "serverSide": true,          
          "pageLength": 20,      
          "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],                  
          "order": [[ 0, "asc" ]],
          "searchDelay": 350,
          ajax: (dataTablesParameters: any, callback) => {
            this.http.post<DataTablesResponse>(
              this.ServerUrl+'getCampaignPublisher/'+uuid,
              dataTablesParameters, {}
            ).subscribe(resp => {
                      this.campaignpublisher = resp.data;   
                      callback({
                          recordsTotal: resp.recordsTotal,
                          recordsFiltered: resp.recordsFiltered,
                          data: [],
                      });
                  });
          },
          columns: [
            {data:"phoneno"},{data:"action"},
          ],
          columnDefs: [
            { 
                "targets": [0], 
                "orderable": false,
            },
            ],
      };
  
    this.dataTable = $(this.table.nativeElement);
    this.dataTable.dataTable(this.dtOptions);
  }
  
  pendingdata:any = [];
  completed:any = [];
  offerdetails:any = [];
  firstDate:any;
  secondDate:any;
  diffdays:any;
  loadparticipationrequest(uuid){
        this.ngxLoader.startLoader('loader-03');
        this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
        this.http.post<any>(
                this.ServerUrl+'getParticipationRequest/'+uuid,
                {}
              ).subscribe(resp => {
                // console.log(resp);                    
                this.offerdetails = resp.offerdetails;   
                this.firstDate = new Date(resp.offerdetails.exp_date);
                this.secondDate = new Date();
  
                const ONE_DAY = 1000 * 60 * 60 * 24;
                const differenceMs = Math.abs(this.firstDate - this.secondDate);
                this.diffdays = Math.round(differenceMs / ONE_DAY);      
            
                
                this.pendingdata = resp.data.pending ? resp.data.pending : [];   
                this.completed = resp.data.completed ? resp.data.completed : [];   
                
                this.dtTrigger.next();
                this.ngxLoader.stopLoader('loader-03');
              },
              error => this.error = error
          );        
  }
    
  
    //  public numberdata: Array<Select2OptionData>;
     public numberdata: [];   
     loadNumbers(){
       this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
       this.moduleservice.loadNumbersfordropdown(this.currentCompany.uuid).subscribe(
         resp => {
             this.numberdata = resp;
            //  console.log(this.numberdata);
           },
           error => this.error = error
       );
     }
     public options: Options;

   
   Submitted = false;

  

    filtertype:any=1;
    getType(type:any,val:any){
      if(type==1){
          $("#tabs3").removeClass("btn btn-primary btn-sm camactivetab");
          $("#tabs2").removeClass("btn btn-primary btn-sm camactivetab");
         $("#tabs1").addClass("camactivetab");this.filtertype=1;
        //  this.loadcallsdata(this.filterselected,this.selecteddate,1);
      }
      if(type==2){
        $("#tabs1").removeClass("btn btn-primary btn-sm camactivetab");
        $("#tabs3").removeClass("btn btn-primary btn-sm camactivetab");
         $("#tabs2").addClass("camactivetab");this.filtertype=2;
        //  this.loadcallsdata(this.filterselected,this.selecteddate,4);
      }
      if(type==3){
        $("#tabs1").removeClass("btn btn-primary btn-sm camactivetab");
        $("#tabs2").removeClass("btn btn-primary btn-sm camactivetab");
         $("#tabs3").addClass("camactivetab");this.filtertype=3;
        //  this.loadcallsdata(this.filterselected,this.selecteddate,4);
      }
      
    }
    
    // @ViewChild('editdiv') editdiv: ElementRef;
    // getScroll(val:any){
    //     try {
    //       const errorField = this.renderer.selectRootElement('.first-class');
    //         errorField.scrollIntoView();
    //     } catch (err) {
  
    //     }
  
    // }
  
    // pfiltertype:any=1;
    // getParticipationType(type){
    //   if(type==1){
    //     $("#tabs4").removeClass("btn btn-primary btn-sm camactivetab");
    //      $("#tabs3").addClass("camactivetab");this.pfiltertype=1;
    //     //  this.loadcallsdata(this.filterselected,this.selecteddate,1);
    //   }
    //   if(type==2){
    //     $("#tabs3").removeClass("btn btn-primary btn-sm camactivetab");
    //      $("#tabs4").addClass("camactivetab");this.pfiltertype=2;
    //     //  this.loadcallsdata(this.filterselected,this.selecteddate,4);
    //   }
      
    // }
   
    parreqdata:any;
    reqdata(details:any) {    
      this.parreqdata = details;    
    }
  
    // iscreateoffer:Boolean=false;
    // iscreateoffertoggle() {    
    //   this.iscreateoffer = !this.iscreateoffer;    
    // }
    
    // isshowmessage:Boolean=true;
    // isshowmessagetoggle() {    
    //   this.isshowmessage = !this.isshowmessage;    
    // }
  
 
  offer_hide(status: number) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    $("#addpublisher").modal("hide");         
    Swal.fire({
      text: 'Are you sure you want to Leave offer?',
      showCancelButton: true,
      confirmButtonText: "Apply",
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this.moduleservice.hideoffer(this.uuid,currentUser.uuid,status).subscribe(
            res => {
              // console.log(res);return false;              
              Swal.fire(
                'Success!',
                'Offer Leave Successfully.',
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
              this.router.navigate(['/campaigns']);
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }

  labels:any;
  loadcalls(){
    if ($.fn.DataTable.isDataTable(this.Pertable.nativeElement) ) {
      $(this.Pertable.nativeElement).dataTable().fnDestroy();
    } 
 
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var that = this;
    this.dtOptions = {
      "pagingType": 'full_numbers',
      "processing": true,
      "serverSide": true,          
      "paging":   false,
      "info":     false,
      // "lengthMenu": [[-1, 50, 100, 500, 2000, 5000], ["All", 50, 100, 500, 2000, 5000 ]],
      "pageLength": 20,
      // "ordering": false,
      "searching":false,
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
          this.ServerUrl+'getlastweekcalls/'+this.currentCompany.uuid+'/'+currentUser.timezone+'/', {'dataTablesParameters':dataTablesParameters, 'user_uuid':currentUser.uuid, 'role':currentUser.role,'campaign_uuid':this.uuid}
         , {}
        ).subscribe(resp => {
                  // console.log(resp); return false;
                  that.labels = resp.campaign_no;   
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: [],
                  });
              });
      },
      columns: [
        {data:"did"},{data:"incoming_number"},{data:"publisher"},{data:"target"},{data:"campaign"},{data:"routed_to"},{data:"routed_to"},{data:"routed_to"},
      ],
  };

  this.dataTable = $(this.Pertable.nativeElement);
  this.dataTable.dataTable(this.dtOptions);
  }  

  chart:any;
  date1:string;
  bgcolorcode:any;
  getChartNumberOfCalls(event:any){
    // console.log(event);return false;
    if(event!=''){
      this.date1 = event.endDate._d;  
    }else{
      this.date1 = '';
    }
    // console.log(this.date1);
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let params = {
      user_uuid : currentUser.uuid,
      role : currentUser.role,
      date : this.date1,
      campaign_uuid:this.uuid
    }
    this.moduleservice.getChartbyDate(this.currentCompany.uuid,params,currentUser.timezone).subscribe(
      res => {
        //  console.log(res); 
        if ($("html").hasClass("dark")) {
          this.bgcolorcode='rgb(17,19,25)';
        }
        this.chart = new Chart({
          chart: {
            // polar: true,
            type: 'line',
           
            backgroundColor:this.bgcolorcode
          },
          exporting: {
            enabled: true
          },
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },  
          xAxis: {
              minRange: 0,
              gridLineWidth: 0,
              title: {
                text: 'Hours'
              }
          },
          yAxis: {
            min:0,
            gridLineWidth: 1,
            title: {
              text: 'Number Of Calls'
            }
          },
          legend: {
            enabled: false
          },        
          plotOptions: {
            area: {
                  fillColor: {
                    linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 1
                    },
                    stops: [
                      [0, '#7cb5ec'],
                      [1, 'rgba(124,181,236,0)']
                    ]
                  },  
                  marker: {
                    radius: 2
                  },
                  lineWidth: 1,
                  states: {
                      hover: {
                          lineWidth: 1
                      }
                  },
                  threshold: null
              },
              
            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: false
            }
          },
          series: [{
            data: res,
            type: 'area',      
            name:"Call Count"
          }]
        }); 
      },
      error => this.error = error
    );  
  }
  
  closeModal(){
    if (!this.numberForm.invalid) {
      $("#addNumber").modal("hide");
    }
  }  

payout_typevalueedit:any = 0;
payout_typevaledit(val:number) {
  this.payout_typevalueedit = val;
  this.numberForm.get('notype').setValue(val); 
}

addnoSubmitted = false;   
get addnoFormcontrol() { return this.numberForm.controls; }  
onAddNoSubmit () {
  //  console.log(this.payoutForm.value);
  //  return false;
  this.addnoSubmitted = true;
  if (this.numberForm.invalid) {
      return;
  }

  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.numberForm.get('company_uuid').setValue(this.currentCompany.uuid); 
  this.numberForm.get('user_uuid').setValue(currentUser.uuid); 
  this.numberForm.get('campaign_uuid').setValue(this.uuid); 

  this.ngxLoader.startLoader('loader-04');
  this.moduleservice.purchase_number(this.uuid,this.numberForm.value).subscribe(
       res => {
         if (res.status !== 'error') {
           this.toastrService.success(res.message);  
           this.Submitted = false;          
           this.loadcampaignpublishers(this.uuid);
         }else{
           this.toastrService.error(res.message);
         }
         this.ngxLoader.stopLoader('loader-04');
       },
       error => this.error = error,
     );     
}
  


DeleteNumber(no: any,uuid: any) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to Delete Record!',
    
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      let formData = new FormData();
      formData.append('number', no);
      formData.append('number_uuid', uuid);
      this.ngxLoader.startLoader('loader-04');
      this.moduleservice.DeletePurNumber(formData).subscribe(
          res => {
            // console.log(res);return false;
            this.ngxLoader.stopLoader('loader-04');
            Swal.fire(
              'Deleted!',
              'Record Deleted Successfully.',
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);

            this.loadcampaignpublishers(this.uuid);
          },
          error => this.error = error
      );       
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your Record is safe :)',
        'error'
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}
  
  }
