import { ModuleService } from './../../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../../global';
import { Title } from '@angular/platform-browser';

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

class IVRMenus {
  id: string;  
  name: string;  
  created_at: string;      
}

@Component({
  selector: 'app-predictive-routing',
  templateUrl: './predictive-routing.component.html',
  styleUrls: ['./predictive-routing.component.scss']
})
export class PredictiveRoutingComponent {
  private ServerUrl = GlobalVariable.BASE_API_URL;

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  dataTable: any;
  ivrmenus: IVRMenus;
  action: any;
  error: {};
  showLoadingIndicator: any;
  dtOptions: DataTables.Settings = {};
  ivr_menu: IVRMenus[];
  private http: HttpClient;
  IVRMenus: any[];
  currentCompany: any;
  ischecked: string = '1';
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    handler: HttpBackend,
    private router: Router,
    private titleService: Title
  ){ 
    this.http = new HttpClient(handler);
  }
  
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {
    this.setTitle('Predictive Routing')
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.loadIVRMenus();
  }

  loadIVRMenus(){
    if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
      $(this.table.nativeElement).dataTable().fnDestroy();
    }   
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));   
    var that = this;
    this.dtOptions = {
      "pagingType": 'full_numbers',
      "processing": true,
      "serverSide": true,          
      "pageLength": 20,
      "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],   
      "order": [[ 1, "asc" ]],
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
          this.ServerUrl+'getPredictiveRouting/'+this.currentCompany.uuid,          
          dataTablesParameters, {}
        ).subscribe(resp => {
                  that.IVRMenus = resp.data;   
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: [],
                  });
              });
      },
      columns: [
        {data:"name"},{data:"created_at"},{data:"action"},
      ],
      columnDefs: [
        { 
            "targets": [0,1], 
            "orderable": false,
        },
        ],
  };

this.dataTable = $(this.table.nativeElement);
this.dataTable.dataTable(this.dtOptions);
}

checkalldelete(event) {
  const checked = event.target.checked;
  this.IVRMenus.forEach(chkbox => chkbox.selected = checked);    
}

checkboxes:any = [];  
deleteselected(event:any){ 
  // $("#customCheck1").prop("checked", false);    
  if(event.target.checked==true)
  {
    if(event.target.value=='on'){
      this.checkboxes = [];
      this.IVRMenus.forEach(lbl => {
        this.checkboxes.push(lbl.id);
      });
    }else{ 
      if(this.checkboxes.length+1==this.IVRMenus.length){
        $( "#customCheck1" ).prop( "checked", true );
      }
      this.checkboxes.push(event.target.value);            }            
      this.ischecked = '0';
  }else{ 
    // console.log(this.checkboxes);      
    if(this.checkboxes.length!=0){
      $( "#customCheck1" ).prop( "checked", false );        
    }
    if(event.target.value!='on'){
      const index = this.checkboxes.indexOf(event.target.value);
      this.checkboxes.splice(index, 1);
    }else{
      this.checkboxes = [];
    }
    this.ischecked = '1';
  }
  // console.log(this.checkboxes); 
}

onDeleteSubmit(){    
  if(this.checkboxes.length==0){
    this.toastrService.error('Please Select Row To Delete');
  }else{
    // console.log(this.checkboxes);  
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Selected Record!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.moduleservice.deleteSelectedIVR(this.checkboxes).subscribe(
          res => {
              Swal.fire(
                'Deleted!',
                'Records Deleted Successfully.',
                           
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
              this.loadIVRMenus();
            },
            error => this.error = error
        );      
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }
  
}

Delete(uuid: any) {
  // alert(uuid);return false;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Record!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.moduleservice.deletePredictiveRouting(uuid,currentUser.uuid).subscribe(
            res => {
              // console.log(res);return false;
              Swal.fire(
                'Deleted!',
                'Record Deleted Successfully.',
                           
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);

              this.loadIVRMenus();
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }


  filter = [];
  Clone(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to clone this IVR Menu!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, clone it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.moduleservice.cloneIVR(id,currentUser.uuid).subscribe(
            res => {
              this.filter = [res];
              if(this.filter[0]['status'] == 'success'){
                this.loadIVRMenus();
                Swal.fire(
                  'Success!',
                  'IVR Menu Clone Successfully.',
                             
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
                
                this.router.navigate(['/edit-ivr/'+this.filter[0]['uuid']]);
              }else{
                Swal.fire(
                  'Error',
                  'IVR Menu Clone failed.',
                  'error'
                )
              }
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          '',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }


}

