import { FormGroup } from '@angular/forms';
import { ModuleService } from './../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Title } from '@angular/platform-browser';

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


@Component({
  selector: 'app-voicemail',
  templateUrl: './voicemail.component.html',
  styleUrls: ['./voicemail.component.scss']
})
export class VoicemailComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  public SiteUrl = GlobalVariable.BASE_URL;

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  dataTable: any;
  number: Number;
  action: any;
  error: {};
  showLoadingIndicator: any;
  dtOptions: DataTables.Settings = {};
  numbers: any;
  that: Number;
  private http: HttpClient;
  currentCompany: any;
  currentUser: any;
  ischecked: string = '1';

  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    private router: Router,
    handler: HttpBackend,
    private titleService: Title
  ){ 
    this.http = new HttpClient(handler);
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {
    this.setTitle('Voicemail')
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.loadTargets();
  }
  loadTargets(){
        // console.log('ok');
        // return false;
          if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
            $(this.table.nativeElement).dataTable().fnDestroy();
          }    
          this.currentCompany = JSON.parse(localStorage.getItem('currentUser'));
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
         this.dtOptions = {
            "pagingType": 'full_numbers',
            "processing": true,
            "serverSide": true,          
            "pageLength": 20,      
            "lengthMenu": [[-1, 20, 50, 100, 500, 2000, 5000], ["All", 20, 50, 100, 500, 2000, 5000 ]],      
            "order": [[ 1, "asc" ]],
            ajax: (dataTablesParameters: any, callback) => {
              this.http.post<DataTablesResponse>(
                this.ServerUrl+'getvoicemails/'+this.currentCompany.uuid+'/'+this.currentUser.timezone,
                dataTablesParameters, {}
              ).subscribe(resp => {
                        this.numbers = resp.data;   
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: [],
                        });
              });
            },
            columns: [
              {data:"created_epoch"},{data:"cid_number"},{data:"file_path"},{data:"campaign"}
            ],            
        };
    
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.dataTable(this.dtOptions);
  }

  checkalldelete(event) {
    const checked = event.target.checked;
    this.numbers.forEach(chkbox => chkbox.selected = checked);    
  }

  checkboxes:any = [];  
  deleteselected(event:any){ 
    // $("#customCheck1").prop("checked", false);    
    if(event.target.checked==true)
    {
      if(event.target.value=='on'){
        this.checkboxes = [];
        this.numbers.forEach(lbl => {
          this.checkboxes.push(lbl.id);
        });
      }else{ 
        if(this.checkboxes.length+1==this.numbers.length){
          $( "#customCheck1" ).prop( "checked", true );
        }
        this.checkboxes.push(event.target.value);            }            
        this.ischecked = '0';
    }else{ 
      // console.log(this.checkboxes);      
      if(this.checkboxes.length!=0){
        $( "#customCheck1" ).prop( "checked", false );        
      }
      if(event.target.value!='on'){
        const index = this.checkboxes.indexOf(event.target.value);
        this.checkboxes.splice(index, 1);
      }else{
        this.checkboxes = [];
      }
      this.ischecked = '1';
    }
    // console.log(this.checkboxes); 
  }

  onDeleteSubmit(){    
    if(this.checkboxes.length==0){
      this.toastrService.error('Please Select Row To Delete');
    }else{
      // console.log(this.checkboxes);  
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Selected Record!',
        
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {

          this.moduleservice.deleteSelectedNumbers(this.checkboxes).subscribe(
            res => {
                Swal.fire(
                  'Deleted!',
                  'Records Deleted Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
                this.loadTargets();
              },
              error => this.error = error
          );      
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }
    
  }

  Delete(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Record!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.moduleservice.deleteNumber(id,currentUser.uuid).subscribe(
            res => {
              this.loadTargets();
              // this.toastrService.success('Number Deleted Successfully');
              Swal.fire(
                'Deleted!',
                'Record Deleted Successfully.',
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
              
              
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }
  
  filter = [];
  Clone(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to clone this target!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, clone it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
        this.moduleservice.cloneNumber(id,currentUser.uuid,currentCompany.uuid).subscribe(
            res => {
              this.filter = [res];
              if(this.filter[0]['status'] == 'success'){
                this.loadTargets();
                Swal.fire(
                  'Success!',
                  'Target Clone Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
                
                this.router.navigate(['/edit-numbers/'+this.filter[0]['uuid']]);
              }else{
                Swal.fire(
                  'Error',
                  'Target Clone failed.',
                  'error'
                )
              }
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          '',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }


  exportdata(){
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      // showTitle: true,
      // title: 'Your title',
      useBom: true,
      // noDownload: true,
      headers: ["Number", "FSL Rule Name", "Rule Set","Note","Associated abel","Created At"]
    };

    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getNumberForExport(this.currentCompany.uuid,"all").subscribe(
      res => {
        console.log(res);
        new   ngxCsv(res, 'All Targets', options);
      },
      error => this.error = error
  );     

  }

  status:boolean;
  change_status(event:any,tagetUuid:any){
    this.status = event.target.checked;
    let activestatus, activestring;
    if(this.status == true){
      activestatus = 1;
      activestring = "Activate";
    }else{
      activestatus = 0;
      activestring = "De-activate";
    }
    // alert(activestatus); return
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to '+activestring+' Target!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, '+activestring+' it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.moduleservice.changeTargetStatus(activestatus,currentUser.uuid,tagetUuid).subscribe(
            res => {
              this.loadTargets();
              // this.toastrService.success('Number Deleted Successfully');
              Swal.fire(
                'Changed!',
                'Target '+activestring+' Successfully.',
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
              
              
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
        this.loadTargets();
        return;
      }
    });
  }  

  play(url: string, incomingnumber:any){
         
    this.toastrService.show(`<audio style="width: 232px;height: 30px;" controls><source src="${url}" type="audio/mp3"></audio>`,
    'Callerid Number # - '+incomingnumber , {
      closeButton: true,
        extendedTimeOut: 0,
        timeOut: 0,
        
        tapToDismiss: false,
             enableHtml: true,
             positionClass: 'toast-bottom-right' ,
         });
  }
}
