import { FormGroup } from '@angular/forms';
import { ModuleService } from './../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Title } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})

export class NumberComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;
  public SiteUrl = GlobalVariable.BASE_URL;

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  dataTable: any;
  number: Number;
  action: any;
  error: {};
  showLoadingIndicator: any;
  dtOptions: DataTables.Settings = {};
  numbers: any;
  that: Number;
  private http: HttpClient;
  currentCompany: any;
  ischecked: string = '1';
  role:number;
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    private router: Router,
    handler: HttpBackend,
    private titleService: Title,
    private ngxLoader: NgxUiLoaderService,

  ){
    this.http = new HttpClient(handler);
    this.role =JSON.parse(localStorage.getItem('currentUser')).role;
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {
    this.setTitle('Manage Targets')
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.loadTargets();
  }
  loadTargets(){
        // console.log('ok');
        // return false;
      
        
          if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
            $(this.table.nativeElement).dataTable().fnDestroy();
          }
          this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          let company_uuid = currentUser?.companydetails.map((item)=>item.user_uuid);
          // console.log(company_uuid);
          
         this.dtOptions = {
            "pagingType": 'full_numbers',
            "processing": true,
            "serverSide": true,
            "pageLength": 20,
            "lengthMenu": [[-1, 20, 50, 100, 500, 2000, 5000], ["All", 20, 50, 100, 500, 2000, 5000 ]],
            "order": [[ 1, "asc" ]],
            ajax: (dataTablesParameters: any, callback) => {
              this.http.post<DataTablesResponse>(
                this.ServerUrl+'gettargets/'+this.currentCompany.uuid,
                {'dataTablesParameters':dataTablesParameters, 'user_uuid':currentUser.uuid, 'role':currentUser.role,'companies':company_uuid}, {}
              ).subscribe(resp => {
                        this.numbers = resp.data;
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: [],
                        });
              });
            },
            columns: [
              // {data:"checkall"},{data:"details"},,{data:"is_ringtree"}
              {data:"name"},{data:"subid"},{data:"valtype"},{data:"typeval"},{data:"hour"},{data:"day"},{data:"month"},{data:"total"},{data:"created_at"},{data:"active_status"},{data:"action"},
            ],
            columnDefs: [
              {
                  "targets": [9,10],
                  "orderable": false,
              },
              ],
        };

      this.dataTable = $(this.table.nativeElement);
      this.dataTable.dataTable(this.dtOptions);
  }

  checkalldelete(event) {
    const checked = event.target.checked;
    this.numbers.forEach(chkbox => chkbox.selected = checked);
  }

  checkboxes:any = [];
  deleteselected(event:any){
    // $("#customCheck1").prop("checked", false);
    if(event.target.checked==true)
    {
      if(event.target.value=='on'){
        this.checkboxes = [];
        this.numbers.forEach(lbl => {
          this.checkboxes.push(lbl.id);
        });
      }else{
        if(this.checkboxes.length+1==this.numbers.length){
          $( "#customCheck1" ).prop( "checked", true );
        }
        this.checkboxes.push(event.target.value);            }
        this.ischecked = '0';
    }else{
      // console.log(this.checkboxes);
      if(this.checkboxes.length!=0){
        $( "#customCheck1" ).prop( "checked", false );
      }
      if(event.target.value!='on'){
        const index = this.checkboxes.indexOf(event.target.value);
        this.checkboxes.splice(index, 1);
      }else{
        this.checkboxes = [];
      }
      this.ischecked = '1';
    }
    // console.log(this.checkboxes);
  }

  onDeleteSubmit(){
    if(this.checkboxes.length==0){
      this.toastrService.error('Please Select Row To Delete');
    }else{
      // console.log(this.checkboxes);
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Selected Record!',

        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {

          this.moduleservice.deleteSelectedNumbers(this.checkboxes).subscribe(
            res => {
                Swal.fire(
                  'Deleted!',
                  'Records Deleted Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
                this.loadTargets();
              },
              error => this.error = error
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }

  }

  Delete(id: string, type:any, number: any,company_uuid:any) {
    // console.log(type);

    if(type=='Number'){
      let no = (number).replace(/[^\d]/g, '')
      this.moduleservice.getNumberdatabyno(no,company_uuid,1).subscribe(
        res => {
      this.ngxLoader.stopLoader('loader-01');
      Swal.fire({
        title: 'Are you sure?',
        html: `Hey! You're trying to delete a number that has received <span class="text-danger"><b>${res.allcalls}</b></span> calls in the past <span class="text-success"><b>7 days</b></span>, are you sure you want to delete this?`,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.moduleservice.deleteNumber(id,currentUser.uuid).subscribe(
              res => {
                this.loadTargets();
                // this.toastrService.success('Number Deleted Successfully');
                Swal.fire(
                  'Deleted!',
                  'Record Deleted Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);


              },
              error => this.error = error
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
      });
    }else{
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Record!',

        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.moduleservice.deleteNumber(id,currentUser.uuid).subscribe(
              res => {
                this.loadTargets();
                // this.toastrService.success('Number Deleted Successfully');
                Swal.fire(
                  'Deleted!',
                  'Record Deleted Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);


              },
              error => this.error = error
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }
  }

  filter = [];
  Clone(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to clone this target!',

      showCancelButton: true,
      confirmButtonText: 'Yes, clone it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
        // console.log(id,currentUser.uuid,currentCompany.uuid); return
        
        this.moduleservice.cloneNumber(id,currentUser.uuid,currentCompany.uuid).subscribe(
            res => {
              this.filter = [res];
              if(this.filter[0]['status'] == 'success'){
                this.loadTargets();
                Swal.fire(
                  'Success!',
                  'Target Clone Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);

                this.router.navigate(['/edit-numbers/'+this.filter[0]['uuid']]);
              }else{
                Swal.fire(
                  'Error',
                  'Target Clone failed.',
                )
              }
            },
            error => this.error = error
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          '',
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }


  exportdata(){
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      // showTitle: true,
      // title: 'Your title',
      useBom: true,
      // noDownload: true,
      headers: ["Number", "FSL Rule Name", "Rule Set","Note","Associated abel","Created At"]
    };

    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getNumberForExport(this.currentCompany.uuid,"all").subscribe(
      res => {
        console.log(res);
        new   ngxCsv(res, 'All Targets', options);
      },
      error => this.error = error
  );

  }

  status:boolean;
  change_status(event:any,tagetUuid:any){
    this.status = event.target.checked;
    let activestatus, activestring;
    if(this.status == true){
      activestatus = 1;
      activestring = "Activate";
    }else{
      activestatus = 0;
      activestring = "De-activate";
    }
    // alert(activestatus); return
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to '+activestring+' Target!',

      showCancelButton: true,
      confirmButtonText: 'Yes, '+activestring+' it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.moduleservice.changeTargetStatus(activestatus,currentUser.uuid,tagetUuid).subscribe(
            res => {
              this.loadTargets();
              // this.toastrService.success('Number Deleted Successfully');
              Swal.fire(
                'Changed!',
                'Target '+activestring+' Successfully.',
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);


            },
            error => this.error = error
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
        this.loadTargets();
        return;
      }
    });
  }

  closeref(i:any){
    $(".appendedtbl_"+i).remove();
    $(".detailrow_"+i).removeClass('shown');
  }

  details(i,data){

    var tr = $('details-control').closest('tr').eq(i);
    // var divClass = $(".detailrow_"+i).eq(0).attr('class');
    var divClass1 = $('#home_'+i).attr('class');
    $('.appendedtable').remove();
    // console.log(divClass1);
    // if (divClass.indexOf('shown') > -1) {
    if (divClass1!=undefined) {
        $(".appendedtbl_"+i).remove();
          $(".detailrow_"+i).removeClass('shown');
      }else {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.moduleservice.getTagetdetailsdata(data.uuid,currentUser.uuid).subscribe(
          res => {
            var SiteUrl = this.SiteUrl;
            var campaigns ='';
            var campaignsdata ='<div class="">'+
            '<div class="mt-2 mb-2">'+
            '<div class="row" style="border-bottom: 1px solid #403f3f;">'+
            '<div class="col-md-2">'+
            '<h6 style="font-size:13px;padding: 2px;">Target Reference</h6>'+
            '</div>'+
            '<div class="col-md-9"></div>'+
            '<div class="col-md-1 text-right">'+
            // '<button class="btn btn-sm btn-danger" onClick="closeref('+i+')">Close</button>'+
            '</div>'+
            '</div>'+
            '</div>'+
            '<table class="table  table-striped targetreferencetable">'+
            '<tr style="background: #252d48!important;"><th>Type</th>'+
            '<th>Name</th>'+
            '<th>Reference</th></tr>';
            if(res.data.length>0){
                for (let index = 0; index < res.data.length; index++) {
                  const data =  res.data[index];

                  // var count = index+1;
                  campaignsdata +=`<tr style="background:#1F263D!important">
                    <td>Campaign Routing</td>
                    <td><a class="expandlink" style="color:white;text-decoration:underline" target="_blank" href="${SiteUrl}edit-campaign/${data.uuid}">${data.name}</a></td>
                    <td>${data.uuid}</td>
                  </tr>`;
                          // '<div class="mb-2">'+
                          //   '<span class="text-job text-primary">Assigned Campaign - '+count+'</span>'+'</div>'+
                          //   '<div class="row">'+
                          //     '<div class="col-md-2">Campaign Name</div>'+
                          //     '<div class="col-md-3"><a class="expandlink" style="color:white;text-decoration:underline" target="_blank" href="'+SiteUrl+'/edit-campaign/'+data.uuid+'">'+data.name+'</a></div>'+
                          //   '</div>'+
                          //   '<div class="row">'+
                          //     '<div class="col-md-2">Country</div>'+
                          //     '<div class="col-md-3">'+

                          //     data.country+'</div>'+
                          //   '</div>'+

                }
            }
            // else{
            //   campaignsdata +=`<tr>
            //   <td colspan="3">No Campaign Assigned</td>
            // </tr>`;
            //   // campaignsdata +='<div class="activity">'+
            //   //               '<div class="activity-icon bg-blue text-white">'+
            //   //               '<i class="fas fa-info"></i>'+
            //   //             '</div>'+
            //   //             '<div class="activity-detail">'+
            //   //               '<div class="mb-2">'+
            //   //                 '<span class="text-job text-primary">No Campaign Assigned</span>'+'</div>'+
            //   //             '</div>'+
            //   //           '</div>';
            // }



          // console.log(res.data1);

          if(res.data1.length>0){
                for (let index = 0; index < res.data1.length; index++) {
                  const data1 =  res.data1[index];

                  // var count = index+1;
                  campaignsdata +=`<tr>
                    <td>IVR Routing</td>
                    <td><a class="expandlink" style="color:white;text-decoration:underline" target="_blank" href="${SiteUrl}edit-ivr/${data1.uuid}">${data1.name}</a></td>
                    <td>${data1.uuid}</td>
                  </tr>`;
                }
            }

            if(res.data1.length==0 && res.data.length==0){
              campaignsdata +=`<tr>
                <td colspan="3" class="text-center">This target does not have any references</td>
              </tr>`;
            }

            campaignsdata +='</th>'+
            '</div>';
          '</div>';


            // if(res.data1.length>0){
            //   for (let index = 0; index < res.data1.length; index++) {
            //     const data1 =  res.data1[index];

            //     var count = index+1;
            //     campaignsdata +='<div class="activity">'+
            //             '<div class="activity-icon bg-blue text-white">'+
            //             '<i class="fas fa-info"></i>'+
            //           '</div>'+
            //           '<div class="activity-detail">'+
            //             '<div class="mb-2">'+
            //               '<span class="text-job text-primary">Assigned IVR</span>'+'</div>'+
            //               '<div class="row">'+
            //                 '<div class="col-md-2">IVR Name</div>'+
            //                 '<div class="col-md-3"><a class="expandlink" style="color:white;text-decoration:underline" target="_blank" href="'+SiteUrl+'/edit-ivr/'+data1.uuid+'">'+data1.name+'</a></div>'+
            //               '</div>'+
            //           '</div>'+
            //         '</div>';
            //   }
            //   }else{
            //     campaignsdata +='<div class="activity">'+
            //                   '<div class="activity-icon bg-blue text-white">'+
            //                   '<i class="fas fa-info"></i>'+
            //                 '</div>'+
            //                 '<div class="activity-detail">'+
            //                   '<div class="mb-2">'+
            //                     '<span class="text-job text-primary">No IVR Assigned</span>'+'</div>'+
            //                 '</div>'+
            //               '</div>';



            //   }

            campaignsdata +='</th>'+
            '</div>';
          '</div>';

            campaigns += campaigns.concat(campaignsdata);
            // console.log(campaigns);

        $(".detailrow_"+i).after(
          '<div class="appendedtable appendedtbl_'+i+'">'+
          // '<ul class="nav nav-tabs expandtabs">'+
          //   '<li id="eventtgl" style="padding: 6px 6px 0px 5px;border-radius: 3px;border: 1px solid #747e9a;margin-right: 2px;    margin-bottom: 3px;" class="active"><a class="active" data-toggle="tab" href="#home_'+i+'">Event</a></li>'+
            // '<li style="padding: 6px 6px 0px 5px;border-radius: 3px;border: 1px solid #747e9a;margin-right: 2px;    margin-bottom: 3px;"><a  data-toggle="tab" href="#menu_'+i+'">Record</a></li>'+
            // '<li style="padding: 6px 6px 0px 5px;border-radius: 3px;border: 1px solid #747e9a;margin-right: 2px;    margin-bottom: 3px;"><a data-toggle="tab" href="#tag_'+i+'">Tag</a></li>'+
          // '</ul>'+

          '<div class="tab-content">'+
            '<div id="home_'+i+'" class="tab-pane fade in active show">'+
              '<section class="section" style="width: 0px;">'+
              '<div class="section-body">'+

                    '<div class="activities1" style="display: block;width: 1553px!important;">'+
                    // '<div class="activity">'+
                    //   '<div class="activity-icon bg-blue text-white">'+
                    //     '<i class="fas fa-info"></i>'+
                    //   '</div>'+
                    //   '<div class="activity-detail">'+
                    //     '<div class="mb-2">'+
                    //       '<span class="text-job text-primary">Assigned Campaigns</span>'+'</div>'+
                    //       '<div class="row">'+
                    //         '<div class="col-md-1">Campaign Name</div>'+
                    //         '<div class="col-md-3">'+name+'</div>'+
                    //       '</div>'+
                    //       '<div class="row">'+
                    //         '<div class="col-md-1">Country</div>'+
                    //         '<div class="col-md-3">'+

                    //         country+'</div>'+
                    //       '</div>'+
                    //   '</div>'+
                    // '</div>'+

                    campaigns+


                    '</span>'+

              '</div>'+
            '</section> '+
            '</div>'+

        '</div>'+
        '</div>'
          );
          tr.addClass('shown');
        },
        error => this.error = error
    );
      }

}
}
