export const GlobalVariable = Object.freeze({
    // BASE_API_URL: 'https://devapi.dialdigits.com/',       
    // BASE_URL: 'https://dev.dialdigits.com/#/',

    BASE_API_URL: 'https://apiv2.dialdigits.com/',       
    BASE_URL: 'https://app.dialdigits.com/#/',

    // BASE_URL: 'http://localhost:4200/#/',    
    // BASE_API_URL: 'http://localhost/DDV2API/',   
    
    // BASE_API_URL: 'http://testapi.dialdigits.com/',       
    // BASE_URL: 'https://testapp.dialdigits.com/#/'
});
    