import { FormGroup } from '@angular/forms';
import { ModuleService } from './../module.service';
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Title } from '@angular/platform-browser';
declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
  
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    dataTable: any;
    number: Number;
    action: any;
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {};
    numbers: any;
    that: Number;
    private http: HttpClient;
    currentCompany: any;
    ischecked: string = '1';
  
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private titleService: Title
    ){ 
      this.http = new HttpClient(handler);
    }

    public setTitle(newTitle: string) {
      this.titleService.setTitle(newTitle);
    }
  
    ngOnInit() {
      this.setTitle('Change Logs')
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.loadChangeLogs();
    }
    loadChangeLogs(){
          // console.log('ok');
          // return false;
            if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
              $(this.table.nativeElement).dataTable().fnDestroy();
            }    
            this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
           this.dtOptions = {
              "pagingType": 'full_numbers',
              "processing": true,
              "serverSide": true,          
              "lengthMenu": [[-1, 50, 100, 200, 500, 1000], ["All", 50, 100, 200, 500, 1000]],  
              "pageLength": 200,                        
              "order": [[ 1, "desc" ]],
              ajax: (dataTablesParameters: any, callback) => {
                this.http.post<DataTablesResponse>(
                  this.ServerUrl+'getchangelog/'+this.currentCompany.uuid,
                  dataTablesParameters, {}
                ).subscribe(resp => {
                          this.numbers = resp.data;   
                          callback({
                              recordsTotal: resp.recordsTotal,
                              recordsFiltered: resp.recordsFiltered,
                              data: [],
                          });
                });
              },
              columns: [
                {data:"user"},{data:"modulename"},{data:"operation_type"},{data:"description_string"},{data:"created_at"}
              ],
              // columnDefs: [
              //   { 
              //       "targets": [0], 
              //       "orderable": false,
              //   },
              //   ],
          };
      
        this.dataTable = $(this.table.nativeElement);
        this.dataTable.dataTable(this.dtOptions);
    }
  
    // checkalldelete(event) {
    //   const checked = event.target.checked;
    //   this.numbers.forEach(chkbox => chkbox.selected = checked);    
    // }
  
    // checkboxes:any = [];  
    // deleteselected(event:any){ 
    //   // $("#customCheck1").prop("checked", false);    
    //   if(event.target.checked==true)
    //   {
    //     if(event.target.value=='on'){
    //       this.checkboxes = [];
    //       this.numbers.forEach(lbl => {
    //         this.checkboxes.push(lbl.id);
    //       });
    //     }else{ 
    //       if(this.checkboxes.length+1==this.numbers.length){
    //         $( "#customCheck1" ).prop( "checked", true );
    //       }
    //       this.checkboxes.push(event.target.value);            }            
    //       this.ischecked = '0';
    //   }else{ 
    //     // console.log(this.checkboxes);      
    //     if(this.checkboxes.length!=0){
    //       $( "#customCheck1" ).prop( "checked", false );        
    //     }
    //     if(event.target.value!='on'){
    //       const index = this.checkboxes.indexOf(event.target.value);
    //       this.checkboxes.splice(index, 1);
    //     }else{
    //       this.checkboxes = [];
    //     }
    //     this.ischecked = '1';
    //   }
    //   // console.log(this.checkboxes); 
    // }
  
    // onDeleteSubmit(){    
    //   if(this.checkboxes.length==0){
    //     this.toastrService.error('Please Select Row To Delete');
    //   }else{
    //     // console.log(this.checkboxes);  
    //     Swal.fire({
    //       title: 'Are you sure?',
    //       text: 'You want to Delete Selected Record!',
    //       
    //       showCancelButton: true,
    //       confirmButtonText: 'Yes, delete it!',
    //       cancelButtonText: 'No, keep it'
    //     }).then((result) => {
    //       if (result.value) {
  
    //         this.moduleservice.deleteSelectedNumbers(this.checkboxes).subscribe(
    //           res => {
    //               Swal.fire(
    //                 'Deleted!',
    //                 'Records Deleted Successfully.',
    //                 'success',                
    //               )
    //               setTimeout(() => {
    //                 Swal.close()
    //               }, 2000);
    //               this.loadChangeLogs();
    //             },
    //             error => this.error = error
    //         );      
    //       } else if (result.dismiss === Swal.DismissReason.cancel) {
    //         Swal.fire(
    //           'Cancelled',
    //           'Your Record is safe :)',
    //           'error'
    //         )
    //         setTimeout(() => {
    //           Swal.close()
    //         }, 2000);
    //       }
    //     });
    //   }
      
    // }
  
    // Delete(id: string) {
    //   Swal.fire({
    //     title: 'Are you sure?',
    //     text: 'You want to Delete Record!',
    //     
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, delete it!',
    //     cancelButtonText: 'No, keep it'
    //   }).then((result) => {
    //     if (result.value) {
    //       this.moduleservice.deleteNumber(id).subscribe(
    //           res => {
    //             this.loadChangeLogs();
    //             // this.toastrService.success('Number Deleted Successfully');
    //             Swal.fire(
    //               'Deleted!',
    //               'Record Deleted Successfully.',
    //               'success',                
    //             )
    //             setTimeout(() => {
    //               Swal.close()
    //             }, 2000);
                
                
    //           },
    //           error => this.error = error
    //       );       
    //     } else if (result.dismiss === Swal.DismissReason.cancel) {
    //       Swal.fire(
    //         'Cancelled',
    //         'Your Record is safe :)',
    //         'error'
    //       )
    //       setTimeout(() => {
    //         Swal.close()
    //       }, 2000);
    //     }
    //   });
    // }
  
  
    // exportdata(){
    //   var options = { 
    //     fieldSeparator: ',',
    //     quoteStrings: '"',
    //     decimalseparator: '.',
    //     showLabels: true, 
    //     // showTitle: true,
    //     // title: 'Your title',
    //     useBom: true,
    //     // noDownload: true,
    //     headers: ["Number", "FSL Rule Name", "Rule Set","Note","Associated abel","Created At"]
    //   };
  
    //   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    //   this.moduleservice.getNumberForExport(this.currentCompany.uuid,"all").subscribe(
    //     res => {
    //       console.log(res);
    //       new   ngxCsv(res, 'All Targets', options);
    //     },
    //     error => this.error = error
    // );     
  
    // }
  }