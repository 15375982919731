import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModuleService } from './../../module.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit,ChangeDetectorRef} from '@angular/core';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from './../../../global';
declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-number-pool',
  templateUrl: './number-pool.component.html',
  styleUrls: ['./number-pool.component.scss']
})

export class NumberPoolComponent implements OnInit {
    private ServerUrl = GlobalVariable.BASE_API_URL;
  
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    dataTable: any;
    number: Number;
    action: any;
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {};
    numbers: any;
    that: Number;
    private http: HttpClient;
    currentCompany: any;
    ischecked: string = '1';
    public publishers: any;
    addForm: FormGroup;
    editForm: FormGroup;
    
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
      private router: Router,
      private cdRef: ChangeDetectorRef   
    ){ 
      this.http = new HttpClient(handler);
    }
  
    ngAfterViewChecked(): void {
      this.cdRef.detectChanges(); 
    }
  
    ngOnInit() {
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.loadNumberpools();
  
      this.addForm = this.fb.group({
        country_id : ['', Validators.required],
        name: ['', Validators.required],      
        closed_browser_delay: ['', Validators.required],      
        browser_idle_limit: ['', Validators.required],      
        poolsize: ['', Validators.required],      
        allocating: [''],                     
        user_uuid: [''],                     
      });
      this.addForm.patchValue({
        country:'United States',
        prefix:'+1'
      });
    
      this.editForm = this.fb.group({
        uuid : [''],
        country_id : ['', Validators.required],
        name: ['', Validators.required],      
        closed_browser_delay: ['', Validators.required],      
        browser_idle_limit: ['', Validators.required],      
        poolsize: ['', Validators.required],      
        allocating: [''],            
        user_uuid: [''],                     
      });
  
    }
  
    loadNumberpools(){
          // console.log('ok');
          // return false;
            if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
              $(this.table.nativeElement).dataTable().fnDestroy();
            }    
           this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
           this.dtOptions = {
              "pagingType": 'full_numbers',
              "processing": true,
              "serverSide": true,          
              "pageLength": 5000,        
              "lengthMenu": [50,100,500,1000,2000,5000],
              "order": [[ 1, "asc" ]],
              ajax: (dataTablesParameters: any, callback) => {
                // this.http.post<DataTablesResponse>(
                //   this.ServerUrl+'getnumbersdata/'+this.currentCompany.uuid,
                //   dataTablesParameters, {}
                this.http.post<DataTablesResponse>(
                  this.ServerUrl+'getnumberpoolsdata/'+this.currentCompany.uuid, {'dataTablesParameters':dataTablesParameters}
                 , {}
                ).subscribe(resp => {
                          this.numbers = resp.data;   
                          callback({
                              recordsTotal: resp.recordsTotal,
                              recordsFiltered: resp.recordsFiltered,
                              data: [],
                          });
                      });
              },
              columns: [
                {data:"checkall"},{data:"name"},{data:"country_id"},{data:"closed_browser_delay"},{data:"browser_idle_limit"},{data:"poolsize"},{data:"allocating"},{data:"action"},
              ],
              columnDefs: [
                { 
                    "targets": [0], 
                    "orderable": false,
                },
                ],
          };
      
        this.dataTable = $(this.table.nativeElement);
        this.dataTable.dataTable(this.dtOptions);
    }
  
  
    numberTypeadd:any;
    labels: any = [];
    editdata(uuid:string,name:string,country_id:string,closed_browser_delay:string,browser_idle_limit:string,poolsize:string,allocating:string){
    // alert(publisher);
        this.numberTypeadd = allocating;
        this.editForm.patchValue({
        uuid : uuid,
        name : name,
        country_id : country_id,
        closed_browser_delay: closed_browser_delay,      
        browser_idle_limit: browser_idle_limit,      
        poolsize: poolsize,      
        allocating: allocating,      
      });    
    }
  
    checkalldelete(event) {
      const checked = event.target.checked;
      this.numbers.forEach(chkbox => chkbox.selected = checked);    
    }
  
    checkboxes:any = [];  
    selectednumber:any = [];  
    deleteselected(event:any){ 
      // $("#customCheck1").prop("checked", false);    
      if(event.target.checked==true)
      {
        if(event.target.value=='on'){
          this.checkboxes = [];
          this.selectednumber = [];
          this.numbers.forEach(lbl => {
            this.checkboxes.push(lbl.id);
            this.selectednumber.push(lbl.number);
          });
        }else{ 
          if(this.checkboxes.length+1==this.numbers.length){
            $( "#customCheck1" ).prop( "checked", true );
          }
          this.checkboxes.push(event.target.value);  
          this.numbers.forEach(lbl => {
            if(event.target.value == lbl.id){
            this.selectednumber.push(lbl.number);}
          }); 
          // this.selectednumber.push(event.target.value);        
          }            
          this.ischecked = '0';
      }else{ 
        // console.log(this.ischecked); 
        if(this.checkboxes.length!=0){
          $( "#customCheck1" ).prop( "checked", false );             
        }
        if(event.target.value!='on'){
          const index = this.checkboxes.indexOf(event.target.value);
          this.checkboxes.splice(index, 1);
          this.selectednumber.splice(index, 1); 
          // console.log('123='+this.selectednumber.length);
          if(this.selectednumber.length==0){   
          this.ischecked = '1';
          }
        }else{
          this.checkboxes = [];
          this.selectednumber = [];
          // console.log(this.selectednumber.length);
          if(this.selectednumber.length==0){
            this.ischecked = '1';  
          }
        }
        
      }
      // console.log(this.ischecked); 
    }
  
    onDeleteSubmit(){    
      if(this.checkboxes.length==0){
        this.toastrService.error('Please Select Row To Delete');
      }else{
        // console.log(this.checkboxes);  
        Swal.fire({
          title: 'Are you sure?',
          text: 'You want to Delete Selected Record!',
          
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
  
            this.moduleservice.deleteSelectedNumbers(this.checkboxes).subscribe(
              res => {
                  Swal.fire(
                    'Deleted!',
                    'Records Deleted Successfully.',
                  )
                  setTimeout(() => {
                    Swal.close()
                  }, 2000);
                  this.loadNumberpools();
                },
                error => this.error = error
            );      
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your Record is safe :)',
              'error'
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);
          }
        });
      }
      
    }
  
    Delete(uuid: any) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Record!',
        
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.moduleservice.deleteNumberpooldata(uuid,currentUser.uuid).subscribe(
              res => {
                // console.log(res);return false;
                this.loadNumberpools();
                // this.toastrService.success('Number Deleted Successfully');
                Swal.fire(
                  'Deleted!',
                  'Record Deleted Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);
                
                
              },
              error => this.error = error
          );       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }
  
  
  Submitted = false;
  //Add Publisher
  get addformcontrol() { return this.addForm.controls; }  
  onSubmit () {
      const formData = new FormData();      
      this.Submitted = true;
      if (this.addForm.invalid) {
          return;
      }
      
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      formData.append('user_uuid', currentUser.uuid);
      formData.append('company_uuid', this.currentCompany.uuid);
      formData.append('country_id', this.addForm.get('country_id').value);
      formData.append('name', this.addForm.get('name').value);    
      formData.append('closed_browser_delay', this.addForm.get('closed_browser_delay').value);
      formData.append('browser_idle_limit', this.addForm.get('browser_idle_limit').value);
      formData.append('poolsize', this.addForm.get('poolsize').value);
      formData.append('allocating', this.addForm.get('allocating').value);
      this.moduleservice.addNumberpooldata(formData).subscribe(
        res => {
          // console.log(res.status); return false;
          if (res.status == 'error') {
            this.toastrService.error('Failed To Add Number Pool');
          }else{
            this.toastrService.success('Number Pool Added Successfully');  
            this.loadNumberpools();
            this.Submitted = false;          
          }
        },
        error => this.error = error,
      );     
  }

  numberTypeval(val:number) {
    this.addForm.get('allocating').setValue(val); 
  } 
  
  editSubmitted = false;
  //Add Buyer
  get editFormcontrol() { return this.editForm.controls; }  
  onEditSubmit () {
      // this.editForm.get('assosciated_labels').setValue(JSON.stringify(this.labels)); 
      // alert('ok');return false;
      const formData = new FormData();      
      this.editSubmitted = true;
      if (this.editForm.invalid) {
          return;
      }
      // console.log(this.labels);return false;
     
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      formData.append('user_uuid', currentUser.uuid);
      formData.append('company_uuid', this.currentCompany.uuid);
      formData.append('uuid', this.editForm.get('uuid').value);
      formData.append('country_id', this.editForm.get('country_id').value);
      formData.append('name', this.editForm.get('name').value);    
      formData.append('closed_browser_delay', this.editForm.get('closed_browser_delay').value);
      formData.append('browser_idle_limit', this.editForm.get('browser_idle_limit').value);
      formData.append('poolsize', this.editForm.get('poolsize').value);
      formData.append('allocating', this.editForm.get('allocating').value);
    
      this.moduleservice.editNumberpooldata(formData).subscribe(
        res => {
          // console.log(res); return false;
          if (res.status !== 'error') {
            this.toastrService.success('Number Pool Updated Successfully');  
            this.loadNumberpools(); 
            this.editSubmitted = false;       
          }else{
            this.toastrService.error('Failed To Add Label');
          }
        },
        error => this.error = error,
      );     
     }
  
  
    closeModal() {    
      // console.log(this.addForm.get('country').value);return false;
      if(!this.addForm.invalid) {
        $("#addModel").modal("hide");         
        // $(".custom-switch-input").prop('checked',false);
        this.Submitted = false;
        setTimeout(() => { this.addForm.reset();}, 2000);    
      }
    }
    closeEditModal() {    
      if(!this.editForm.invalid) {
       $("#editModel").modal("hide");         
        this.editSubmitted = false;
        setTimeout(() => { this.editForm.reset();}, 2000);    
      }
    }
   
  
  }
  