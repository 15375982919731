import { GlobalVariable } from './../../../../global';
import { ViewChild, ElementRef, ChangeDetectorRef,Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup,Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from './../../../module.service';
import { HttpClient, HttpBackend} from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
declare var $;
import { Title } from '@angular/platform-browser';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


@Component({
  selector: 'app-edit-plan',
  templateUrl: './edit-plan.component.html',
  styleUrls: ['./edit-plan.component.scss']
})
export class EditPlanComponent implements OnInit {

    @ViewChild('dataTable', {static: true}) table: ElementRef;
    @ViewChild('dataTableTarget', {static: true}) tabletarget: ElementRef;
    @ViewChild('dataTableRouting', {static: true}) tablerouting: ElementRef;
    
     editForm: FormGroup;
     payoutForm: FormGroup;
     publishtertocampaignForm: FormGroup;
     editRoutingForm: FormGroup;
     currentCompany: any;
     locations:any = [];
     error: {};
     showLoadingIndicator: any;
     getdata: any;
     uuid:any;
     dropdownList = [];
     selectedItems = [];
     dropdownSettings = {};
     pubdropdownSettings = {};
     labeldropdownSettings = {};
  
     private ServerUrl = GlobalVariable.BASE_API_URL;
     
     dtOptions: DataTables.Settings = {};
    dtOptionsTarget: DataTables.Settings = {};
    dtOptionsRouting: DataTables.Settings = {};

     dataTable: any;
     dataTableTarget: any;
     dataTableRouting: any;
     campaignpublisher: any;
     private http: HttpClient;
  
     constructor(
         private renderer: Renderer2,
         private moduleservice: ModuleService,
         private toastrService:ToastrService,
         handler: HttpBackend,
         private fb: FormBuilder,
         private router: Router,       
         private route: ActivatedRoute,
         private cdRef: ChangeDetectorRef,
         private titleService: Title   
         ){
          this.http = new HttpClient(handler);
        this.uuid = this.route.snapshot.paramMap.get('id');     
     }  
   
    ngAfterViewChecked(): void {
      this.cdRef.detectChanges(); 
    }
  
public setTitle(newTitle: string) {
  this.titleService.setTitle(newTitle);
}
     addedname:any;
     ngOnInit() {
       this.showLoadingIndicator= true;
       setTimeout(()=>{this.showLoadingIndicator= false; },1000);
       
       this.uuid = this.route.snapshot.paramMap.get('id');     
       this.moduleservice.getplanbyuuid(this.uuid).subscribe(
        res => {   
          this.setTitle(res.plan_name+' | IVR Routing Plan')       
          this.editForm.patchValue({
            name : res.plan_name
          });
          this.addedname = res.plan_name;
        });
      //  this.getAlltarget();
       this.loadalltarget();
       this.loadallRoutingplan(this.uuid);
      
       this.editRoutingForm = this.fb.group({
        user_uuid: [''], 
        uuid: [''],      
        priority: [''],      
        weight: [''],      
        conversation_revenue: [''],      
        conversation_event: [''],      
        conversation_seconds: [''],            
        restrictdup: [''],            
        restrictafter: [''],            
        days: [''],            
        settingToggleDurationval: [''],            
        settingToggleDuration: [''],            
       });
  
       this.editForm = this.fb.group({
         name : ['', Validators.required],
         user_uuid: [''],             
       });       
     }
  
  allTargets:any
  loadalltarget(){
    let type = 1;
    if ($.fn.DataTable.isDataTable(this.tabletarget.nativeElement) ) {
      $(this.tabletarget.nativeElement).dataTable().fnDestroy();
     }    
     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     let currentUser = JSON.parse(localStorage.getItem('currentUser'));
     this.dtOptionsTarget = {
        "pagingType": 'full_numbers',
        "processing": true,
        "serverSide": true,          
        "pageLength": 20,      
        "lengthMenu": [[10,20,50,100, "All"], [10,20,50,100, "All"]],
        "order": [[ 0, "asc" ]],
        ajax: (dataTablesParameters: any, callback) => {
          this.http.post<DataTablesResponse>(
            this.ServerUrl+'gettargets/'+this.currentCompany.uuid+'/'+this.uuid+'/'+type,
            {'dataTablesParameters':dataTablesParameters, 'user_uuid':currentUser.uuid, 'role':currentUser.role}, {}
          ).subscribe(resp => {
                    this.allTargets = resp.data;   
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: [],
                    });
                });
        },
        columns: [
          {data:"name"},{data:"typeval"},{data:"action"},
        ],
        columnDefs: [
          { 
              "targets": [0], 
              "orderable": false,
          },
          ],
    };
  
  this.dataTableTarget = $(this.tabletarget.nativeElement);
  this.dataTableTarget.dataTable(this.dtOptionsTarget);
  }
  
  allRoutingPlan:any
  loadallRoutingplan(uuid){
     if ($.fn.DataTable.isDataTable(this.tablerouting.nativeElement) ) {
      $(this.tablerouting.nativeElement).dataTable().fnDestroy();
     }    
  
     this.dtOptionsRouting = {
        "pagingType": 'full_numbers',
        "processing": true,
        "serverSide": true,     
        "pageLength": 20,           
        "lengthMenu": [[10,20,50,100, "All"], [10,20,50,100, "All"]], 
        "order": [[ 0, "asc" ]],
        "searching": false,
        ajax: (dataTablesParameters: any, callback) => {
          this.http.post<DataTablesResponse>(
            this.ServerUrl+'getallIVRRoutingplan/'+uuid,
            dataTablesParameters, {}
          ).subscribe(resp => {
                    this.allRoutingPlan = resp.data;   
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: [],
                    });
                });
        },
        columns: [
          {data:"prority"},{data:"name"},{data:"destination"},{data:"revenue"},{data:"status"},{data:"action"}
        ],
        columnDefs: [
          { 
              "targets": [0], 
              "orderable": false,
          },
          ],
    };
    this.dataTableRouting = $(this.tablerouting.nativeElement);
    this.dataTableRouting.dataTable(this.dtOptionsRouting);
  }
  
  DeleteRoutingPlan(uuid: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Record!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // alert(uuid);return;
        this.moduleservice.deleteIVRRoutingPlan(uuid,currentUser.uuid).subscribe(
            res => {
              // console.log(res);return false;
              Swal.fire(
                'Deleted!',
                'Record Deleted Successfully.',
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
  
              this.loadallRoutingplan(this.uuid);
              this.loadalltarget();
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }
  
  setRouting(uuid){
    if(this.editForm.get('name').value==""){
        Swal.fire(
        'Error',
        'Please Enter Name of Routing First :)',
        'error'
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
      return false;
    }
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want Add Routing Plan!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, Add it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
        if(this.namvalue == undefined){
          this.namvalue= this.addedname; 
        }
        // console.log(this.namvalue); return;
     
        this.moduleservice.setivrRouting(uuid,this.namvalue,currentUser.uuid,currentCompany.uuid).subscribe(
            res => {
  
              this.loadallRoutingplan(this.uuid);
              this.loadalltarget();
              Swal.fire(
                'Added!',
                'Routing Plan Added Successfully.',
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);
              
              
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Routing Plan Cancelled :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }
  

     targetdata:any;
     getAlltarget(){
       this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
       this.moduleservice.getAlltarget(this.currentCompany.uuid).subscribe(
         res => {
           this.targetdata= res
           },
           error => this.error = error
       );
     }
   

  Submitted = false;
  nameadded:number = 0;
  namvalue:string;
  nam_uuid:string;
   get editFormcontrol() { return this.editForm.controls; }  
   onSubmit () {
    this.Submitted = true;
    if (this.editForm.invalid) {
        return;
    }
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.editForm.get('user_uuid').setValue(currentUser.uuid); 
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.uuid = this.route.snapshot.paramMap.get('id');       
    this.moduleservice.editIVRPlan(currentUser.uuid,this.uuid,this.editForm.value).subscribe(
         res => {
          //  console.log(res); return false;
           if (res.status !== 'error') {
             this.nameadded = 1;
             this.nam_uuid = res.uuid;
             this.namvalue = this.editForm.get('name').value;
             this.toastrService.success('IVR Routing Plan Updated Successfully');  
             this.Submitted = false;          
            //  this.router.navigate(['/campaigns']);
           }else{
             this.toastrService.error('Failed To Add Plan! Please try again');
           }
         },
         error => this.error = error,
       );     
  }

  
  closeModal(){
    if (!this.payoutForm.invalid) {
      $("#addLabels").modal("hide");
    }
  }  
  
  closeroutingModal(){
    if (!this.editRoutingForm.invalid) {
      $("#editRouting").modal("hide");
    }
  }  
  
  getsettingToggleDurationval:any=0;
  settingToggleDuration(type:any){
   this.getsettingToggleDurationval =type;
   this.editRoutingForm.get('settingToggleDuration').setValue(type);
  //  console.log(this.editRoutingForm.get('settingToggleDurationval').value);
    
  }  
  
  
    editRoutingdata(uuid:string,priority:number,weight:number,revenue:number,type:number,seconds:any,restrictdup:any,restrictafter:any,settingToggleDurationval:any,days:any){
      // console.log(uuid)
      this.editRoutingForm.patchValue({
        uuid: uuid,
        priority: priority,
        weight: weight,
        conversation_revenue: revenue,
        conversation_event: type,
        conversation_seconds: seconds,  
        restrictdup: restrictdup,         
        restrictafter: restrictafter,         
        days: days,         
        settingToggleDurationval: settingToggleDurationval,         
        settingToggleDuration: settingToggleDurationval,         
      });  
      // console.log(settingToggleDurationval);
      
      this.getsettingToggleDurationval = settingToggleDurationval;
  
    }
  
   get editRoutingSubmit() { return this.editRoutingForm.controls; }  
   oneditRoutingSubmit () {  
        this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.editRoutingForm.get('user_uuid').setValue(currentUser.uuid); 
        this.editRoutingForm.get('settingToggleDuration').setValue(this.getsettingToggleDurationval); 
       this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      //  console.log(this.editRoutingForm.value); return;
       
       this.moduleservice.editIVRRoutingSubmit(this.uuid,this.editRoutingForm.value).subscribe(
         res => {
           if (res.status !== 'error') {
             this.toastrService.success('Routing Plan Updated Successfully');  
             this.Submitted = false;          
             this.loadallRoutingplan(this.uuid);           
           }else{
             this.toastrService.error('Failed To Update Campaign');
           }
         },
         error => this.error = error,
       );     
  }
  
  
   }