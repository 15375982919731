import { FormBuilder, Validators, FormGroup} from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModuleService } from './../module.service';
import { HttpClient, HttpBackend} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Title } from '@angular/platform-browser';

declare var $;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

class Labels {
  id: number;
  name: string;
  notes: string;  
  created_at: string;      
  state: any;
  selected: any;
}

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {

//  rows = [
//     { name: 'Austin', gender: 'Male', company: 'Swimlane' },
//     { name: 'Dany', gender: 'Male', company: 'KFC' },
//     { name: 'Molly', gender: 'Female', company: 'Burger King' }
//   ];
//   columns = [{ prop: 'name' }, { name: 'Gender' }, { name: 'Company' }]; 


  private ServerUrl = GlobalVariable.BASE_API_URL;

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  dataTable: any;
  currentCompany: any;
  label: Number;  
  error: {};
  showLoadingIndicator: any;
  dtOptions ={};
  // dtOptions: DataTables.Settings = {};
  labels: Labels[];  
  private http: HttpClient;
  LabelSubmitted = false;
  addLabelForm: FormGroup;
  editLabelForm: FormGroup;
  checkForm: FormGroup;
  ischecked: string = '1';
  data:any = {};
  // payout_typeval:any;
  // flag: any;
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    handler: HttpBackend,
    private fb: FormBuilder,
    private titleService: Title
  ){ 
    this.http = new HttpClient(handler);
    // this.payout_typeval= 0;
    // this.flag = 0;

  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  
  ngOnInit() {
    this.setTitle('Labels')
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.loadlabels();

    this.addLabelForm = this.fb.group({
      name: ['', Validators.required],
      notes: ['',Validators.required],      
      user_uuid: [''],      
      // conversation_revenue: [''],      
      // conversation_event: [''],      
      // conversation_seconds: [''],      
      // payout_type: [''],      
      // payout_amount: [''],      
      // payout_trigger: [''],      
      // payout_seconds: [''],            
    });

    this.editLabelForm = this.fb.group({
      uuid: ['',''],
      name: ['',Validators.required],
      notes: ['', Validators.required],    
      user_uuid: [''],      
      // conversation_revenue: ['',Validators.required],      
      // conversation_event: ['',Validators.required],      
      // conversation_seconds: [''],      
      // payout_typeedit: [''],      
      // payout_amount: [''],      
      // payout_trigger: [''],      
      // payout_seconds: [''],            
    });
  }

  // payout_typevalue:any = 0;
  // payout_typeval(val:number) {
  //   this.payout_typevalue = val;
  // }

  // payout_typevalueedit:any = 0;
  // payout_typevaledit(val:number) {
  //   this.payout_typevalueedit = val;
  //   this.editLabelForm.get('payout_typeedit').setValue(val); 
  // }

  loadlabels(){
      if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
        $(this.table.nativeElement).dataTable().fnDestroy();
      } 
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      var that = this;
      this.dtOptions = {
        "pagingType": 'full_numbers',
        "processing": true,
        "serverSide": true,          
        "pageLength": 500,
        "order": [[ 0, "asc" ]],
        "lengthMenu": [10,25,50,100,500,1000],
        ajax: (dataTablesParameters: any, callback) => {
          that.http.post<DataTablesResponse>(
            this.ServerUrl+'getlabels/'+this.currentCompany.uuid,            
            dataTablesParameters, {}
          ).subscribe(resp => {
                    // console.log(resp);
                    that.labels = resp.data;   
                  //   setTimeout( function () {
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: [],
                    });
                  // }, 50 );

                  var out = [];
 
                  // for ( var i=0, ien=resp.data.length; i<ien ; i++ ) {
                  //     out.push( [ i+'-1', i+'-2', i+'-3', i+'-4', i+'-5' ] );
                  // }

                  // for (let i = 0; i < resp.data.length; i++) {
                  //   const name = resp.data[i].name;
                  //   const notes = resp.data[i].notes;
                  //   const noofnos = resp.data[i].noofnos;
                  //   const created_at = resp.data[i].created_at;
                  //   out.push([
                  //     '',
                  //     name,
                  //     notes,
                  //     noofnos,
                  //     created_at,
                  //     ''
                  //   ]);
                  // }
                  // console.log(JSON.stringify(out)); 
                  // console.log(resp.data); return false;

                  // for ( var i=0, ien=0+resp.data.length ; i<ien ; i++ ) {
                  //     out.push([ "name",i+'-1', i+'-2', i+'-3', i+'-4', i+'-5', i+'-6' ]);
                  // }
                  // console.log(resp.data);
                  // console.log(out);
                  // setTimeout( function () {
                  //   callback( {
                  //     draw: resp.draw,
                  //     data: resp.data,
                  //     recordsTotal: resp.recordsTotal,
                  //     recordsFiltered: resp.recordsFiltered,
                  // } );
                    // callback({
                    //         recordsTotal: resp.recordsTotal,
                    //         recordsFiltered: resp.recordsFiltered,
                    //         data: out,
                    //         draw: resp.draw,
                    //     });
                  // }, 50 );
                });
        },
        // "deferRender":    true,
        "scrollY":        800,
        // "scrollCollapse": true,
        // ordering: false,
        // searching: false,
        // "scroller": {
        //     loadingIndicator: true
        // },
        columns: [
          {data:""},{data:"name"},{data:"notes"},{data:"noofnos"},{data:"created_at"},{data:""},
        ],
        columnDefs: [
          { 
              "targets": [0], 
              "orderable": false,
          },
          ],
    };

    this.dataTable = $(this.table.nativeElement);
    this.dataTable.dataTable(this.dtOptions);
    }

    Delete(uuid: any) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Record!',
        
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          let currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.moduleservice.deleteLabel(uuid,currentUser.uuid).subscribe(
              res => {
                // console.log(res);return false;
                Swal.fire(
                  'Deleted!',
                  'Record Deleted Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);

                this.loadlabels();
              },
              error => this.error = error
          );       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }

  // recording:string;
  // get_recordingstatus(event:any){
  //   this.recording = event.target.checked;
  // }

//Add Labels
get labelcontrol() { return this.addLabelForm.controls; }  
onLabelSubmit () {
    const formData = new FormData();      
    this.LabelSubmitted = true;
    if (this.addLabelForm.invalid) {
        return;
    }
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    formData.append('user_uuid', currentUser.uuid);
    formData.append('company_uuid', this.currentCompany.uuid);
    formData.append('name', this.addLabelForm.get('name').value);
    formData.append('notes', this.addLabelForm.get('notes').value);
    
    // formData.append('conversation_revenue', this.addLabelForm.get('conversation_revenue').value);
    // formData.append('conversation_event', this.addLabelForm.get('conversation_event').value);
    // formData.append('conversation_seconds', this.addLabelForm.get('conversation_seconds').value);
    // formData.append('payout_type', this.payout_typevalue);
    // formData.append('payout_amount', this.addLabelForm.get('payout_amount').value);
    // formData.append('payout_trigger', this.addLabelForm.get('payout_trigger').value);
    // formData.append('payout_seconds', this.addLabelForm.get('payout_seconds').value);

    // formData.append('is_recording', this.recording);

    
    // console.log(formData); return false;

    this.moduleservice.addLabel(formData).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Label Added Successfully');  
          this.loadlabels();        
          this.LabelSubmitted = false;
          this.isName = false;
  
        }else{
          this.toastrService.error('Failed To Add Label');
        }
      },
      error => this.error = error,
    );     
   }

   closeModal() {    
    if(this.addLabelForm.get('name').value && this.addLabelForm.get('notes').value)
    {
      $("#addLabels").modal("hide");         
      $(".custom-switch-input").prop('checked',false);
      this.LabelSubmitted = false;
      setTimeout(() => {
        this.addLabelForm.reset();        
        this.isName="";
      }, 2000);    
    }


    if(this.editLabelForm.get('name').value && this.editLabelForm.get('notes').value)
    {
      $("#editLabels").modal("hide");    
      this.LabelSubmitted = false;
      setTimeout(() => {
        this.editLabelForm.reset();           
        this.isName="";                  
      }, 2000);    
    }
  }

  // Edit Label
  recordingval:number;
  editpayout_typeval:number;
  editLabel(uuid:string,recording:number,name:string,notes:any){
    // ,conversation_revenue:any,conversation_event:any,conversation_seconds:any,payout_type:any,payout_amount:any,payout_trigger:any,payout_seconds:any
    // alert(recording);
    // if(recording==1){
    //   this.recordingval = 1;
    // }else{
    //   this.recordingval = 0;
    // }
    // this.editpayout_typeval = payout_type;
    // this.payout_typevalueedit = payout_type;
  
    // console.log(this.editpayout_typeval);
    this.editLabelForm.patchValue({
      uuid: uuid,
      name: name,
      notes: notes,       
      // conversation_revenue: conversation_revenue,      
      // conversation_event: conversation_event,      
      // conversation_seconds: conversation_seconds,      
      // // payout_typeedit: payout_type,      
      // payout_amount: payout_amount,      
      // payout_trigger: payout_trigger,      
      // payout_seconds: payout_seconds,              
    });    
  }
  
  get labeleditcontrol() { return this.editLabelForm.controls; }  
  onLabelEditSubmit () {
      const formData = new FormData();      
      if (this.editLabelForm.invalid) {
          return;
      }
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      formData.append('user_uuid', currentUser.uuid);
      formData.append('name', this.editLabelForm.get('name').value);
      formData.append('notes', this.editLabelForm.get('notes').value);
      // formData.append('conversation_revenue', this.editLabelForm.get('conversation_revenue').value);
      // formData.append('conversation_event', this.editLabelForm.get('conversation_event').value);
      // formData.append('conversation_seconds', this.editLabelForm.get('conversation_seconds').value);
      // formData.append('payout_typeedit',  this.payout_typevalueedit);
      // formData.append('payout_amount', this.editLabelForm.get('payout_amount').value);
      // formData.append('payout_trigger', this.editLabelForm.get('payout_trigger').value);
      // formData.append('payout_seconds', this.editLabelForm.get('payout_seconds').value);
      // formData.append('is_recording', this.recording);
      
      // console.log(this.editLabelForm.get('payout_typeedit').value); return false;

      this.moduleservice.editLabel(formData,this.editLabelForm.get('uuid').value).subscribe(
        res => {
          // console.log(res); return false;
          if (res.status !== 'error') {
            this.loadlabels();
            this.toastrService.success('Label Data Saved Successfully');         
          }else{
            this.toastrService.error('Failed To Save Label Data');
          }
        },
        error => this.error = error,
      );     
     }

     
    isName : any; 
    validatename() {
    if(this.addLabelForm.get('name').value!=''){     
      this.moduleservice.validateLabelName(this.addLabelForm.get('name').value).subscribe(
        res =>{
            // console.log(res); return false;  
          this.isName = res;
          if (this.isName.status == 'success') {
            this.isName = "success";
          }
          else{
            this.isName = "fail";
          }
        }    
      );
    }else{
      this.isName="";
    }
  }

  exportdata(){
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      // showTitle: true,
      // title: 'Your title',
      useBom: true,
      // noDownload: true,
      headers: ["Label Name", "Notes","Created At"]
    };

    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
    this.moduleservice.getLabelForExport(this.currentCompany.uuid).subscribe(
      res => {
        console.log(res);
        new   ngxCsv(res.data, 'All Labels', options);
      },
      error => this.error = error
    );      
  }

  checkalldelete(event) {
    const checked = event.target.checked;
    this.labels.forEach(chkbox => chkbox.selected = checked);    
  }

  checkboxes:any = [];  
  deleteselected(event:any){ 
    // $("#customCheck1").prop("checked", false);    
    if(event.target.checked==true)
    {
      if(event.target.value=='on'){
        this.checkboxes = [];
        this.labels.forEach(lbl => {
          this.checkboxes.push(lbl.id);
        });
      }else{ 
        if(this.checkboxes.length+1==this.labels.length){
          $( "#customCheck1" ).prop( "checked", true );
        }
        this.checkboxes.push(event.target.value);            }            
        this.ischecked = '0';  
    }else{ 
      // console.log(this.checkboxes);      
      if(this.checkboxes.length!=0){
        $( "#customCheck1" ).prop( "checked", false );        
      }
      if(event.target.value!='on'){
        const index = this.checkboxes.indexOf(event.target.value);
        this.checkboxes.splice(index, 1);
      }else{
        this.checkboxes = [];
      }
      this.ischecked = '1';
    }    
    // console.log(this.checkboxes); 
  }

  onDeleteSubmit(){    
    if(this.checkboxes.length==0){
      this.toastrService.error('Please Select Row To Delete');
    }else{
      // console.log(this.checkboxes);  
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Selected Record!',
        
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {

          this.moduleservice.deleteSelectedLabels(this.checkboxes).subscribe(
            res => {
                Swal.fire(
                  'Deleted!',
                  'Records Deleted Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);

              this.loadlabels();
            },
            error => this.error = error
          );      
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }
    
  }
  
}
