import { Component, OnInit } from '@angular/core';
import { ModuleService } from './../../modules/module.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $;

@Component({
  selector: 'app-dynamic-parameter',
  templateUrl: './dynamic-parameter.component.html',
  styleUrls: ['./dynamic-parameter.component.scss']
})
export class DynamicParameterComponent implements OnInit {
  addparaForm: FormGroup;
  error: any;
  currentCompany: any;
  submitted = false;
  showLoadingIndicator: boolean;
  constructor(
    private fb: FormBuilder,
    private moduleService: ModuleService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService:ToastrService,
  ) { }

  value:any; 
  ngOnInit() {
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    
    this.addparaForm = this.fb.group({
      xyzcalltimespan: ['',''],       
      default_rule: ['',''],       
      abc_rule_expiration: ['',''],       
      sho_rel_period: ['',''],       
      long_rel_period: ['',''],       
      short_call_time_win: ['',''],       
      call_threshold: ['',''],       
    });

  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany')); 
  if (this.currentCompany.uuid) {
        this.moduleService.getDynParadata(this.currentCompany.uuid).subscribe(
          res => {
            
            for (let index = 0; index < res.length+1; index++) {
              // console.log(res[index]);
             
              if(res[index]['label']=='XYZ Call Time Span'){
                this.addparaForm.patchValue({
                  xyzcalltimespan : res[index]['value'],                              
                });                 
              }
              if(res[index]['label']=='Default Rule'){
                this.addparaForm.patchValue({
                  default_rule : res[index]['value'],                              
                });                 
              }
              if(res[index]['label']=='ABC Rule Expiration'){
                this.addparaForm.patchValue({
                  abc_rule_expiration :res[index]['value'],                              
                }); 
             }
              if(res[index]['label']=='Shortlist Release Period'){
                this.addparaForm.patchValue({
                  sho_rel_period : res[index]['value'],                              
                }); 
              }
              if(res[index]['label']=='Longlist Release Period'){
                this.addparaForm.patchValue({
                  long_rel_period : res[index]['value'],                              
                }); 
              }

              if(res[index]['label']=='Shortlist Call Time Window'){
                console.log(res[index]['value']);
                this.addparaForm.patchValue({
                  short_call_time_win : res[index]['value'],                              
                }); 
              }

              if(res[index]['label']=='Call Threshold'){
                this.addparaForm.patchValue({
                  call_threshold : res[index]['value'],                              
                }); 
              }

              
             }
                        
          }
        );
    } 
  }

  get f() { return this.addparaForm.controls; }

  onSubmit () {
    const formData = new FormData();  
    if (this.addparaForm.invalid) {
        return;
    }
    this.submitted = true;
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany')); 
    formData.append('call_threshold', this.addparaForm.get('call_threshold').value);
    const company_uuid = this.currentCompany.uuid;
    if (company_uuid) {
      this.moduleService.addDyPara(this.addparaForm.value, company_uuid).subscribe(
        res => {
          // console.log(res); return false;
          if (res.status == 'success') {
            this.toastrService.success('Dynamic Parameter Saved Successfully');            
          }else{
            this.toastrService.success('Failed To Save! Please Try Again Later..');
          }
        },
        error => this.error = error
      );
    }   
  }

}
