import { HeaderComponent } from './header/header.component';
import { Component,HostListener } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { GlobalVariable } from './global';
import { MessagingService } from "./modules/messaging.service";
import { ModuleService } from '../app/modules/module.service';
// import { SwUpdate, SwPush } from '@angular';

const MINUTES_UNITL_AUTO_LOGOUT = 60 // in mins
const CHECK_INTERVAL = 10000 // in ms
const STORE_KEY =  'lastAction';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  private ServerUrl = GlobalVariable.BASE_URL;
  message;
  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
 public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  islogin:any;
  showLoadingIndicator: any;
  routeurl:any;
  emailurl:any;
  constructor(public router:Router,private activatedRoute: ActivatedRoute,private authService: AuthService,private messagingService: MessagingService,private moduleservice: ModuleService){
    // console.log(this.router.url)
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY,Date.now().toString());    
    this.activatedRoute.params.subscribe( (data) => {
      this.routeurl = data.id;        
    })
  }
  title = 'Dial-Digits';
  
  ngOnInit() {   
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },5000); 
    // console.log(this.ServerUrl);
    // console.log(window.location.href);
    this.routeurl = window.location.href.replace(this.ServerUrl+'publisher-registration/', ""); 
    this.emailurl = window.location.href.replace(this.ServerUrl+'verify-email/', ""); 
    // console.log(this.routeurl);
    // this.checkcompanyavail(this.routeurl);
    $('body:has(.login)').addClass('redBorder');
  
    // this.messagingService.requestPermission()
    // this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage

    };

    checkcompanyavail(company_uuid:any){
      this.moduleservice.checkcompanyavail(company_uuid).subscribe(
        res => {
          console.log('gettting');
          console.log(res.isavail);
          
           if (res.isavail!==1)  {
            this.router.navigate(['./404']);
           }
        //  this.email = res['email'];     
        },
        // error => this.error = error,
      );  
    }  
  

    initListener() {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('mouseover',()=> this.reset());
      document.body.addEventListener('mouseout',() => this.reset());
      document.body.addEventListener('keydown',() => this.reset());
      document.body.addEventListener('keyup',() => this.reset());
      document.body.addEventListener('keypress',() => this.reset());
    }
  
    reset() {
      this.setLastAction(Date.now());
    }
  
    initInterval() {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVAL);
    }
  
    check() {
      const now = Date.now();
      const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
      const diff = timeleft - now;
      const isTimeout = diff < 0;
  
      if (isTimeout)  {
        this.authService.logout();
        this.router.navigate(['./login']);
      }
    }
    
}




    // setInterval(() => {
    //   this.authService.logout();
    // }, 1 * 60 * 1000);

    // this.islogin = this.authService.isLoggedIn();
    
    // if(this.islogin=='false'){
      // console.log(this.islogin);  
      // this.router.navigate(['/login']);
    // }  

    
  // @HostListener("window:onbeforeunload",["$event"])
  // clearLocalStorage(event){
  //     // localStorage.clear();
  //     alert('kk')
  //     localStorage.removeItem('currentuser');
  // }

  // @HostListener('window:beforeunload', [ '$event' ])
  // @HostListener('click')
  // onclick() {
  //   alert('kk')
  // }

  // @HostListener('window:beforeunload', ['$event'])
  //    public beforeunloadHandler($event) {
  //    $event.returnValue = "Are you sure?";
  //   }
    
  // @HostListener('window:unload', [ '$event' ])
  // unloadHandler(event) {
  //   alert('kk')
   
  // }