import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModuleService } from './../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {RequestOptions } from '@angular/http';
//import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2';
import { GlobalVariable } from '../../global';

declare var $;
const router = Router;


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

let requestOptions = new RequestOptions({ headers:null, withCredentials: 
  true });

const ParseHeaders = {
  headers: new HttpHeaders({
   'Content-Type'  : 'application/x-www-form-urlencoded'
  })
 };
 

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss']
})
export class BlacklistComponent implements OnInit {
  private ServerUrl = GlobalVariable.BASE_API_URL;

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  dataTable: any;
  currentCompany: any;
  
  error: {};
  showLoadingIndicator: any;
  dtOptions: DataTables.Settings = {};
  numbers: any;
  ischecked: string = '1';
  // that: Number;
  private http: HttpClient;
  numberSubmitted = false;
  numbereditSubmitted = false;
  addNumberForm: FormGroup;
  editNumberForm: FormGroup;
  constructor(
    private moduleservice: ModuleService,
    private toastrService:ToastrService,
    handler: HttpBackend,
    private fb: FormBuilder,
  ){ 
    this.http = new HttpClient(handler);
  }

  ngOnInit() {
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    this.loadnumbers();

    this.addNumberForm = this.fb.group({
      number: ['', Validators.required],
    });

    this.editNumberForm = this.fb.group({
      uuid: ['',''],
      number: ['',Validators.required],
    });
  }
  loadnumbers(){
    if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
      $(this.table.nativeElement).dataTable().fnDestroy();
    }   
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));   
    var that = this;
    this.dtOptions = {
      "pagingType": 'full_numbers',
      "processing": true,
      "serverSide": true,          
      "pageLength": 20,
      "order": [[ 0, "desc" ]],
      ajax: (dataTablesParameters: any, callback) => {
        that.http.post<DataTablesResponse>(
          this.ServerUrl+'/getlistnumbers/0/'+this.currentCompany.uuid,
        
          dataTablesParameters, {}
        ).subscribe(resp => {
                  console.log(resp);
                  that.numbers = resp.data;   
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: [],
                  });
              });
      },
      columns: [
        {data:"checkall"},{data:"number"},{data:"created_at"},{data:"action"},
      ],
      columnDefs: [
        { 
            "targets": [0,3], 
            "orderable": false,
        },
      ],
  };

  this.dataTable = $(this.table.nativeElement);
  this.dataTable.dataTable(this.dtOptions);
  }

  Delete(uuid: any) {
    // alert(uuid);
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete Record!',
      
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.moduleservice.deleteListNumber(uuid,0).subscribe(
            res => {
              // console.log(res);return false;
              Swal.fire(
                'Deleted!',
                'Record Deleted Successfully.',
              )
              setTimeout(() => {
                Swal.close()
              }, 2000);

              this.loadnumbers();
            },
            error => this.error = error
        );       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your Record is safe :)',
          'error'
        )
        setTimeout(() => {
          Swal.close()
        }, 2000);
      }
    });
  }

//Add Labels
get numbercontrol() { return this.addNumberForm.controls; }  
onNumberSubmit () {
  const formData = new FormData();      
  this.numberSubmitted = true;
  if (this.addNumberForm.invalid) {
      return;
  }
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  // console.log(this.currentCompany); return false;
  formData.append('company_uuid', this.currentCompany.uuid);
  formData.append('number', this.addNumberForm.get('number').value);
  formData.append('type', '0');
  this.moduleservice.addNumberlist(formData).subscribe(
    res => {
      // console.log(res); return false;
      if (res.status !== 'error') {
        this.toastrService.success('Number Added Successfully');         
      }else{
        this.toastrService.error('Failed To Add Label');
      }
    },
    error => this.error = error,
  );     
 }

 closeModal() {  
  if(this.addNumberForm.get('number').value)
  {
    $("#addNumber").modal("hide");    
    this.loadnumbers();          
    setTimeout(() => {
      this.numberSubmitted = false;
      this.addNumberForm.reset();
    }, 2000);    
  }


  if(this.editNumberForm.get('number').value)
  {
    $("#editNumber").modal("hide");    
    setTimeout(() => {
      this.numbereditSubmitted = false;
      this.editNumberForm.reset();
      this.loadnumbers();          
    }, 2000);    
  }
}

// Edit Label
editNumber(uuid:string,number:string){
  this.editNumberForm.patchValue({
    uuid: uuid,
    number: number,   
  });    
}

get numbereditcontrol() { return this.editNumberForm.controls; }  
onNumberEditSubmit () {
    const formData = new FormData();  
    this.numbereditSubmitted = true;    
    if (this.editNumberForm.invalid) {
        return;
    }
    formData.append('number', this.editNumberForm.get('number').value);
    formData.append('type', '0');
    this.moduleservice.editNumberList(formData,this.editNumberForm.get('uuid').value).subscribe(
      res => {
        // console.log(res); return false;
        if (res.status !== 'error') {
          this.toastrService.success('Black List Number Saved Successfully');         
        }else{
          this.toastrService.error('Failed To Save Black List Number Data');
        }
      },
      error => this.error = error,
    );     
   }

   _keyPress(event: any) {
    const isNumericInput = (event) => {
      const key = event.keyCode;
      return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
      );
    };
    
    const isModifierKey = (event) => {
      const key = event.keyCode;
      return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
          // Allow Ctrl/Command + A,C,V,X,Z
          (event.ctrlKey === true || event.metaKey === true) &&
          (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
    };
    
    const enforceFormat = (event) => {
      // Input must be of a valid number format or a modifier key, and not longer than ten digits
      if(!isNumericInput(event) && !isModifierKey(event)){
        event.preventDefault();
      }
    };
    
    const formatToPhone = (event) => {
      if(isModifierKey(event)) {return;}
    
      const target = event.target;
      const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
      const zip = input.substring(0,3);
      const middle = input.substring(3,6);
      const last = input.substring(6,10);
    
      if(input.length > 6){target.value = `(${zip}) ${middle} - ${last}`;}
      else if(input.length > 3){target.value = `(${zip}) ${middle}`;}
      else if(input.length > 0){target.value = `(${zip}`;}
    };
    const number = document.getElementById('number');
    const editnumber = document.getElementById('editnumber');
    
    if(number){
      number.addEventListener('keydown',enforceFormat);
      number.addEventListener('keyup',formatToPhone);    
      }  
    if(editnumber){
      editnumber.addEventListener('keydown',enforceFormat);
      editnumber.addEventListener('keyup',formatToPhone);    
      }  
     
  }

  // Check Delete
  checkalldelete(event) {
    const checked = event.target.checked;
    this.numbers.forEach(chkbox => chkbox.selected = checked);    
  }

  checkboxes:any = [];  
  deleteselected(event:any){     
    if(event.target.checked==true)
    {
      if(event.target.value=='on'){
        this.checkboxes = [];
        this.numbers.forEach(lbl => {
          this.checkboxes.push(lbl.id);
        });
      }else{ 
        if(this.checkboxes.length+1==this.numbers.length){
          $( "#customCheck1" ).prop( "checked", true );
        }
        this.checkboxes.push(event.target.value);            }            
        this.ischecked = '0';  
    }else{ 
      // console.log(this.checkboxes);      
      if(this.checkboxes.length!=0){
        $( "#customCheck1" ).prop( "checked", false );        
      }
      if(event.target.value!='on'){
        const index = this.checkboxes.indexOf(event.target.value);
        this.checkboxes.splice(index, 1);
      }else{
        this.checkboxes = [];
      }
      this.ischecked = '1';
    }    
    // console.log(this.checkboxes); 
  }

  onDeleteSubmit(){    
    if(this.checkboxes.length==0){
      this.toastrService.error('Please Select Row To Delete');
    }else{
      // console.log(this.checkboxes);  
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to Delete Selected Record!',
        
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {

          this.moduleservice.deletewbSelectedNumbers(this.checkboxes,0).subscribe(
            res => {
                Swal.fire(
                  'Deleted!',
                  'Records Deleted Successfully.',
                )
                setTimeout(() => {
                  Swal.close()
                }, 2000);

              this.loadnumbers();
            },
            error => this.error = error
          );      
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your Record is safe :)',
            'error'
          )
          setTimeout(() => {
            Swal.close()
          }, 2000);
        }
      });
    }
    
  }

}
