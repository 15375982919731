import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ModuleService } from './modules/module.service';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { ModulesModule } from './modules/modules.module';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AuthModule } from './auth/auth.module';
import { PageNotFoundComponentComponent } from './page-not-found-component/page-not-found-component.component';
import { RouterModule } from '@angular/router';
import { httpInterceptorProviders } from './http-interceptors/index';
import { AuthService } from './auth/auth.service';
import { SidebarService } from './sidebar/sidebar.service';
import { ToastrModule } from 'ngx-toastr';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelect2Module } from 'ng-select2';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';

import { MessagingService } from './modules/messaging.service';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { EditorModule } from "@tinymce/tinymce-angular";
@NgModule({
  declarations: [
    AppComponent ,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    PageNotFoundComponentComponent,
    PagenotfoundComponent,  
  ],
  imports: [
    EditorModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule,
    ModulesModule,
    RouterModule,
    NgSelect2Module,
    BrowserAnimationsModule,    
    NgxDatatableModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    
  ],
  providers: [AuthService,SidebarService,ModuleService,MessagingService, AsyncPipe,Title,
    httpInterceptorProviders, {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// platformBrowserDynamic().bootstrapModule(AppModule);
