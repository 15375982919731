import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModuleService } from './../module.service';
import { HttpClient, HttpBackend} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
//import 'sweetalert2/src/sweetalert2.scss'
import { GlobalVariable } from '../../global';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import "datatables.net";
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

declare var $;
const router = Router;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


@Component({
  selector: 'app-recordings',
  templateUrl: './recordings.component.html',
  styleUrls: ['./recordings.component.scss']
})
export class RecordingsComponent implements OnInit {
   private ServerUrl = GlobalVariable.BASE_API_URL;
   
    selected: any;
    numbers: any = '';
    alwaysShowCalendars: boolean;
    showRangeLabelOnInput: boolean;
    keepCalendarOpeningWithRange: boolean;
    maxDate: moment.Moment;
    minDate: moment.Moment;
   ranges: any = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment()
          .subtract(1, 'month')
          .startOf('month'),
        moment()
          .subtract(1, 'month')
          .endOf('month')
      ],
    
    };
  
    @ViewChild('dataTable', {static: true}) table: ElementRef;
    dataTable: any;
    currentCompany: any;
    label: Number;
    error: {};
    showLoadingIndicator: any;
    dtOptions: DataTables.Settings = {};
    datas: any;
    private http: HttpClient;
    searchcallsform: FormGroup;
    submitted = false;
  
  
    constructor(
      private moduleservice: ModuleService,
      private toastrService:ToastrService,
      handler: HttpBackend,
      private fb: FormBuilder,
      private titleService: Title  
    ){ 
      this.http = new HttpClient(handler);
      
      this.alwaysShowCalendars = true;
      this.keepCalendarOpeningWithRange = true;
      this.showRangeLabelOnInput = true;
      this.selected = {startDate: moment().startOf('day'), endDate: moment().endOf('day')};  
    }  
   
    public setTitle(newTitle: string) {
      this.titleService.setTitle(newTitle);
    }
    
    ngOnInit() {
      this.setTitle('Recordings')
      this.showLoadingIndicator= true;
      setTimeout(()=>{this.showLoadingIndicator= false; },1000);
      this.loadcalls();
    }
    get f() { return this.searchcallsform.controls; }  
  
    loadcalls(){
     
      if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
        $(this.table.nativeElement).dataTable().fnDestroy();
      } 
     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      var that = this;
      this.dtOptions = {
        "pagingType": 'full_numbers',
        "processing": true,
        "serverSide": true,          
        "pageLength": 20,
        "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],   
        "order": [[ 2, "desc" ]],
        ajax: (dataTablesParameters: any, callback) => {
          that.http.post<DataTablesResponse>(
            this.ServerUrl+'getrecordings/'+this.currentCompany.uuid, {'dataTablesParameters':dataTablesParameters,'number':this.numbers,'date':this.selected}
           , {}
          ).subscribe(resp => {
                    // console.log(resp); return false;
                    that.datas = resp.data;   
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: [],
                    });
                });
        },
        columns: [
          {data:"uuid"},{data:"did"},{data:"incoming_number"},{data:"call_duration"},{data:"date_of_call"},{data:"download"}
        ],
    };
  
    this.dataTable = $(this.table.nativeElement);
    this.dataTable.dataTable(this.dtOptions);
    }
  
    exportdata(){
      var options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true, 
        useBom: true,
        headers: ["Number Called", " Caller ID"," Forwarded To"," Call Duration","Labels","IVR Inputs","Date of Call","Time of Call"]
      };
  
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleservice.getCallsForExport(this.currentCompany.uuid).subscribe(
        res => {
          new   ngxCsv(res.data, 'My Report', options);
        },
        error => this.error = error
    );      
    }

}
