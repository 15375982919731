import { GlobalVariable } from './../../../global';
import { AfterViewInit, ChangeDetectionStrategy,ViewChild, ElementRef, ChangeDetectorRef,Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup,FormArray,Validators, FormBuilder } from '@angular/forms';
import { ModuleService } from './../../module.service';
import { HttpClient,HttpHeaders, HttpBackend} from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { ngxCsv } from 'ngx-csv';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Title } from '@angular/platform-browser';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
declare var $;
import HC_exporting from 'highcharts/modules/exporting';
HC_exporting(Highcharts);

class DataTablesResponse {
  data: any[];
  campaign_no: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
  pendingTotal:number;
  completedTotal:number;
  pendingdata:any[];
  completed:any[];
}

@Component({
  selector: 'app-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss'],

  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignEditComponent implements OnInit {

  @ViewChild('dataTable', {static: true}) table: ElementRef;
  @ViewChild('dataTableTarget', {static: true}) tabletarget: ElementRef;
  @ViewChild('dataTablePendingReq', {static: true}) tablependingreq: ElementRef;
  @ViewChild('dataTableRouting', {static: true}) tablerouting: ElementRef;
  @ViewChild('targetDiv') targetDiv: ElementRef;
  @ViewChild('PerdataTable', {static: true}) Pertable: ElementRef;
  PerdataTable: any;
   numberForm: FormGroup;
   editForm: FormGroup;
   payoutForm: FormGroup;
   addofferForm: FormGroup;
   publishtertocampaignForm: FormGroup;
   defaultpayoutForm: FormGroup;
   editRoutingForm: FormGroup;
   currentCompany: any;
   locations:any = [];
   error: {};
   showLoadingIndicator: any;
   getdata: any;
   uuid:any;
   dropdownSettingss:IDropdownSettings;
   labeldropdownSettingss:IDropdownSettings;
   dropdownList = [];
   selectedItems = [];
   dropdownSettings = {};
   pubdropdownSettings = {};
   labeldropdownSettings = {};

   private ServerUrl = GlobalVariable.BASE_API_URL;
   dtTrigger: Subject<any> = new Subject<any>();
   dtOptions: DataTables.Settings = {};
   dtOptionsTarget: DataTables.Settings = {};
   dtOptionsRouting: DataTables.Settings = {};
   dtOptionsPending: DataTables.Settings = {};
   dataTable: any;
   dataTableTarget: any;
   dataTablePendingReq: any;
   dataTableRouting: any;
   campaignpublisher: any;
   private http: HttpClient;

   constructor(
       private renderer: Renderer2,
       private moduleservice: ModuleService,
       private toastrService:ToastrService,
       handler: HttpBackend,
       private fb: FormBuilder,
       private router: Router,
       private route: ActivatedRoute,
       private cdRef: ChangeDetectorRef,
       private ngxLoader: NgxUiLoaderService,
       private titleService: Title
       ){
        this.http = new HttpClient(handler);
      this.uuid = this.route.snapshot.paramMap.get('id');
   }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

   public allRulesbyname: any;
   getRulesByName(target_uuid: string){
    //  console.log(name);
    if(target_uuid!=''){
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.moduleservice.getRulesByTargetuuid(target_uuid,this.currentCompany.uuid).subscribe(
        res => {
          // console.log(res);
          this.allRulesbyname = res;
        },
        error => this.error = error,
      );
    }else{
      this.allRulesbyname="";
    }
  }

allweekdays:any;
loadallweekdays(){
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleservice.loadallweekdays(this.currentCompany.uuid).subscribe(
    res => {
      //  console.log(res); return false;
      this.allweekdays = res.data;
    },
    error => this.error = error,
  );
}


labels:any;
loadcalls(){
  if ($.fn.DataTable.isDataTable(this.Pertable.nativeElement) ) {
    $(this.Pertable.nativeElement).dataTable().fnDestroy();
  }

  let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  var that = this;
  this.dtOptions = {
    "pagingType": 'full_numbers',
    "processing": true,
    "serverSide": true,
    "paging":   false,
    "info":     false,
    // "lengthMenu": [[-1, 50, 100, 500, 2000, 5000], ["All", 50, 100, 500, 2000, 5000 ]],
    "pageLength": 20,
    // "ordering": false,
    "searching":false,
    ajax: (dataTablesParameters: any, callback) => {
      that.http.post<DataTablesResponse>(
        this.ServerUrl+'getlastweekcalls/'+this.currentCompany.uuid+'/'+currentUser.timezone+'/', {'dataTablesParameters':dataTablesParameters, 'user_uuid':currentUser.uuid, 'role':currentUser.role,'campaign_uuid':this.uuid}
       , {}
      ).subscribe(resp => {
                // console.log(resp); return false;
                that.labels = resp.campaign_no;
                callback({
                    recordsTotal: resp.recordsTotal,
                    recordsFiltered: resp.recordsFiltered,
                    data: [],
                });
            });
    },
    columns: [
      {data:"did"},{data:"incoming_number"},{data:"publisher"},{data:"target"},{data:"campaign"},{data:"routed_to"},{data:"routed_to"},{data:"routed_to"},
    ],
};

this.dataTable = $(this.Pertable.nativeElement);
this.dataTable.dataTable(this.dtOptions);
}

labeldata:[];
getAllLabels(){
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleservice.getAllLabels(this.currentCompany.uuid).subscribe(
    res => {
      this.labeldata = res;
      // console.log(res);
    },
    error => this.error = error,
  );
}

   editpayout_typeval:number;
   isrecording:number;
   campaignNumbers: any = [];
   campaignLabels: any = [];
   campaignoffers:any = [];

   ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

   today: string;
   event:any="";
   ngOnInit() {
     this.showLoadingIndicator= true;
     setTimeout(()=>{this.showLoadingIndicator= false; },1000);
     this.today = new Date().toISOString().split('T')[0];

     this.dropdownSettings = {
        singleSelection: true,
        text:"Select Number",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class",
      };

      this.pubdropdownSettings = {
        singleSelection: true,
        text:"Select Publisher",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class",
      };

      this.labeldropdownSettings = {
        singleSelection: false,
        text:"Select Labels",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',
        enableSearchFilter: true,
        classes:"myclass custom-class"
      };

     this.getcampaignbyuuid();
     this.getPredicateRouting();
     this.gettimezone();
     this.getAllLabels();
     this.getAllpublishers();
     this.loadallweekdays()

     this.loadNumbers();
     this.loadcalls();

     this.getChartNumberOfCalls(this.event);

     this.getAlltarget();

     this.loadcampaignpublishers(this.uuid);
     this.loadparticipationrequest(this.uuid);
     this.loadcampaigntarget();
     this.loadallRoutingplan(this.uuid);

     this.payoutForm = this.fb.group({
      user_uuid: [''],
      phoneno: [''],
      publisher_uuid: [''],
      payout_typeedit: [''],
      payout_amount: [''],
      payout_trigger: [''],
      payout_seconds: [''],
     });

     this.numberForm = this.fb.group({
      company_uuid: [''],
      user_uuid: [''],
      campaign_uuid: [''],
      notype: [0],
      prefix: [''],
      isexisting: [''],
      selectedExistingNumber: [''],
      payoutuuid: [''],
      isedit: ['1'],
     });

     this.addofferForm = this.fb.group({
      name: [''],
      description: [''],
      hoursofoperation: [''],
      exp_timezone: [''],
      exp_date: [''],
      start_hrs: ['23'],
      start_min: ['59'],
      max_no_per_publisher: ['100'],
     });

     this.publishtertocampaignForm = this.fb.group({
      user_uuid: [''],
      number: [''],
      publisher_uuid: [''],
      payout_typeedit: [''],
      payout_amount: [''],
      payout_trigger: [''],
      payout_seconds: [''],
     });

     this.defaultpayoutForm = this.fb.group({
      user_uuid: [''],
      payout_type: [''],
      payout_amount: [''],
      payout_trigger: [''],
      payout_seconds: [''],
     });

     this.editRoutingForm = this.fb.group({
      user_uuid: [''],
      uuid: [''],
      priority: [''],
      weight: [''],
      conversation_revenue: [''],
      conversation_event: [''],
      conversation_seconds: [''],
      restrictdup: [''],
      restrictafter: [''],
      days: [''],
      settingToggleDurationval: [''],
      settingToggleDuration: [''],
     });

     this.editForm = this.fb.group({
       campaignName : ['', Validators.required],
       user_uuid: [''],
       country: [''],
       // option: [''],
       number: [''],
       labeldata: [''],
       numberIsFor: [''],
       // numberType: [''],
       publisher: [''],
       target: [''],
       conversation_revenue: [''],
       conversation_event: [''],
       conversation_seconds: [''],

      //  payout_typeedit: ['',Validators.required],
      //  payout_amount: [''],
      //  payout_trigger: [''],
      //  payout_seconds: [''],
       recording: [''],
       enable_blacklist_aliance: [''],
       is_enable_offer: [''],
       enable_predicaterouting: [''],
       predicate_routing: ['']
     });
     // this.options = {
     //   multiple: true,
     //   theme: 'classic',
     //   closeOnSelect: false,
     //   width: '370'
     // };
   }
  defaultconf:any;
  getcampaignbyuuid(){
    this.moduleservice.getcampainbyuuid(this.uuid).subscribe(
      res => {
        this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
        if(this.currentCompany.uuid != res.campaigns.company_uuid){
          Swal.fire({
            title:'Error!',
            html:'This campaign is assigned to another company.',
            icon:'warning',
            timer:2000
          }
          )
          this.router.navigate(['/campaigns']);
        }

        this.setTitle(res.campaigns.name+' | Campaign')
        this.getdata = res.campaigns;
        // console.log(this.getdata);

        this.campaignoffers = res.campaign_offer;

        this.dpayout_type = res.campaigns.payout_type;
        this.editpayout_typeval = res.campaigns.payout_type;
        this.payout_typevalueedit = res.campaigns.payout_type;
        this.isrecording = res.campaigns.is_recording;
        this.isblacklistaliance = Number(res.campaigns.enable_blacklist_aliance);
        this.is_enable_offer = Number(res.campaigns.is_enable_offer);
        this.ispredicaterouting = Number(res.campaigns.isPredicateRouting);
        this.defaultconf = res.conf;
        if(res.campaigns.payout_amount!=""){
          this.defaultpayoutForm.patchValue({
            payout_type: res.campaigns.payout_type,
            payout_amount: res.campaigns.payout_amount,
            payout_trigger: res.campaigns.payout_trigger,
            payout_seconds: res.campaigns.payout_seconds,
          });
        }

        this.editForm.patchValue({
          campaignName: res.campaigns.name,
          country: res.campaigns.country,
          number: res.campaigns.number,
          publisher: res.campaigns.publisher_uuid,
          enable_predicaterouting: this.ispredicaterouting,
          predicate_routing: Number(res.campaigns.predicateRouting),
        });
        // console.log(res.campaigns.predicate_routing);

        this.numberIsFor = res.campaigns.numberIs_For;
        // console.log(res.campaigns.company_uuid);
        this.editForm.get('numberIsFor').setValue(res.campaigns.numberIs_For);
        this.editForm.get('enable_blacklist_aliance').setValue(Number(res.campaigns.enable_blacklist_aliance));
        this.editForm.get('is_enable_offer').setValue(Number(res.campaigns.is_enable_offer));
      },
      error => this.error = error,
    );
  }

   getpayoutdata(uuid,phone,number_uuid){
    // console.log(phone[0]['number_uuid']);
    // if(phone.length>0){    
    //   this.payoutForm.get('phoneno').setValue(phone[0]['number_uuid']);
    // }
    this.payoutForm.get('phoneno').setValue(number_uuid); 
    this.ngxLoader.startLoader('loader-10');
    this.payoutForm.get('publisher_uuid').setValue(uuid);
    this.moduleservice.getpayoutdata(uuid,number_uuid).subscribe(
      resp => {
        // console.log(resp);
        if(resp!=null){
          this.editpayout_typeval = resp.payout_type;
          this.payout_typevalueedit = resp.payout_type;
          this.payoutForm.get('payout_typeedit').setValue(this.editpayout_typeval);
          this.payoutForm.patchValue({
            payout_typeedit: resp.payout_type,
            payout_amount: resp.payout_amount,
            payout_trigger: resp.payout_trigger,
            payout_seconds: resp.payout_seconds
          });
        }else{
          this.editpayout_typeval = 0;
          this.payout_typevalueedit = 0;
          this.payoutForm.get('payout_typeedit').setValue(0);
          this.payoutForm.patchValue({
            payout_typeedit: 0,
            payout_amount: "",
            payout_trigger: 0,
            payout_seconds: ""
          });
        }
        this.ngxLoader.stopLoader('loader-10');
        },
        error => this.error = error
    );
   }

   loadcampaignpublishers(uuid){
    // console.log('ok');
    // return false;
      if ($.fn.DataTable.isDataTable(this.table.nativeElement) ) {
        $(this.table.nativeElement).dataTable().fnDestroy();
      }
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.dtOptions = {
        "pagingType": 'full_numbers',
        "processing": true,
        "serverSide": true,
        "pageLength": 20,
        "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],
        "order": [[ 0, "asc" ]],
        "searchDelay": 350,
        ajax: (dataTablesParameters: any, callback) => {
          this.http.post<DataTablesResponse>(
            this.ServerUrl+'getCampaignPublisher/'+uuid,
            dataTablesParameters, {}
          ).subscribe(resp => {
                    this.campaignpublisher = resp.data;
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: [],
                    });
                });
        },
        columns: [
          {data:"enabled"},{data:"publisher"},{data:"phoneno"},{data:"conf"},{data:"status"},{data:"action"},
          // ,{data:"labels"}
        ],
        columnDefs: [
          {
              "targets": [0],
              "orderable": false,
          },
          ],
    };

  this.dataTable = $(this.table.nativeElement);
  this.dataTable.dataTable(this.dtOptions);
}

chart:any;
  date1:string;
  bgcolorcode:any;
  getChartNumberOfCalls(event:any){
    // console.log(event);return false;
    if(event!=''){
      this.date1 = event.endDate._d;
    }else{
      this.date1 = '';
    }
    // console.log(this.date1);
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let params = {
      user_uuid : currentUser.uuid,
      role : currentUser.role,
      date : this.date1,
      campaign_uuid:this.uuid
    }
    // console.log(this.uuid);

    this.moduleservice.getChartbyDate(this.currentCompany.uuid,params,currentUser.timezone).subscribe(
      res => {
        //  console.log(res);
        if ($("html").hasClass("dark")) {
          this.bgcolorcode='rgb(17,19,25)';
        }
        this.chart = new Chart({
          chart: {
            // polar: true,
            type: 'line',

            backgroundColor:this.bgcolorcode
          },
          exporting: {
            enabled: true
          },
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          xAxis: {
              minRange: 0,
              gridLineWidth: 0,
              title: {
                text: 'Hours'
              }
          },
          yAxis: {
            min:0,
            gridLineWidth: 1,
            title: {
              text: 'Number Of Calls'
            }
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            area: {
                  fillColor: {
                    linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 1
                    },
                    stops: [
                      [0, '#7cb5ec'],
                      [1, 'rgba(124,181,236,0)']
                    ]
                  },
                  marker: {
                    radius: 2
                  },
                  lineWidth: 1,
                  states: {
                      hover: {
                          lineWidth: 1
                      }
                  },
                  threshold: null
              },

            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: false
            }
          },
          series: [{
            data: res,
            type: 'area',
            name:"Call Count"
          }]
        });
      },
      error => this.error = error
    );
  }

pendingdata:any = [];
completed:any = [];
offerdetails:any = [];
firstDate:any;
secondDate:any;
diffdays:any;
loadparticipationrequest(uuid){
      this.ngxLoader.startLoader('loader-03');
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      this.http.post<any>(
              this.ServerUrl+'getParticipationRequest/'+uuid,
              {}
            ).subscribe(resp => {
              // console.log(resp);
              this.offerdetails = resp.offerdetails;
              if(resp.offerdetails && resp.offerdetails.exp_date){
                this.firstDate = new Date(resp.offerdetails.exp_date);
              }
              this.secondDate = new Date();

              const ONE_DAY = 1000 * 60 * 60 * 24;
              const differenceMs = Math.abs(this.firstDate - this.secondDate);
              this.diffdays = Math.round(differenceMs / ONE_DAY);


              this.pendingdata = resp.data.pending ? resp.data.pending : [];
              this.completed = resp.data.completed ? resp.data.completed : [];

              this.dtTrigger.next();
              this.ngxLoader.stopLoader('loader-03');
            },
            error => this.error = error
        );
}

allTargets:any
loadcampaigntarget(){
  if ($.fn.DataTable.isDataTable(this.tabletarget.nativeElement) ) {
    $(this.tabletarget.nativeElement).dataTable().fnDestroy();
   }
   this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
   let currentUser = JSON.parse(localStorage.getItem('currentUser'));
   this.dtOptionsTarget = {
      "pagingType": 'full_numbers',
      "processing": true,
      "serverSide": true,
      "pageLength": 20,
      "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],
      "order": [[ 0, "asc" ]],
      ajax: (dataTablesParameters: any, callback) => {
        this.http.post<DataTablesResponse>(
          this.ServerUrl+'gettargets/'+this.currentCompany.uuid+'/'+this.uuid,
          {'dataTablesParameters':dataTablesParameters, 'user_uuid':currentUser.uuid, 'role':currentUser.role}, {}
        ).subscribe(resp => {
                  this.allTargets = resp.data;
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: [],
                  });
              });
      },
      columns: [
        {data:"name"},{data:"typeval"},{data:"active_status"},{data:"status"},{data:"action"},
      ],
      columnDefs: [
        {
            "targets": [0],
            "orderable": false,
        },
        ],
  };

this.dataTableTarget = $(this.tabletarget.nativeElement);
this.dataTableTarget.dataTable(this.dtOptionsTarget);
}

allRoutingPlan:any= [];
loadallRoutingplan(uuid){
   if ($.fn.DataTable.isDataTable(this.tablerouting.nativeElement) ) {
    $(this.tablerouting.nativeElement).dataTable().fnDestroy();
   }

   this.dtOptionsRouting = {
      "pagingType": 'full_numbers',
      "processing": true,
      "serverSide": true,
      // "scrollX": true,
      "pageLength": 20,
      "lengthMenu": [[-1, 20, 100, 500, 2000], ["All", 20, 100, 500, 2000]],
      "order": [[ 0, "asc" ]],
      "searching": true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http.post<DataTablesResponse>(
          this.ServerUrl+'getallRoutingplan/'+uuid+'/'+this.currentCompany.uuid,
          dataTablesParameters, {}
        ).subscribe(resp => {
                  this.allRoutingPlan = resp.data;
                  console.log(this.allRoutingPlan);
                  callback({
                      recordsTotal: resp.recordsTotal,
                      recordsFiltered: resp.recordsFiltered,
                      data: [],
                  });
              });
      },
      columns: [
        {data:"priority"},{data:"weight"},{data:"name"},{data:"destination"},{data:"revenue"},{data:"target_status"},{data:"status"},{data:"action"}
      ],
      // columnDefs: [
      //   {
      //       "targets": [0],
      //       "orderable": false,
      //   },
      //   ],
  };
  this.dataTableRouting = $(this.tablerouting.nativeElement);
  this.dataTableRouting.dataTable(this.dtOptionsRouting);
}

DeleteRoutingPlan(uuid: any) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to Delete Record!',

    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.moduleservice.deleteRoutingPlan(uuid,currentUser.uuid).subscribe(
          res => {
            // console.log(res);return false;
            Swal.fire(
              'Deleted!',
              'Record Deleted Successfully.',
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);

            this.loadallRoutingplan(this.uuid);
            this.loadcampaigntarget();
          },
          error => this.error = error
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your Record is safe :)',
        'error'
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}

DeletePublisherPayout(uuid: any) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to Delete Record!',

    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.moduleservice.deletePublisherPayout(uuid).subscribe(
          res => {
            // console.log(res);return false;
            Swal.fire(
              'Deleted!',
              'Record Deleted Successfully.',
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);
            this.loadNumbers();
            this.loadcampaignpublishers(this.uuid);
          },
          error => this.error = error
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your Record is safe :)',
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}

DeletePayoutNo(uuid: string,nouuid:string) {
  // console.log(nouuid); return
  
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to Delete Record!',

    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      this.moduleservice.DeletePayoutNo(uuid,nouuid).subscribe(
          res => {
            // console.log(res);return false;
            Swal.fire(
              'Deleted!',
              'Record Deleted Successfully.',
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);
            this.loadNumbers();
            this.loadcampaignpublishers(this.uuid);
          },
          error => this.error = error
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your Record is safe :)',
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}

Deletedefaultpayout() {
  this.defaultpayoutForm.reset();
  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.defaultpayoutForm.get('user_uuid').setValue(currentUser.uuid);
  this.defaultpayoutForm.get('payout_type').setValue(0);
  this.defaultpayoutForm.get('payout_amount').setValue("");
  this.defaultpayoutForm.get('payout_trigger').setValue(0);
  this.defaultpayoutForm.get('payout_seconds').setValue("");
  // console.log(this.defaultpayoutForm.value); return

  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to Delete Record!',

    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.moduleservice.adddefaultpayout(this.uuid,this.defaultpayoutForm.value).subscribe(
        res => {
          if (res.status !== 'error') {
            this.toastrService.success('Campaign default payout saved successfully');
            this.Submitted = false;
            this.getcampaignbyuuid();
            this.defaultpayoutForm.reset();
          }else{
            this.toastrService.error('Failed To add campaign payout');
          }
        },
        error => this.error = error,
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your Record is safe :)',
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}


setRouting(uuid){
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want Add Routing Plan!',

    showCancelButton: true,
    confirmButtonText: 'Yes, Add it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.moduleservice.setcampaignRouting(uuid,this.uuid,currentUser.uuid).subscribe(
          res => {

            var currentUser = JSON.parse(localStorage.getItem('currentUser'));
            this.editForm.get('user_uuid').setValue(currentUser.uuid);
            // console.log(res);return false;
            this.loadallRoutingplan(this.uuid);
            this.loadcampaigntarget();
            // this.toastrService.success('Number Deleted Successfully');
            Swal.fire(
              'Added!',
              'Routing Plan Added Successfully.',
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);


          },
          error => this.error = error
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Routing Plan Cancelled :)',
        'error'
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}


  //  public numberdata: Array<Select2OptionData>;
   public numberdata: [];
   loadNumbers(){
     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.moduleservice.loadNumbersfordropdown(this.currentCompany.uuid).subscribe(
       resp => {
           this.numberdata = resp;
          //  console.log(this.numberdata);
         },
         error => this.error = error
     );
   }
   public options: Options;


   publisherdata:any;
   getAllpublishers(){
     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.moduleservice.getAllpublishers(this.currentCompany.uuid).subscribe(
       res => {
         this.publisherdata= res
        //  console.log(res);
         },
         error => this.error = error
     );
   }

   targetdata:any;
   getAlltarget(){
     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.moduleservice.getAlltarget(this.currentCompany.uuid).subscribe(
       res => {
         this.targetdata= res
         },
         error => this.error = error
     );
   }

   numberIsFor:number;
   numberIsForval(type:number){
     this.numberIsFor = type;
     this.editForm.get('numberIsFor').setValue(type);

   }

 Submitted = false;
 //Add Publisher
//  uuid = this.route.snapshot.paramMap.get('id');
payout_typevalueedit:any = 0;
payout_typevaledit(val:number) {
  this.payout_typevalueedit = val;
  this.payoutForm.get('payout_typeedit').setValue(val);
  this.publishtertocampaignForm.get('payout_typeedit').setValue(val);
}

notype:any = 0;
notype_toggle(val:number) {
  this.notype = val;
  this.publishtertocampaignForm.get('isexisting').setValue(val);
}

istollfree:any = 0;
istollfreenotype_toggle(val:number) {
  this.istollfree = val;
  this.publishtertocampaignForm.get('notype').setValue(val);
}

 get editFormcontrol() { return this.editForm.controls; }
 onSubmit () {
  //  console.log(this.campaignNumbers);
  //  return false;
  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.editForm.get('user_uuid').setValue(currentUser.uuid);
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));

    // this.editForm.get('number').setValue(this.campaignNumbers)
    // this.editForm.get('labeldata').setValue(this.campaignLabels)
    if(this.recording==2){
      this.editForm.get('recording').setValue(2)
    }
    // console.log(this.isblacklistaliance);

    if(this.isblacklistaliance==2){
      this.editForm.get('enable_blacklist_aliance').setValue(2)
    }
    if(this.is_enable_offer==2){
      this.editForm.get('is_enable_offer').setValue(2)
    }
    if(this.ispredicaterouting==2){
      this.editForm.get('enable_predicaterouting').setValue(2)
    }
     this.showLoadingIndicator= true;
     this.moduleservice.updateCampaign(this.uuid,this.currentCompany.uuid,this.editForm.value).subscribe(
       res => {
        //  console.log(res); return false;
        this.showLoadingIndicator= false;
         if (res.status !== 'error') {
           this.isName = "fail";
           this.toastrService.success('Campaign Updated Successfully');
           this.Submitted = false;
           this.router.navigate(['/campaigns']);
         }else{
           this.toastrService.error('Failed To Update Campaign');
         }
       },
       error => this.error = error,
     );
}

isName : any;
  validatename() {
  if(this.editForm.get('campaignName').value!=''){
    this.moduleservice.validateCampaignName(this.editForm.get('campaignName').value).subscribe(
      res =>{
          // console.log(res); return false;
        this.isName = res;
        if (this.isName.status == 'success') {
          this.isName = "success";
        }
        else{
          this.isName = "fail";
        }
      }
    );
  }else{
    this.isName="";
  }
}

closeModal(){
  if (!this.payoutForm.invalid) {
    $("#addLabels").modal("hide");
  }
}

closepubModal(){
  if (!this.publishtertocampaignForm.invalid) {
    $("#addpublisher").modal("hide");
  }
}
closeroutingModal(){
  if (!this.editRoutingForm.invalid) {
    $("#editRouting").modal("hide");
  }
}
closedpayoutModal(){
  if (!this.defaultpayoutForm.invalid) {
    $("#addcampaignpayout").modal("hide");
  }
}

payoutSubmitted = false;
get payoutFormcontrol() { return this.payoutForm.controls; }
 onPayoutSubmit () {
  //  console.log(this.payoutForm.value);
  //  return false;
  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.payoutForm.get('user_uuid').setValue(currentUser.uuid);
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.moduleservice.addpublisherpayout(this.uuid,this.payoutForm.value).subscribe(
       res => {
         if (res.status !== 'error') {
           this.toastrService.success('Publisher Payout Added Successfully');
           this.Submitted = false;
           this.loadcampaignpublishers(this.uuid);
         }else{
           this.toastrService.error('Failed To Update Campaign');
         }
       },
       error => this.error = error,
     );
}

 publisherselected:any;
 publisherNumber:any;
 publisherSubmitted = false;
 get publisherFormcontrol() { return this.publishtertocampaignForm.controls; }
 onpublisherSubmit () {
  if(this.publisherselected){
    this.publisherselected = this.publisherselected[0]['id'];
    this.publishtertocampaignForm.get('publisher_uuid').setValue(this.publisherselected);
  }else{
    Swal.fire(
      'Error!',
      'Please select Publisher',
      'error'
    )
    return
  }
  $("#addpublisher").modal("hide");

  if(this.publisherNumber){
    this.publisherNumber = this.publisherNumber[0]['id'];
    this.publishtertocampaignForm.get('number').setValue(this.publisherNumber);
  }
  //  console.log(this.publisherNumber);
  //  return false;

  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.publishtertocampaignForm.get('user_uuid').setValue(currentUser.uuid);

     this.moduleservice.addpublishertocampaign(this.uuid,this.publishtertocampaignForm.value).subscribe(
       res => {
         if (res.status !== 'error') {
           this.toastrService.success('Publisher to Campaign Added Successfully');
           this.Submitted = false;
           this.loadcampaignpublishers(this.uuid);
           this.publishtertocampaignForm.reset();
           this.loadNumbers();
           this.publisherselected = "";
         }else{
           this.toastrService.error('Failed To Update Campaign');
         }
       },
       error => this.error = error,
     );
}

// default payout
dpayout_type:any = 0;
payout_typeedit(val:number) {
  this.dpayout_type = val;
  this.defaultpayoutForm.get('payout_type').setValue(val);
}

dpSubmitted = false;
get defaultpayoutFormcontrol() { return this.defaultpayoutForm.controls; }
 ondpayoutSubmit () {
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.defaultpayoutForm.get('user_uuid').setValue(currentUser.uuid);
    // console.log(this.defaultpayoutForm.value); return

     this.moduleservice.adddefaultpayout(this.uuid,this.defaultpayoutForm.value).subscribe(
       res => {
         if (res.status !== 'error') {
           this.toastrService.success('Campaign default payout saved successfully');
           this.Submitted = false;
           this.getcampaignbyuuid();
           this.defaultpayoutForm.reset();
         }else{
           this.toastrService.error('Failed To add campaign payout');
         }
       },
       error => this.error = error,
     );
}


  recording:any=2;
  get_recordingstatus(event:any){
    this.recording = event.target.checked;
    // console.log(this.recording);
    if(this.recording==true){
    this.editForm.get('recording').setValue(1);
    this.recording = 1;
    }
    if(this.recording==false){
    this.editForm.get('recording').setValue(0);
    this.recording = 0
    }
  }

  isblacklistaliance:any=2;
  get_blacklistAliance(event:any){
    this.isblacklistaliance = event.target.checked;
    if(this.isblacklistaliance==true){
    this.editForm.get('enable_blacklist_aliance').setValue(1);
    this.isblacklistaliance = 1;
    }
    if(this.isblacklistaliance==false){
    this.editForm.get('enable_blacklist_aliance').setValue(0);
    this.isblacklistaliance = 0
    }
  }

  is_enable_offer:any=2;
  is_enable_offer_toggle(event:any){
    this.is_enable_offer = event.target.checked;
    if(this.is_enable_offer==true){
    this.editForm.get('is_enable_offer').setValue(1);
    this.is_enable_offer = 1;
    }
    if(this.is_enable_offer==false){
    this.editForm.get('is_enable_offer').setValue(0);
    this.is_enable_offer = 0
    }
  }

  ispredicaterouting:any=2;
  get_predicaterouting(event:any){
    this.ispredicaterouting = event.target.checked;
    if(this.ispredicaterouting==true){
    this.editForm.get('enable_predicaterouting').setValue(1);
    this.ispredicaterouting = 1;
    }
    if(this.ispredicaterouting==false){
    this.editForm.get('enable_predicaterouting').setValue(0);
    this.ispredicaterouting = 0
    }
  }

  maxconcurrency:any;
  get_maxcon(event:any){
    this.maxconcurrency = event.target.checked;
    // this.isselectlabel = false;
    // $(".labelswitch").prop('checked',false);
  }

  isselectlabel:any;
  get_labelisselect(event:any){
    this.isselectlabel = event.target.checked;
    // this.maxconcurrency = false;
    // $(".labelnumber").prop('checked',false);
  }

  settingToggleDurationvalue:any = 0;
  settingToggleDuration(val:number) {
    this.settingToggleDurationvalue = val;
    this.editRoutingForm.get('settingToggleDurationval').setValue(val);
  }

  targetname:string = "";
  editRoutingdata(uuid:string,priority:number,weight:number,revenue:number,type:number,seconds:any,restrictdup:any,restrictafter:any,settingToggleDurationval:any,days:any,name){
    // console.log(uuid)
    this.targetname = name
    this.editRoutingForm.patchValue({
      uuid: uuid,
      priority: priority,
      weight: weight,
      conversation_revenue: revenue,
      conversation_event: type,
      conversation_seconds: seconds,
      restrictdup: restrictdup,
      restrictafter: restrictafter,
      days: days,
      settingToggleDurationval: settingToggleDurationval,
      settingToggleDuration: settingToggleDurationval,
    });
    this.settingToggleDurationvalue = settingToggleDurationval;

  }

  changeVal (uuid:string,val:any,type:number) {
    this.moduleservice.change_routingplanval(uuid, val, type).subscribe(
      res => {
        if (res.status !== 'error') {
          // this.toastrService.success('Routing Plan Updated Successfully');
          this.Submitted = false;
          this.loadallRoutingplan(this.uuid);
        }else{
          // this.toastrService.error('Failed To Update Campaign');
        }
      },
      error => this.error = error,
    );
  }

 get editRoutingSubmit() { return this.editRoutingForm.controls; }
 oneditRoutingSubmit () {
      this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
      var currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.editRoutingForm.get('user_uuid').setValue(currentUser.uuid);
     this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
     this.moduleservice.editRoutingSubmit(this.uuid,this.editRoutingForm.value).subscribe(
       res => {
         if (res.status !== 'error') {
           this.toastrService.success('Routing Plan Updated Successfully');
           this.Submitted = false;
           this.loadallRoutingplan(this.uuid);
         }else{
           this.toastrService.error('Failed To Update Campaign');
         }
       },
       error => this.error = error,
     );
}

alltimezone:any;
gettimezone(){
  this.moduleservice.gettimezone().subscribe(
    res => {
      this.alltimezone = res;
      // console.log(res);
    }
  );
}

PredicateRouting:any;
getPredicateRouting(){
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.moduleservice.getpredictiverouting(this.currentCompany.uuid).subscribe(
    res => {
      this.PredicateRouting = res;
      // console.log(res);
    }
  );
}

exportdata(){
  var options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    useBom: true,
    headers: ["UUID", "Enabled"," Publisher","Publisher UUID","Number","Associated Labels","Number UUID","Configuration Overrides","Status"]
  };
  // console.log(this.campaignpublisher);
  new   ngxCsv(this.campaignpublisher, 'All Campaign Publishers', options);
  }

  filtertype:any=1;


  getType(type:any,val:any){
    // if(val==1){
    //   this.renderer.setProperty(window, 'scrollTo', {
    //     top: this.targetDiv.nativeElement.offsetTop,
    //     behavior: 'smooth'
    //   });
    // }

    if(type==1){
      $("#tabs3").removeClass("btn btn-primary btn-sm camactivetab");
        $("#tabs2").removeClass("btn btn-primary btn-sm camactivetab");
       $("#tabs1").addClass("camactivetab");this.filtertype=1;
      //  this.loadcallsdata(this.filterselected,this.selecteddate,1);
    }
    if(type==2){
      $("#tabs1").removeClass("btn btn-primary btn-sm camactivetab");
      $("#tabs3").removeClass("btn btn-primary btn-sm camactivetab");
       $("#tabs2").addClass("camactivetab");this.filtertype=2;
      //  this.loadcallsdata(this.filterselected,this.selecteddate,4);
    }
    if(type==3){
      $("#tabs1").removeClass("btn btn-primary btn-sm camactivetab");
      $("#tabs2").removeClass("btn btn-primary btn-sm camactivetab");
       $("#tabs3").addClass("camactivetab");this.filtertype=3;
      //  this.loadcallsdata(this.filterselected,this.selecteddate,4);
    }

  }

  @ViewChild('editdiv') editdiv: ElementRef;
  getScroll(val:any){
      try {
        const errorField = this.renderer.selectRootElement('.first-class');
          errorField.scrollIntoView();
      } catch (err) {

      }

  }

  pfiltertype:any=1;
  getParticipationType(type){
    if(type==1){
      $("#tabs4").removeClass("btn btn-primary btn-sm camactivetab");
       $("#tabs3").addClass("camactivetab");this.pfiltertype=1;
      //  this.loadcallsdata(this.filterselected,this.selecteddate,1);
    }
    if(type==2){
      $("#tabs3").removeClass("btn btn-primary btn-sm camactivetab");
       $("#tabs4").addClass("camactivetab");this.pfiltertype=2;
      //  this.loadcallsdata(this.filterselected,this.selecteddate,4);
    }

  }

  parreqdata:any;
  reqdata(details:any) {
    this.parreqdata = details;
  }

  iscreateoffer:Boolean=false;
  iscreateoffertoggle() {
    this.iscreateoffer = !this.iscreateoffer;
  }

  isshowmessage:Boolean=true;
  isshowmessagetoggle() {
    this.isshowmessage = !this.isshowmessage;
  }

// Offer Section

  is_approval:any=1;
  is_approvaltoggle(event:any){
    if(event.target.checked==true){
      this.is_approval = 1;
    }else{
      this.is_approval = 0;
    }
  }

  isallow_tollfree_no:any=1;
  allow_tollfree_notoggle(event:any){
    if(event.target.checked==true){
      this.isallow_tollfree_no = 1;
    }else{
      this.isallow_tollfree_no = 0;
    }
  }

  allow_did_no:any=1;
  is_allow_did_notoggle(event:any){
    if(event.target.checked==true){
      this.allow_did_no = 1;
    }else{
      this.allow_did_no = 0;
    }
  }

isadded:any=0;
addofferSubmitted = false;
get addOfferFormcontrol() { return this.addofferForm.controls; }
onAddOfferSubmit(buttonType): void {
  // console.log(this.addofferForm.get('description').value); return

  this.addofferSubmitted = true;
  const formData = new FormData();
  if (this.addofferForm.invalid) {
      return;
  }

  let exp_date = this.addofferForm.get('exp_date').value;
  let hrs = this.addofferForm.get('start_hrs').value;
  let min = this.addofferForm.get('start_min').value;
  let exp_date_full =  `${exp_date} ${hrs}:${min}`
  // console.log(exp_date_full); return


  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  formData.append('campaign_uuid', this.uuid);
  formData.append('user_uuid', currentUser.uuid);
  formData.append('company_uuid', this.currentCompany.uuid);
  formData.append('submittype', buttonType);
  formData.append('name', this.addofferForm.get('name').value);
  formData.append('description', this.addofferForm.get('description').value);
  formData.append('hoursofoperation', this.addofferForm.get('hoursofoperation').value);
  formData.append('exp_timezone', this.addofferForm.get('exp_timezone').value);
  formData.append('exp_date', exp_date_full);
  formData.append('req_approval', this.is_approval);
  formData.append('max_no_per_publisher', this.addofferForm.get('max_no_per_publisher').value);
  formData.append('allow_tollfree_no', this.isallow_tollfree_no);
  formData.append('allow_did_no', this.allow_did_no);
  // console.log(formData);
  this.moduleservice.addOffer(formData).subscribe(
    res => {
      if (res.status !== 'error') {
        this.toastrService.success('Offer details submitted Successfully');
        this.Submitted = false;
        this.isadded =1;
        this.isedit =false;
        this.campaignoffers = [[]];
        this.loadparticipationrequest(this.uuid);
      }else{
        this.toastrService.error('Failed To save offer details');
      }
    },
    error => this.error = error,
  );
}

isedit:Boolean=false;
isedittoggle() {
  this.iscreateoffer = true;
  this.isedit = !this.isedit;

  var now = new Date(this.offerdetails.exp_date);
  var time = now.toTimeString();

  this.addofferForm.patchValue({
    name: this.offerdetails.name,
    description: this.offerdetails.description,
    hoursofoperation: this.offerdetails.hoursofoperation,
    exp_timezone: this.offerdetails.exp_timezone,
    exp_date: this.offerdetails.exp_date,
    start_hrs: time.slice(0, 2),
    start_min: time.slice(3, 5),
    max_no_per_publisher:this.offerdetails.max_no_per_publisher,
  });

  this.today = new Date(this.offerdetails.exp_date).toISOString().split('T')[0];
  // console.log(time);


  this.is_approval = this.offerdetails.req_approval;
  this.isallow_tollfree_no = this.offerdetails.allow_tollfree_no;
  this.allow_did_no = this.offerdetails.allow_did_no;
}

changeofferstatus(status: Number) {
  let msg ="Pause";
  let content ="Are you sure you want to pause the offer? Publishers won't be able to participate in the paused offer.";
  if(status == 1){
    msg ="Resume";
    content ="Are you sure you want to resume the offer? Publishers will be able to participate in the offer.";
  }
  if(status == 3){
    msg ="End";
    content ="Are you sure you want to end the offer and remove it from the campaign? All related phone numbers will be terminated, all participating publishers will be notified of this change.";
  }
  if(status == 4){
    msg ="Enable";
    content ="Are you sure you want to enable the offer?";
  }
  if(status == 5){
    msg ="Disable";
    content ="Are you sure you want to disable the offer?";
  }
  if(status == 2){
    msg ="End";
  }

  Swal.fire({
    title: 'Confirm '+msg+' Offer',
    text: content,

    showCancelButton: true,
    confirmButtonText: msg,
    cancelButtonText: 'Cancel'
  }).then((result) => {
    if (result.value) {
      this.moduleservice.changeofferstatus(this.uuid,status).subscribe(
          res => {
            // console.log(res);return false;
            Swal.fire(
              'Success!',
              'Offer '+ msg +' Successfully.',
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);
            if(status != 4 || status != 5){
            this.loadparticipationrequest(this.uuid);
            }
            this.getcampaignbyuuid();

          },
          error => this.error = error
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your Record is safe :)',
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}



approverejoffer(status: Number) {
  // console.log(this.parreqdata); return
  let msg ="Approve";
  if(status == 3){
    msg ="Reject";
  }
  Swal.fire({
    text: 'Confirm '+msg+' Offer',
    // text: content,
    //
    showCancelButton: true,
    confirmButtonText: msg,
    cancelButtonText: 'Cancel'
  }).then((result) => {
    if (result.value) {
      this.moduleservice.approverejoffer(this.parreqdata.uuid,status).subscribe(
          res => {
            // console.log(res);return false;
            $("#approveoffermodel").modal("hide");
            Swal.fire(
              'Success!',
              'Offer '+ msg +' Successfully.',
            )
            setTimeout(() => {
              Swal.close()
            }, 2000);

            this.loadparticipationrequest(this.uuid);

          },
          error => this.error = error
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your Record is safe :)',
        'error'
      )
      setTimeout(() => {
        Swal.close()
      }, 2000);
    }
  });
}

payoutuuid:any;
setuuid(uuid){
this.payoutuuid = uuid
}

selectedExistingNumber:any;
addnoSubmitted = false;
get addnoFormcontrol() { return this.numberForm.controls; }
onAddNoSubmit () {
  //  console.log(this.notype);
  //  console.log(this.selectedExistingNumber);
  //  console.log(this.payoutuuid);
  //  return false;
  this.addnoSubmitted = true;
  if (this.numberForm.invalid) {
      return;
  }
  $("#addNumber").modal("hide");
  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany'));
  this.numberForm.get('company_uuid').setValue(this.currentCompany.uuid);
  this.numberForm.get('user_uuid').setValue(currentUser.uuid);
  this.numberForm.get('campaign_uuid').setValue(this.uuid);
  this.numberForm.get('isexisting').setValue(this.notype);
  this.numberForm.get('selectedExistingNumber').setValue(JSON.stringify(this.selectedExistingNumber));
  this.numberForm.get('payoutuuid').setValue(this.payoutuuid);
  this.numberForm.get('isedit').setValue(1);
  this.numberForm.get('notype').setValue(this.istollfree);

  this.ngxLoader.startLoader('loader-04');
  this.moduleservice.purchase_number(this.uuid,this.numberForm.value).subscribe(
       res => {
         if (res.status !== 'error') {
           this.toastrService.success(res.message);
           this.Submitted = false;
           this.loadcampaignpublishers(this.uuid);
         }else{
           this.toastrService.error(res.message);
         }
         this.ngxLoader.stopLoader('loader-04');
       },
       error => this.error = error,
     );
}


}
