import { Component, OnInit } from '@angular/core';
import { ModuleService } from './../../modules/module.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
declare var $;


@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  addNotesForm: FormGroup;
  error: any;
  currentCompany: any;
  submitted = false;
  showLoadingIndicator: boolean;
  constructor(
    private fb: FormBuilder,
    private moduleService: ModuleService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService:ToastrService,
    private titleService: Title
  ) { }
  
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {
    this.setTitle('Notes')
    this.showLoadingIndicator= true;
    setTimeout(()=>{this.showLoadingIndicator= false; },1000);
    
    this.addNotesForm = this.fb.group({
      note: ['',Validators.required],       
    });

  this.currentCompany = JSON.parse(localStorage.getItem('currentCompany')); 
  const company_uuid = this.currentCompany.uuid;
  // alert(company_uuid);
  if (company_uuid) {
        this.moduleService.getNotesdata(company_uuid).subscribe(
          res => {
            // console.log(res);
            this.addNotesForm.patchValue({
              note: res[0]['notes'],               
            });              
          }
        );
    } 
  }

  get f() { return this.addNotesForm.controls; }

  onSubmit () {
    const formData = new FormData();  
    if (this.addNotesForm.invalid) {
        return;
    }
    this.submitted = true;
    formData.append('note', this.addNotesForm.get('note').value);
    this.currentCompany = JSON.parse(localStorage.getItem('currentCompany')); 
    formData.append('company_uuid', this.currentCompany.uuid);
    const company_uuid = this.currentCompany.uuid;
    if (company_uuid) {
      this.moduleService.addNotes(formData, company_uuid).subscribe(
        res => {
          // console.log(res); return false;
          if (res.status !== 'error') {
            this.toastrService.success('Notes Saved Successfully');            
          }else{
            this.toastrService.success('Failed To Save! Please Try Again Later..');
          }
        },
        error => this.error = error
      );
    }   
  }

}
