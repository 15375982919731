import { CampaignViewComponent } from './campaigns/campaign-view/campaign-view.component';
import { ThankyouComponent } from './publishers/thankyou/thankyou.component';
import { VoicemailComponent } from './voicemail/voicemail.component';
import { AuthGuard } from './../auth/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule,Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProfileComponent } from '../auth/profile/profile.component';  

import { NumberComponent } from './number/number.component';  
import { NumberaddComponent } from './number/numberadd/numberadd.component';  
import { NumbereditComponent } from './number/numberedit/numberedit.component';
import { NumbereditBuyerComponent } from './number/numberedit-buyer/numberedit-buyer.component';
import { NumberUploadComponent } from './number/number-upload/number-upload.component';
import { NumberBatcheditComponent } from './number/number-batchedit/number-batchedit.component';
import {NumberAddfslrulesComponent } from './number/number-addfslrules/number-addfslrules.component';

import { LabelComponent } from './label/label.component';
import { LabelViewComponent } from './label/label-view/label-view.component';
import { DashboardComponent } from './dashboard/dashboard.component';  
import { NotesComponent } from './notes/notes.component';  
import { IvrMenusComponent } from './ivr-menus/ivr-menus.component';
import { AddIVRMenuComponent } from './ivr-menus/add-ivrmenu/add-ivrmenu.component';
import { EditIvrmenuComponent } from './ivr-menus/edit-ivrmenu/edit-ivrmenu.component';

import { RulesComponent } from './rules/rules.component';
import { RulesaddComponent } from './rules/rulesadd/rulesadd.component';
import { RuleseditComponent } from './rules/rulesedit/rulesedit.component';

import { DynamicParameterComponent } from '../modules/dynamic-parameter/dynamic-parameter.component';

import { BlacklistComponent } from './blacklist/blacklist.component';
import { WhitelistComponent } from './whitelist/whitelist.component';

import { ReportsComponent } from './reports/reports.component';
import { ReportCallsComponent } from './reports/report-calls/report-calls.component';
import { ReportLabelsummaryComponent } from './reports/report-labelsummary/report-labelsummary.component';


import { VerificationManagementComponent } from './verification-management/verification-management.component';

import { AudibleRingComponent } from './audible-ring/audible-ring.component';

import { ShortlistComponent } from './shortlist/shortlist.component';
import { LonglistComponent } from './longlist/longlist.component';

import { SmsComponent } from './sms/sms.component';

import { RecordingsComponent } from './recordings/recordings.component';
import { NumberBuyerComponent } from './number/number-buyer/number-buyer.component';
import { PublishersComponent } from './publishers/publishers.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignCreateComponent } from './campaigns/campaign-create/campaign-create.component';
import { NumbersComponent } from './numbers/numbers.component';
import { NumberPoolComponent } from './numbers/number-pool/number-pool.component';

import { BlockedNumbersComponent } from './numbers/blocked-numbers/blocked-numbers.component';

import { CampaignEditComponent } from './campaigns/campaign-edit/campaign-edit.component';

import { ManageUsersComponent } from './settings/manage-users/manage-users.component';
import { BasicSettingComponent } from './settings/basic-setting/basic-setting.component';


import { PublisherSettingsComponent } from './publishers/publisher-settings/publisher-settings.component';
import { PublisherTermsComponent } from './publishers/publisher-terms/publisher-terms.component';
import { AdvertiserTermsComponent } from './publishers/advertiser-terms/advertiser-terms.component';
import { AlertConfigurationComponent } from './settings/alert-configuration/alert-configuration.component';
import { ManageCompanyComponent } from './settings/manage-company/manage-company.component';

import { ChangelogComponent } from './changelog/changelog.component';
import { IVRRoutingPlansComponent } from './ivr-menus/ivr-routing-plans/ivr-routing-plans.component';
import { AddPlanComponent } from './ivr-menus/ivr-routing-plans/add-plan/add-plan.component';
import { EditPlanComponent } from './ivr-menus/ivr-routing-plans/edit-plan/edit-plan.component';
import { MailVerificationComponent } from './mail-verification/mail-verification.component';
import { NgxdemoComponent } from './label/ngxdemo/ngxdemo.component';

import { ReportsComparisonComponent } from './reports-comparison/reports-comparison.component';
import { TasksComponent } from './tasks/tasks.component';
import { PredictiveRoutingComponent } from './settings/predictive-routing/predictive-routing.component';
import { AddRoutingComponent } from './settings/predictive-routing/add-routing/add-routing.component';
import { EditRoutingComponent } from './settings/predictive-routing/edit-routing/edit-routing.component';


const routes: Routes = [
  {path:'',component:DashboardComponent, canActivate: [AuthGuard]}, 
  // Profile
  {path:'profile',component:ProfileComponent, canActivate: [AuthGuard]},  
  
  // Target Module  
  {path:'target',component:NumberComponent, canActivate: [AuthGuard]},  
  {path:'numbers-import/:id',component:NumberUploadComponent, canActivate: [AuthGuard]},  
  {path:'add-target',component:NumberaddComponent, canActivate: [AuthGuard]},  
  {path:'edit-numbers/:id',component:NumbereditComponent, canActivate: [AuthGuard]},  
  {path:'edit-target/:id',component:NumbereditBuyerComponent, canActivate: [AuthGuard]},  
  {path:'batch-edit',component:NumberBatcheditComponent, canActivate: [AuthGuard]},  
  {path:'buyer',component:NumberBuyerComponent, canActivate: [AuthGuard]},  
  {path:'add-weekdays',component:NumberAddfslrulesComponent, canActivate: [AuthGuard]},  
  
  {path:'labels',component:LabelComponent, canActivate: [AuthGuard]},
  {path:'labels/view/:labelname',component:LabelViewComponent, canActivate: [AuthGuard]},  
  
  {path:'notes',component:NotesComponent, canActivate: [AuthGuard]},  
  {path:'ivr-menu',component:IvrMenusComponent, canActivate: [AuthGuard]},  
  {path:'new-ivr-menu',component:AddIVRMenuComponent, canActivate: [AuthGuard]},  
  {path:'edit-ivr/:id',component:EditIvrmenuComponent, canActivate: [AuthGuard]},  
  {path:'ivr-routing-plans',component:IVRRoutingPlansComponent, canActivate: [AuthGuard]},  
  {path:'ivrplan-add',component:AddPlanComponent, canActivate: [AuthGuard]},  
  {path:'ivrplan-edit/:id',component:EditPlanComponent, canActivate: [AuthGuard]},  
  
  {path:'rules',component:RulesComponent, canActivate: [AuthGuard]},  
  {path:'add-rule',component:RulesaddComponent, canActivate: [AuthGuard]},  
  {path:'edit-rule/:id',component:RuleseditComponent, canActivate: [AuthGuard]},  
  
  {path:'dynamic-forwarding-parameters',component:DynamicParameterComponent, canActivate: [AuthGuard]},  

  {path:'blacklist',component:BlacklistComponent, canActivate: [AuthGuard]},  
  {path:'whitelist',component:WhitelistComponent, canActivate: [AuthGuard]},  
  {path:'reports',component:ReportsComponent, canActivate: [AuthGuard]},  
  {path:'reports/:id',component:ReportsComponent, canActivate: [AuthGuard]},  
  {path:'call-reports',component:ReportCallsComponent, canActivate: [AuthGuard]},  
  {path:'call-reports-labelsummary',component:ReportLabelsummaryComponent, canActivate: [AuthGuard]},  
  
  {path:'comparison-reports',component:ReportsComparisonComponent , canActivate: [AuthGuard]},  
  
  {path:'shortlist',component:ShortlistComponent, canActivate: [AuthGuard]},  
  {path:'longlist',component:LonglistComponent, canActivate: [AuthGuard]},  
  
  {path:'verification-management',component:VerificationManagementComponent, canActivate: [AuthGuard]},  

  {path:'audible-ring',component:AudibleRingComponent, canActivate: [AuthGuard]},  
  
  {path:'sms',component:SmsComponent, canActivate: [AuthGuard]},  
  {path:'recordings',component:RecordingsComponent, canActivate: [AuthGuard]},  
  
  {path:'publishers',component:PublishersComponent, canActivate: [AuthGuard]},  
  {path:'publishers-registration-settings',component:PublisherSettingsComponent, canActivate: [AuthGuard]},  
  {path:'publisher-registration/:id',component:PublisherTermsComponent},  
  {path:'advertiser-terms',component:AdvertiserTermsComponent},  
  {path:'thank-you',component:ThankyouComponent},  

  {path:'campaigns',component:CampaignsComponent, canActivate: [AuthGuard]},  
  {path:'campaign-wizard',component:CampaignCreateComponent },  
  {path:'numbers',component:NumbersComponent, canActivate: [AuthGuard]},  
  {path:'number-pools',component:NumberPoolComponent, canActivate: [AuthGuard]},  
  {path:'blocked-numbers',component:BlockedNumbersComponent, canActivate: [AuthGuard]},  
  // {path:'create-number',component:NumberCreateComponent, canActivate: [AuthGuard]},  
  {path:'edit-campaign/:id',component:CampaignEditComponent, canActivate: [AuthGuard]},  
  {path:'view-campaign/:id',component:CampaignViewComponent, canActivate: [AuthGuard]},  
  
  {path:'manage-users',component:ManageUsersComponent, canActivate: [AuthGuard]},  
  {path:'basic-setting',component:BasicSettingComponent, canActivate: [AuthGuard]},  
  {path:'alert-configuration',component:AlertConfigurationComponent, canActivate: [AuthGuard]},  
  {path:'manage-company',component:ManageCompanyComponent, canActivate: [AuthGuard]},  
  
  
  {path:'change-log',component:ChangelogComponent, canActivate: [AuthGuard]},  

  {path:'verify-email/:uuid',component:MailVerificationComponent},  
  {path:'NgxdemoComponent',component:NgxdemoComponent},  

  {path:'voicemail',component:VoicemailComponent, canActivate: [AuthGuard]},  
  {path:'tasks',component:TasksComponent, canActivate: [AuthGuard]},  
  {path:'predictive-routing',component:PredictiveRoutingComponent, canActivate: [AuthGuard]},  
  {path:'predictive-routing-add',component:AddRoutingComponent, canActivate: [AuthGuard]},  
  {path:'predictive-routing-edit/:id',component:EditRoutingComponent, canActivate: [AuthGuard]},  
  
  ];

@NgModule({
  imports: [RouterModule.forChild(routes),FormsModule,ReactiveFormsModule],
  exports: [RouterModule]
})
export class ModulesRoutingModule { }
